import { combineReducers } from 'redux';
import configReducer from './config/configReducer';
import batchModeReducer from './configBatchModeData/batchModeReducer';
import categoryReducer from './configCategoryData/categoryReducer';
import configureDevicesReducer from './configureDevices/configureDevicesReducer';
import deviceConfigReducer from './deviceConfig/deviceConfigReducer';

export default combineReducers({
  configReducer,
  configureDevicesReducer,
  categoryReducer,
  batchModeReducer,
  deviceConfigReducer,
});
