import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const EWSDataModel = [
  {
    id: `${FeatureIdEnum.Ews_Language}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Ews_Language_Mode}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Language}.mode`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'lang_src_label',
        info: 'lang_src_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Language_Source}`,
        resource: `${ConstraintsResourceEnum.EWS_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Language_Source}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EwsConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EWSConfigEwsEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Language}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Language}.code`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'ews_lang_label',
        hint: 'ews_lang_hint',
        help: 'ews_lang_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Language}`,
        resource: `${ConstraintsResourceEnum.EWS_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Language}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EwsConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EWSConfigSelectedLanguage}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Time_Service}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Time_driftCorrection}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Services}.drift-correction`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'time_driftCorrection_label',
        info: 'time_driftCorrection_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.TimeServices_DriftCorrection}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.TimeServices_systemTimeSync}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockSystemTimeSync}`,
      },
      {
        id: `${SubfeatureIdEnum.Time_ServerAddress}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Services}.server-address`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'time_serverAddress_label',
        hint: 'time_serverAddress_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.TimeServices_ServerAddress}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.TimeServices_serverAddress}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockNtpServer}`,
      },
      {
        id: `${SubfeatureIdEnum.Time_ServicePort}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Services}.port`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'time_port_label',
        hint: 'time_port_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.TimeServices_port}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.TimeServices_Port}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockNtpLocalPortNumber}`,
      },
      {
        id: `${SubfeatureIdEnum.Time_Synchours}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Services}.sync-hours`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'time_synchours_label',
        hint: 'time_synchours_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.TimeServices_syncHours}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.TimeServices_syncHours}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockNtpSyncFrequency}`,
      },
    ],
  },
];
