import { Toggle } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import EditDataContext from '../../context/EditDataContext';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { InformationIcon } from '../CommonExtended/Icons/InformationIcon';
import { TitleContainer } from '../CommonExtended/InputTitle/Styles';
import { ToggleInputWrap, CustomToggle} from './Styles';

const ToggleControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;
  const { getRulesEffect } = TempDataHelper();
  const { getDeviceSettingsLocString } = LocalizeHelper();

  const { onUpdateTempData, tempRulesEffect, tempSettings } = useContext(EditDataContext);

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  let tooltipMessage = '';
  if (device_settings.info !== undefined) {
    tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);
  }
  // Currently passed defalut state as True, need to map feature API actual data
  const initialData =
    device_settings.fleetValue == 'true'
      ? true
      : device_settings.fleetValue == 'false'
      ? false
      : device_settings.fleetValue;

  const [toggleState, setToggleState] = useState(initialData);
  const [flag, setFlag] = useState(false);
  const [toggleStateDispatch, setToggleStateDispatch] = useState(true);

  const [effectVal, setEffectVal] = useState();

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  const handleOnchange = (value) => {
    setToggleState(value);

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      attribute: device_settings.attribute,
      error: false,
    });
  };

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  useEffect(() => {
    setToggleStateDispatch(false);
  }, []);

  useEffect(() => {
    const snmpV1V2AccessOption = tempSettings?.filter(
      (data) => data.settingsName === 'snmpV1V2Config.accessOption',
    );

    if (
      device_settings.id === 'snmp-v1-v2-readonly-public-allowed' &&
      snmpV1V2AccessOption?.length
    ) {
      if (snmpV1V2AccessOption[0].fleetValue === 'readOnly') {
        setToggleState(true);
        setFlag(true);
      } else if (snmpV1V2AccessOption[0].fleetValue === 'disable') {
        setToggleState(false);
        setFlag(true);
      } else if (snmpV1V2AccessOption[0].fleetValue === 'readWrite') {
        setFlag(false);
        flag && setToggleState(initialData);
      }
    }
  }, [tempSettings]);

  return (
    <ToggleInputWrap>
      <TitleContainer>
        <CustomToggle
          id={device_settings.attribute}
          disabled={isDisable}
          label={label}
          onChange={handleOnchange}
          on={toggleState}
        />
        <InformationIcon
          infoMessage={tooltipMessage}
          size={16}
          color="colorGray5"
          isDisabled={isDisable}
        />
      </TitleContainer>
    </ToggleInputWrap>
  );
};

export default memo(ToggleControl);
