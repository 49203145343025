import { fontSize1, lineHeight2 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { FlexRowCenter } from '../../GlobalStyles/GlobalStyles';

export const TooltipContainer = styled.div`
  .tooltipContent {
    font-size: ${fontSize1};
    line-height: ${lineHeight2};
  }

  #jsTooltipPosition {
    white-space: normal;
    word-break: break-word;
  }
  position: relative;
`;

export const IconContainer = styled(FlexRowCenter)`
  padding-left: 4px;
`;

export const HelpIconContainer = styled(FlexRowCenter)`
  padding-left: 3px;
`;
