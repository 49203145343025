import { IconInfo } from '@veneer/core';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const RelatedSettingsCard = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 10px;
  background: ${primitives.color.gray1};
  border-radius: 8px;
`;

export const RelatedSettingsCardContent = styled.div`
  display: flex;
  padding-left: 10px;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
  

`;

export const RelatedSettingsCardTitle = styled.label`
  font-family: 'Forma DJR Micro';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #212121;
`;

export const RelatedSettingsCardDesc = styled.p`
  font-family: 'Forma DJR Micro';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #404040;
`;

export const StyledIconInfo = styled(IconInfo)`
  color: #737373 !important;
  margin-top: '2px';
`;
