import React, { Fragment } from 'react';
import { FeatureIdEnum } from '../../../../config/FeatureEnums';
import { CAIDCertifcate } from './CAIDCertificate/CAIDCertifcate';
import { Ipv4Ipv6 } from './Ipv4Ipv6/Ipv4Ipv6';

const CustomSubFeatureSection = (props) => {
  let component;
  switch (props.featureId) {
    case FeatureIdEnum.CA_Certificate:
    case FeatureIdEnum.ID_Certificate:
      component = <CAIDCertifcate {...props} />;
      break;
    case FeatureIdEnum.IPv4_information:
    case FeatureIdEnum.IPv6_Information:
      component = <Ipv4Ipv6 {...props} />;
      break;
  }

  return <Fragment>{component}</Fragment>;
};

export default CustomSubFeatureSection;
