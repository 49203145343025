import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext } from 'react';
import { AdvanceFeatureWarningMessage } from '../../../../../src/components/CommonExtended/AdvanceFeatureWarningMessage/AdvanceFeatureWarningMessage';
import { AppConfig } from '../../../../config/App.config';
import { SubFeatureCustomModeEnum } from '../../../../config/SubFeatureEnums';
import EditDataContext from '../../../../context/EditDataContext';
import SettingsGenerator from '../../../../generators/SettingsGenerator';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { useStoreState } from '../../../../store/useStoreState';
import { ControlledPolicy } from '../../../CommonExtended/ControlledPolicy/ControlledPolicy';
import { InfoNotification } from '../../../CommonExtended/InfoNotification/InfoNotification';
import { Loader } from '../../../CommonExtended/Loader/Loader';
import Settings from '../Settings';
import CustomFeatureSettingsList from './CustomFeatureSettingsList';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const SettingsList = (props) => {
  const { featuresData, entitlements, isWex } = props;
  const { controlledPolicy, policyCheck, entitlementId, effectivePolicy, warningNotification } =
    featuresData;

  const { editViewData } = useContext(EditDataContext);

  const { featureFlag } = AppConfig.controlledPolicy;
  const { deviceConfigDataState } = useStoreState();
  const { getCommonStrings, getDeviceFeatureModalString } = LocalizeHelper();
  const { loading } = deviceConfigDataState;
  const notifyMessage = getDeviceFeatureModalString(featuresData.id, 'notifyMessage');
  const entitlementCondition = entitlements?.includes('ws-hp.com/smcloud-advanced');
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
  const shouldShowAdvanceFeatureWarning =
    AppConfig.entitlementFeature.featureFlag &&
    entitlementId &&
    ldAdvancedEntitlement &&
    !entitlementCondition;
  const hasControlledPolicy = featureFlag && policyCheck;
  const hasControlledPolicyCheck = featureFlag && policyCheck && controlledPolicy?.length;

  const hasNotEntitlement =
    entitlementId && ldAdvancedEntitlement && entitlementCondition === false;
  const controlledPolicyCheck = !hasNotEntitlement ? (
    hasControlledPolicyCheck ? (
      <ControlledPolicy policyName={controlledPolicy} />
    ) : (
      <InfoNotification
        title={getCommonStrings('relatedSettingsTitle')}
        message={getCommonStrings('relatedSettingsSubtitle')}
      />
    )
  ) : null;

  const ldapSetupCredential = editViewData?.find(
    (item) => item.name === 'ldap-setup.credential',
  )?.value;

  const getSettings = () => {
    return featuresData.subfeatures?.map((subfeature) => {
      return (
        <Settings
          deviceSettingForms={SettingsGenerator.generateDeviceSettings(
            featuresData,
            entitlements,
            subfeature,
            featuresData.id,
            effectivePolicy,
            ldapSetupCredential,
            isWex
          )}
          accessMode={subfeature.accessMode}
          indent={subfeature.indent}
        />
      );
    });
  };

  return (
    <Fragment>
      {loading ? <Loader /> : null}
      {shouldShowAdvanceFeatureWarning ? <AdvanceFeatureWarningMessage /> : null}
      {hasControlledPolicy ? controlledPolicyCheck : null}
      {warningNotification && <InfoNotification message={notifyMessage} />}
      {featuresData.customizable &&
      featuresData.customizable === SubFeatureCustomModeEnum.WRITEVIEW ? (
        <CustomFeatureSettingsList
          id={featuresData.id}
          subfeaturesdata={featuresData.subfeatures}
          isControlledPolicy={effectivePolicy}
          isWex = {isWex}
          hasControlledPolicy={hasControlledPolicy}
          controlledPolicyCheck={controlledPolicyCheck}
        />
      ) : (
        getSettings()
      )}
    </Fragment>
  );
};

export default SettingsList;
