const environmentVariables = {
  local: {
    deviceConfigApiUrl: 'https://smartsecurity-pie.tropos-rnd.com/constraintsengine/constraints',
  },
  proxy: {
    deviceConfigApiUrl: 'https://pie-us1.api.ws-hp.com/constraintsengine/constraints',
  },
  dev: {
    deviceConfigApiUrl: 'https://dev-us1.api.ws-hp.com/constraintsengine/constraints',
  },
  pie: {
    deviceConfigApiUrl: 'https://pie-us1.api.ws-hp.com/constraintsengine/constraints',
  },
  stage: {
    deviceConfigApiUrl: 'https://stage-us1.api.ws-hp.com/constraintsengine/constraints',
  },
  prod: {
    deviceConfigApiUrl: 'https://us1.api.ws-hp.com/constraintsengine/constraints',
  },
};

const deviceCacheEnvironmentVariables = {
  local: {
    deviceCacheUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
  },
  proxy: {
    deviceCacheUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
  },
  dev: {
    deviceCacheUrl: 'https://dev-us1.api.ws-hp.com/devicecache',
  },
  pie: {
    deviceCacheUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
  },
  stage: {
    deviceCacheUrl: 'https://stage-us1.api.ws-hp.com/devicecache',
  },
  prod: {
    deviceCacheUrl: 'https://us1.api.ws-hp.com/devicecache',
  },
};

export function getEnvConfig(env) {
  const tlcEnv = env instanceof String ? env.toLowerCase() : env;
  switch (tlcEnv) {
    case 'local':
      return environmentVariables.local;
    case 'proxy':
      return environmentVariables.proxy;
    case 2:
    case 'stage':
    case 'stg':
      return environmentVariables.stage;
    case 3:
    case 'prod':
    case 'production':
      return environmentVariables.prod;
    case 1:
    case 'pie':
      return environmentVariables.pie;
    case 0:
    case 'dev':
      return environmentVariables.dev;
    default:
      return environmentVariables.pie;
  }
}

export function getDeviceCacheEnvConfig(env) {
  const tlcEnv = env instanceof String ? env.toLowerCase() : env;
  switch (tlcEnv) {
    case 'local':
      return deviceCacheEnvironmentVariables.local;
    case 'proxy':
      return deviceCacheEnvironmentVariables.proxy;
    case 2:
    case 'stage':
    case 'stg':
      return deviceCacheEnvironmentVariables.stage;
    case 3:
    case 'prod':
    case 'production':
      return deviceCacheEnvironmentVariables.prod;
    case 1:
    case 'pie':
      return deviceCacheEnvironmentVariables.pie;
    case 0:
    case 'dev':
      return deviceCacheEnvironmentVariables.dev;
    default:
      return deviceCacheEnvironmentVariables.pie;
  }
}

export const anchorMenuContainerId = '@jarvis/react-ecp-device-details';

export const defaultAnchorPosition: any = {
  position: 'relative',
  top: 0,
  bottom: 0,
  end: 0,
  left: 16,
  right: 0,
  start: 0,
};
