import React, { Fragment } from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { CustomButtonWrap, CustomCancelButton, CustomSaveButton } from './Styles';

const Footer = (props) => {
  const { onClose, onSave, isFetching, disabled } = props;
  const { getDeviceModalLocString } = LocalizeHelper();

  return (
    <CustomButtonWrap>
      <Fragment>
        <CustomCancelButton
          appearance="secondary"
          onClick={onClose}
          data-testid={'id-batch-view-cancel'}
        >
          {getDeviceModalLocString('cancel_btn')}
        </CustomCancelButton>
      </Fragment>
      <CustomSaveButton
        appearance="primary"
        onClick={onSave}
        loading={isFetching}
        disabled={disabled}
        data-testid={'id-batch-view-save'}
      >
        {getDeviceModalLocString('configure_btn')}
      </CustomSaveButton>
    </CustomButtonWrap>
  );
};

export default Footer;
