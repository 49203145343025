import { colorGray4, colorGray7, fontSize3, lineHeight3 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { theme } from '../../GlobalStyles/GlobalStyles';

export const FlexRow = styled.div`
  display: flex;
  width: 400px;
  padding-bottom: 8px;
`;

export const PasswordField = styled.div`
  display: flex;
  width: 400px;
  border-radius: 8px;
`;

export const ConfirmPasswordField = styled(PasswordField)``;

export const TextBlack = styled.div`
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  letter-spacing: 0.02em;
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
`;

export const ConfirmPasswordBlock = styled.div``;

export const InputWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
