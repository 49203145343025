import { SubfeatureIdEnum } from '../config/SubFeatureEnums';
export const AppConfig = {
  notSupportedFeature: {
    featureFlag: true,
    pagination: '10',
  },
  editFilters: {
    featureFlag: true,
  },
  batchModeSearch: {
    featureFlag: true,
  },
  batchModeToggleFilter: {
    featureFlag: true,
  },
  controlledPolicy: {
    featureFlag: true,
  },
  entitlementFeature: {
    featureFlag: true,
  },

  incompletePolicySetting: {
    featureFlag: false,
  },
  customizeGetSetting: [
    SubfeatureIdEnum.Ews_Proposed_Password,
    // SubfeatureIdEnum.EmailAddress_RestrictionEnabled,
    SubfeatureIdEnum.SNMPV1V2_RW_Access,
    // SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure,
    SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure,
  ],
};
