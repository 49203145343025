import { Toggle } from '@veneer/core';
import styled from 'styled-components';
import { styleAttributes4 } from '../../GlobalStyles/GlobalStyles';
import primitives from '@veneer/primitives';

export const EditableToggleWrap = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 8px;
`;

export const ToggleButton = styled(Toggle)`
  max-width: 500px;
  width: 32px ;
  height: 20px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const EditableToggleText = styled.text`
  ${styleAttributes4}
  color: ${primitives.color.gray7};
  margin-top: 8px;
  margin-bottom: 24px;
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
`;
