import { ProgressIndicator } from '@veneer/core';
import React from 'react';
import { LoadingWrapper } from './Styles';

export const Loader = () => {
  return (
    <LoadingWrapper>
      <ProgressIndicator />
    </LoadingWrapper>
  );
};
