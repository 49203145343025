import styled from 'styled-components';

export const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 16px;
  &:last-child {
    width: 100%;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
