import { RadioButton, RadioButtons } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import TempDataHelper from '../../../helper/TempDataHelper';
import { InputTitle } from '../../CommonExtended/InputTitle/InputTitle';
import { InputWrap } from './Styles';

const OutGoingServerAuth = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy } = props;

  const { getRulesEffect } = TempDataHelper();

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const { getDeviceSettingsLocString, getDeviceConfigurationsLocString } = LocalizeHelper();

  const [inputVal, setInputVal] = useState(
    device_settings.fleetValue ? [device_settings.fleetValue] : ['signInUser'],
  );
  const [effectVal, setEffectVal] = useState();

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  const radioOptions = device_settings.constraints.options?.map((option) => {
    return (
      <RadioButton
        label={getDeviceConfigurationsLocString(featuresId, option.value, device_settings.id)}
        value={option.value}
        disabled={isDisable}
      />
    );
  });

  const handleChange = (e, value) => {
    setInputVal([value]);

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      attribute: device_settings.attribute,
      error: false,
    });
  };

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  return (
    <InputWrap>
      <InputTitle
        title={label}
        tooltipMessage={tooltipMessage}
        info={device_settings.info}
        disable={isDisable}
      />
      <RadioButtons
        alignment="vertical"
        name="radiobutton"
        defaultValue="0"
        value={inputVal[0]}
        onChange={(e, value) => handleChange(e, value)}
      >
        {radioOptions}
      </RadioButtons>
    </InputWrap>
  );
};

export default memo(OutGoingServerAuth);
