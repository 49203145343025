import { RadioButton, RadioButtons } from '@veneer/core';
import React, { memo, useContext, useState } from 'react';
import { ConstraintsEffectEnum } from '../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { InputTitle } from '../../CommonExtended/InputTitle/InputTitle';
import { InputWrap,StyledRadioButtons } from './Styles';

const Dot1xAuthFailureConfiguration = (props) => {
  const { device_settings, featuresId, isChecked , isWex} = props;

  const { onUpdateTempData } = useContext(EditDataContext);

  const { getDeviceSettingsLocString, getDeviceConfigurationsLocString } = LocalizeHelper();

  const [inputVal, setInputVal] = useState(device_settings.fleetValue);

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);

  const isDisable = isChecked;

  const radioOptions = device_settings.constraints.options.map((option) => {
    return (
      <RadioButton
        label={getDeviceConfigurationsLocString(featuresId, option.value, device_settings.id)}
        value={option.value}
        disabled={isDisable}
      />
    );
  });

  const handleOnchange = (e, value) => {
    setInputVal(value);
    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: value,
      attribute: device_settings.attribute,
      error: false,
    });
  };

  return (
    <InputWrap>
      <InputTitle
        title={label}
        tooltipMessage={tooltipMessage}
        info={device_settings.info}
        disable={isDisable}
      />
      <StyledRadioButtons
        alignment="vertical"
        name="radiobutton"
        value={inputVal}
        onChange={(e, value) => handleOnchange(e, value)}
        isWex = {isWex}
      >
        {radioOptions}
      </StyledRadioButtons>
    </InputWrap>
  );
};

export default memo(Dot1xAuthFailureConfiguration);
