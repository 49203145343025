import { Toggle } from '@veneer/core';
import styled from 'styled-components';
import { styleAttributes4 } from '../../GlobalStyles/GlobalStyles';

export const EditableToggleWrap = styled.div`
  display: flex;
`;

export const ToggleButton = styled(Toggle)`
  max-width: 500px;
  width: 32px;
  height: 20px;
`;

export const EditableToggleText = styled.text`
  ${styleAttributes4}
  margin-left: 8px;
`;
