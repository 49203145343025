export const OutgoingServersHelper = (subFeatureId, propsData) => {
  const formatKey = (key, index, keys) => {
    if (key === 'automatedEmail') key = 'Alerts';
    return key.charAt(0).toUpperCase() + key.slice(1) + (index < keys.length - 1 ? ', ' : '');
  };
  const isEditable = false;

  const getTableData = (outgoingServersData, subFeatureId) => {
    const subFeatureType =
      subFeatureId.split('-').pop().charAt(0) + subFeatureId.split('-').pop().slice(1);

    return outgoingServersData.map((servers) => {
      const keys = Object.keys(servers.serverUsage);
      return (
        keys.includes(subFeatureType) && {
          serverName: servers.serverAddress,
          port: servers.serverPort,
          serverUsage: keys.map(formatKey),
        }
      );
    });
  };

  const tableData = getTableData(propsData, subFeatureId).filter((servers) => servers);

  return { outgoingServersData: tableData };
};
