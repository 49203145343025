import React from 'react';
import { Title, TitleBold } from './Styles';

export const InputLabel = (props) => {
  const { isTextBold, isContentAfter } = props;
  return (
    <>
      {isTextBold ? (
        <TitleBold isContentAfter={isContentAfter}>{props.title}</TitleBold>
      ) : (
        <Title disable={props.disable}>{props.title}</Title>
      )}
    </>
  );
};
