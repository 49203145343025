import { Button, ButtonGroup } from '@veneer/core';
import React, { useContext, useEffect, useState } from 'react';
import { SubfeatureIdEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import ConstraintsRuleParser from '../../../dataParser/ConstraintsRuleParser';
import SettingsGenerator from '../../../generators/SettingsGenerator';
import { FleetAttributeHelper } from '../../../helper/FleetAttributeHelper';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { useStoreState } from '../../../store/useStoreState';
import Settings from '../../EditView/Settings/Settings';
import { CustomModal, FlexColumn, TextBlackVariant1, TextBlackVariant2 } from './Styles';
import TextBoxControl from './TextBoxControl';

export enum outgoingServerEnum {
  ADDRESS = 'address',
  PORT = 'port',
  SPLIT_EMAIL = 'splitEmail',
  SSL = 'ssl',
  VALIDATE_CERT = 'validateCert',
  AUTH = 'auth',
  EMAIL = 'email',
  ALERT = 'alert',
  FAX = 'fax',
  AUTOSEND = 'autosend',
  CREDENTIAL = 'credential',
  USER = 'user',
  PASSWORD = 'password',
  USAGE = 'usage', // UI only
}

const OutgoingServersModal = (props) => {
  const { id, value, edit, onClose, onChange, subfeaturesdata, tableValue, isWex, hasControlledPolicy, controlledPolicyCheck  } = props;
  const [enable, setEnable] = useState(true);

  const { getDeviceSettingsLocString } = LocalizeHelper();

  const {
    entitlements,
    editViewData,
    setEditViewData,
    setTempRulesEffect,
    setTempRulesData,
    tempSettings,
    tempFeatures,
  } = useContext(EditDataContext);
  const [tempSettingsLoc, setTempSettingsLoc] = useState(tempSettings);

  const { deviceConfigDataState } = useStoreState();
  const { rulesEffect, rulesData } = deviceConfigDataState;
  const { tempConditionCheck } = ConstraintsRuleParser();

  const editDescriptionMessage = getDeviceSettingsLocString(id, 'edit-description-message');
  const addDescriptionMessage = getDeviceSettingsLocString(id, 'add-description-message');
  const addServer = getDeviceSettingsLocString(id, 'add-server');
  const editServer = getDeviceSettingsLocString(id, 'edit-server');
  const serverInformation = getDeviceSettingsLocString(id, 'server-information');
  const save = getDeviceSettingsLocString(id, 'save');
  const add = getDeviceSettingsLocString(id, 'add');
  const cancel = getDeviceSettingsLocString(id, 'cancel');

  useEffect(() => {
    let array2 = [...editViewData];

    array2 = array2.map((item) => {
      if (replacements.hasOwnProperty(item.name)) {
        return {
          ...item,
          value:
            item.settingsName === 'credential.userName' ||
            item.settingsName === 'credential.password'
              ? ''
              : replacements[item.name],
        };
      }
      return item;
    });

    setEditViewData(array2);
  }, [value]);

  const filteredData = subfeaturesdata.filter(
    (_data) =>
      _data.id !== SubfeatureIdEnum.Outgoing_Server_List &&
      _data.id !== SubfeatureIdEnum.Outgoing_Server_List_OverwriteOption &&
      _data.id !== SubfeatureIdEnum.Outgoing_Server_List_OverWriteExistingName &&
      _data.id !== SubfeatureIdEnum.Outgoing_Server_List_Email &&
      _data.id !== SubfeatureIdEnum.Outgoing_Server_InformationLabel,
  );

  let filteredDataArray = [...filteredData];

  let replacements = {
    ...value,
  };

  filteredDataArray = filteredDataArray
    .map((item) => {
      if (replacements.hasOwnProperty(item.attribute)) {
        return {
          ...item,
          fleetValue:
            item.id === 'outgoing-email-server-username' ? '' : replacements[item.attribute],
        };
      }
      return item;
    })
    .filter((_data) => _data.id !== 'outgoing-email-server-name');

  const serverNameData = subfeaturesdata.find((_data) => _data.id === 'outgoing-email-server-name');

  const ldapSetupCredential = false;

  const getSettings = () => {
    return filteredDataArray?.map((subfeature) => {
      return (
        <Settings
          deviceSettingForms={SettingsGenerator.generateDeviceSettings(
            props,
            entitlements,
            subfeature,
            props.id,
            props.effectivePolicy,
            ldapSetupCredential,
            isWex
          )}
          accessMode={subfeature.accessMode}
          indent={subfeature.indent}
        />
      );
    });
  };

  const handleSelect = () => {
    const filteredData = editViewData
      .filter((item) =>
        [
          'address',
          'port',
          'splitEmail',
          'ssl',
          'validateCert',
          'auth',
          'credential',
          'user',
          'password',
          'email',
          'fax',
          'alert',
          'autosend',
        ].includes(item.name),
      )
      .filter((data) => data.rulesEffect === false)
      .map((item) => {
        const payload = FleetAttributeHelper(item);
        return payload;
      });

    const result = filteredData.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    onChange(result);
    onClose();
  };

  useEffect(() => {
    if (editViewData && editViewData.length) {
      let editViewDataCopy = [...editViewData];

      let filteredData = editViewDataCopy.filter(
        (item) =>
          (item.settingsName === 'serverAddress' && item.name === 'address') ||
          item.settingsName === 'serverPort' ||
          item.settingsName === 'splitEmailOptions' ||
          item.settingsName === 'useSsl' ||
          (item.settingsName === 'validateServerCertificate' && item.name === 'validateCert') ||
          (item.settingsName === 'serverUsage.email' && item.name === 'email') ||
          item.settingsName === 'serverUsage.fax' ||
          item.settingsName === 'serverUsage.automatedEmail' ||
          item.settingsName === 'serverUsage.autoSend' ||
          item.settingsName === 'serverRequireAuthentication' ||
          item.settingsName === 'credential.credentialType' ||
          item.settingsName === 'credential.userName' ||
          item.settingsName === 'credential.password',
      );

      const requiredSettingsCount = filteredData.filter(
        (data) =>
          (data.required === true || data.required === undefined) && data.rulesEffect === false,
      ).length;

      const requiredSettings = filteredData.some(
        (data) =>
          (data.required === true || data.required === undefined) && data.rulesEffect === false,
      );

      const valid = requiredSettings
        ? filteredData.filter(
            (data) =>
              (data.required == undefined || data.required) &&
              data.rulesEffect === false &&
              !data.error &&
              !data.confirmpasswordError &&
              data.value !== '' &&
              data.value !== null,
          ).length === requiredSettingsCount
        : filteredData.filter(
            (data) =>
              !data.error && !data.confirmpasswordError && data.value !== '' && data.value !== null,
          ).length === 1;

      const serverNames = ['autosend', 'fax', 'email', 'alert'];

      const isAnyValuePresent = editViewData.some(
        (item) =>
          serverNames.includes(item.name) &&
          item.value !== null &&
          item.value !== '' &&
          item.value !== false &&
          item.value !== 'false',
      );

      setEnable(!valid || !isAnyValuePresent);
    }
  }, [editViewData]);

  useEffect(() => {
    const tempCheckIdsSet = new Set([
      'outgoing-email-server-req-auth',
      'outgoing-email-server-smtp',
      'outgoing-email-server-credType',
    ]);
    const new_tempCheck_Value = false;

    const result = tempSettingsLoc.map(subfeature => {
      // Find the corresponding update
      const update = filteredDataArray.find(u => u.id === subfeature.id);
      // Create a new object for subfeature with updated properties
      const updatedSubfeature = update ? {
        ...subfeature,
        fleetResult: update.fleetResult,
        fleetValue: update.fleetValue,
      } : subfeature;

      // Update tempCheck if id is in tempCheckIdsSet and return new object
      return tempCheckIdsSet.has(subfeature.id) ? {
        ...updatedSubfeature,
        tempCheck: new_tempCheck_Value,
      } : updatedSubfeature;
    });

    result.map((settings) => {
      return tempConditionCheck(rulesData, settings);
    });

    setTempSettingsLoc(result);
  }, []);

  useEffect(() => {
    setTempRulesData(rulesData);
    setTempRulesEffect(rulesEffect);
  }, [rulesData, rulesEffect]);

  return (
    <CustomModal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={edit ? editServer : addServer}
      className={'small-policy-modal'}
      data-testid={'id-outgoing-servers-modal'}
      isWex = {isWex}
      footer={
        <ButtonGroup>
          <Button appearance={'secondary'} onClick={onClose}>
            {cancel}
          </Button>
          <Button onClick={handleSelect} disabled={enable}>
            {edit ? save : add}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlackVariant2 padding={'16px'} className={'paddingTop20'}>
        {edit ? editDescriptionMessage : addDescriptionMessage}
      </TextBlackVariant2>
      {hasControlledPolicy ? controlledPolicyCheck : null}
      <TextBlackVariant1 className={'paddingBottom16 bold'}>{serverInformation}</TextBlackVariant1>
      <FlexColumn>
        <TextBoxControl
          device_settings={serverNameData}
          isChecked={undefined}
          featuresId={id}
          isControlledPolicy={false}
          tableValue={tableValue}
          serverAddress={value.address}
        />
        {getSettings()}
      </FlexColumn>
    </CustomModal>
  );
};
export default OutgoingServersModal;
