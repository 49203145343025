/* eslint-disable @typescript-eslint/no-explicit-any */
type LoggerTypes = 'debug' | 'error' | 'log' | 'warn' | 'info'
const preffix = '[react-ecp-deviceConfig-mfe] '

function getLogger(type: LoggerTypes) {
  return (...args: any[]) => {
    const logger = (window as any)?.Shell?.v1?.logger
    logger?.setLocalOptions({
      enable: true,
      allowedTypes: {
        debug: true,
        info: true,
        error: true,
        log: true,
        warn: true
      }
    })
    const loggerFunction = logger?.[type] || console?.[type]
    loggerFunction?.(preffix, ...args)
  }
}

export default {
  debug: getLogger('debug'),
  error: getLogger('error'),
  info: getLogger('info'),
  log: getLogger('log'),
  warn: getLogger('warn')
}
