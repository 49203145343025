import React, { useContext, useEffect } from 'react';
import { ConstraintsEffectEnum } from '../../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../../config/SubFeatureEnums';
import EditDataContext from '../../../../context/EditDataContext';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import TempDataHelper from '../../../../helper/TempDataHelper';
import { InputLabel } from '../../../CommonExtended/InputTitle/InputLabel';
import LdapRootModal from '../Modal/LdapRootModal';
import { ButtonGroupWrap, ButtonSelectedLabel, InputWrap } from '../Styles';
import Button from './Button';

const ButtonGroup = (props) => {
  const { featuresId, totalSelectedData, serverTableData, isChecked , isWex} = props;
  const [open, setOpen] = React.useState(false);
  const [effectVal, setEffectVal] = React.useState();
  const { getDeviceSettingsLocString } = LocalizeHelper();
  const { getRulesEffect } = TempDataHelper();
  const { tempRulesEffect } = useContext(EditDataContext);

  const label = getDeviceSettingsLocString(featuresId, 'roots-modal-text-label');
  const add_btn_label = getDeviceSettingsLocString(featuresId, 'add-btn');
  const remove_btn_label = getDeviceSettingsLocString(featuresId, 'remove-btn');
  const roots_limit = getDeviceSettingsLocString(featuresId, 'roots-limit', {
    count: serverTableData?.length,
  });
  const table_Limit = 25;

  const handleAdd = () => {
    if (!table_Limit || serverTableData.length <= table_Limit) {
      setOpen(true);
    }
  };

  const handleRemove = () => {
    const result = props.serverTableData.filter(
      (item1) => !props.selectedData.some((item2) => item2.root_name === item1.root_name),
    );
    props.setServerTableData(result);
  };

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : props.isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        props.device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, props.device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  return (
    <div>
      <InputWrap marginBottom="8px">
        <InputLabel title={label} />
      </InputWrap>

      <ButtonGroupWrap>
        <Button
          name="root_add_btn"
          label={add_btn_label}
          handleClick={handleAdd}
          disable={isDisable || serverTableData.length >= table_Limit}
          isWex = {isWex}
        />
        <Button
          name="root_remove_btn"
          label={remove_btn_label}
          handleClick={handleRemove}
          disable={isDisable ? isDisable : totalSelectedData < 1 ? true : false}
          isWex = {isWex}
        />
        <ButtonSelectedLabel>
          <InputLabel disabled={isDisable} title={roots_limit} />
        </ButtonSelectedLabel>
      </ButtonGroupWrap>

      {open ? (
        <LdapRootModal
          open={open}
          setOpen={setOpen}
          {...props}
          setServerTableData={props.setServerTableData}
          isWex = {isWex}
        />
      ) : null}
    </div>
  );
};

export default ButtonGroup;
