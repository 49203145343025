import { Button, IconArrowDown, IconArrowUp, Table } from '@veneer/core';
import { ColumnIndexTypes } from '@veneer/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { shellProps } from '../../../shellProps';
import { FlexColumn, FlexRow, TextBlackVariant1 } from './Styles';

const OutgoingServersTable = (props) => {
  const { tableData, setTableData, usage, enabled, id, isChecked } = props;
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined);

  const { getDeviceSettingsLocString, getDeviceTableColumnsLocString } = LocalizeHelper();
  const { showMultipleConfigUi } = useContext(shellProps);

  const sendToEmail = getDeviceSettingsLocString(id, 'send_to_email');
  const internetFax = getDeviceSettingsLocString(id, 'internet_fax');
  const alert = getDeviceSettingsLocString(id, 'email_alerts');
  const autoSend = getDeviceSettingsLocString(id, 'auto_send');
  const serverAddress = getDeviceTableColumnsLocString(id, 'serverName');
  const serverPort = getDeviceTableColumnsLocString(id, 'port');
  const serverUsage = getDeviceTableColumnsLocString(id, 'serverUsage');

  enum TABLE_CONSTANTS {
    ID = 'id',
    NO_DATA = '--',
    JOINER = ', ',
  }

  enum outgoingServerEnum {
    ADDRESS = 'address',
    PORT = 'port',
    SPLIT_EMAIL = 'splitEmail',
    SSL = 'ssl',
    VALIDATE_CERT = 'validateCert',
    AUTH = 'auth',
    EMAIL = 'email',
    FAX = 'fax',
    ALERT = 'alert',
    AUTOSEND = 'autosend',
    CREDENTIAL = 'credential',
    USER = 'user',
    PASSWORD = 'password',
    USAGE = 'usage', // UI only
  }

  const usageItems = [
    {
      id: outgoingServerEnum.EMAIL,
      label: sendToEmail,
      tag: 'usage-email',
    },
    {
      id: outgoingServerEnum.FAX,
      label: internetFax,
      tag: 'usage-fax',
    },
    {
      id: outgoingServerEnum.ALERT,
      label: alert,
      tag: 'usage-alert',
    },
    {
      id: outgoingServerEnum.AUTOSEND,
      label: autoSend,
      tag: 'usage-autosend',
    },
  ];

  const title = useMemo(() => usageItems.find(({ id }) => id === usage)?.label, [usage]);

  function getRowSelectAllState(selected: number, total: number): string {
    return !selected ? 'none' : selected === total ? 'all' : 'indeterminated';
  }

  useEffect(() => {
    setRowSelectAllState(
      getRowSelectAllState(tableData.filter((x) => x.rowConfig.selected).length, tableData.length),
    );
  }, [tableData]);

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden';
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      {
        id: outgoingServerEnum.ADDRESS,
        label: serverAddress,
      },
      {
        id: outgoingServerEnum.PORT,
        label: serverPort,
      },
      {
        id: outgoingServerEnum.USAGE,
        label: serverUsage,
      },
    ];
  }, []);

  const swapArrayItems = (a, i, j) => {
    const temp = a[i];
    a[i] = a[j];
    a[j] = temp;
  };

  const onMoveUp = (simulate = false) => {
    let moved = 0;
    for (let i = 1; i < tableData?.length; i++) {
      if (tableData[i].rowConfig.selected && !tableData[i - 1].rowConfig.selected) {
        if (simulate) {
          return true;
        }
        swapArrayItems(tableData, i - 1, i);
        moved++;
      }
    }
    if (moved) {
      setTableData([...tableData], false);
    }
    return moved > 0;
  };

  const onMoveDown = (simulate = false) => {
    let moved = 0;
    for (let i = tableData?.length - 2; i >= 0; i--) {
      if (tableData[i].rowConfig.selected && !tableData[i + 1].rowConfig.selected) {
        if (simulate) {
          return true;
        }
        swapArrayItems(tableData, i, i + 1);
        moved++;
      }
    }
    if (moved) {
      setTableData([...tableData], false);
    }
    return moved > 0;
  };

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target;
    const found = tableData.find(({ id }) => id === rowId);
    if (found) {
      found.rowConfig.selected = checked;
      setTableData([...tableData], true); // expand selection to other tables
    }
  };

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target;
    const newTableData = tableData.map((row) => {
      const rowCopy = row;
      row.rowConfig.selected = checked;
      return rowCopy;
    });
    setTableData(newTableData, true); // expand selection to other tables
  };

  const tableHeader = (text) =>
    !setTableData ? (
      <>{text}</>
    ) : (
      <TextBlackVariant1 className={'paddingTop16 marginBottom4'} disabled={!enabled}>
        {text}
      </TextBlackVariant1>
    );

  return (
    <FlexColumn>
      {tableHeader(title)}
      <FlexRow>
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={
            tableData.length && (!showMultipleConfigUi || !isChecked) ? 'multiSelection' : undefined
          }
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto'}
          data-testid={'id-outgoing-services-table-' + usage}
          preferences={{
            width: [
              { columnId: outgoingServerEnum.ADDRESS, width: 35 },
              { columnId: outgoingServerEnum.PORT, width: 15 },
              { columnId: outgoingServerEnum.USAGE, width: 50 },
            ],
          }}
        />
        <FlexColumn className={'marginLeft8'}>
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowUp />}
            onClick={() => onMoveUp()}
            disabled={isChecked || !onMoveUp(true)}
          />
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowDown />}
            onClick={() => onMoveDown()}
            disabled={isChecked || !onMoveDown(true)}
          />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};

export default OutgoingServersTable;
