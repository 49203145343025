import {
  storeStartRootComponent,
  storeDeviceId,
  storeDeviceModelNumber,
  storeShowMultipleConfigUi,
  storeSelectedDeviceIdList,
} from '../../../src/store/config/action';

export const getConfigStoreActions = (props) => {
  if (process.env.NODE_ENV === 'mockup') {
    return [
      storeStartRootComponent(true),
      storeDeviceId('en/us/devices/demoId'),
      storeDeviceModelNumber(''),
      storeShowMultipleConfigUi(false),
      storeSelectedDeviceIdList(['en/us/devices/demoId']),
    ];
  }
  return [
    storeStartRootComponent(true),
    storeDeviceId(props.deviceId),
    storeDeviceModelNumber(props.deviceModelNumber),
    storeShowMultipleConfigUi(props.showMultipleConfigUi ?? true),
    storeSelectedDeviceIdList(props.selectedDeviceIdList ?? []),
  ];
};
