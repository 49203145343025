import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const WebServicesDataModel = [
  {
    id: `${FeatureIdEnum.Smart_CloudPrint}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Smart_CloudPrint}`,
        attribute: `${FleetSvcPolicyAttributeId.Smart_CloudPrint}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'smartCloudPrint_label',
        info: 'smartCloudPrint_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Smart_CloudPrint}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Smart_CloudPrint}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CloudPrint}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.HPJetAdvantage_MoreApps}`,
    configurable: false,
    visible: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.HPJetAdvantage_MoreApps}`,
        attribute: `${FleetSvcPolicyAttributeId.HPJetAdvantage_MoreApps}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'hpJetAdvMoreApps_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.HPJetAdvMoreApps_Enabled}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.HPJetAdvantage_MoreApps}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HPJetAdvantage}`,
      },
      {
        id: `${SubfeatureIdEnum.AccountCreation}`,
        attribute: `${FleetSvcPolicyAttributeId.HPJetAdvantage_MoreApps}.create-account`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'accountCreation_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccountCreation}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.AccountCreation}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountCreation}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.HP_WebServices}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.HP_WebServices}`,
        attribute: `${FleetSvcPolicyAttributeId.HP_WebServices}.eprint`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'fe-hp-webservices_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.HP_WebServices}`,
        resource: `${ConstraintsResourceEnum.HP_WebServicesResource}`,
        settingsName: `${ConstraintsSettingsNameEnum.HP_WebServicesSettingsName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.HP_WebServices}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HP_WebServicesDCData}`,
      },
    ],
  },
];
