import React from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { CustomButtonWrap, CustomCancelButton, CustomSaveButton } from './Styles';

const Footer = (props) => {
  const { onClose, onSave, disabled, loading , isWex} = props;
  const { getDeviceModalLocString } = LocalizeHelper();

  return (
    <CustomButtonWrap>
      <CustomCancelButton
        appearance="secondary"
        onClick={onClose}
        data-testid={'id-single-mode-edit-view-cancel'}
        isWex ={isWex}
      >
        {getDeviceModalLocString('cancel_btn')}
      </CustomCancelButton>
      <CustomSaveButton
        appearance="primary"
        onClick={onSave}
        disabled={disabled}
        loading={loading}
        data-testid={'id-single-mode-edit-view-save'}
        isWex ={isWex}
      >
        {getDeviceModalLocString('save_btn')}
      </CustomSaveButton>
    </CustomButtonWrap>
  );
};

export default Footer;
