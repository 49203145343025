import { ColumnIndexTypes, Table } from '@veneer/core';
import React, { useEffect, useMemo, useState } from 'react';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';
import { FlexColumnTable, FlexColumnTableWrap, TextBlack } from '../Styles';

enum TABLE_CONSTANTS {
  ID = 'id',
  NO_DATA = '--',
  JOINER = ', ',
}

enum outgoingServerEnum {
  ADDRESS = 'address',
  PORT = 'port',
  SPLIT_EMAIL = 'splitEmail',
  SSL = 'ssl',
  VALIDATE_CERT = 'validateCert',
  AUTH = 'auth',
  EMAIL = 'email',
  FAX = 'fax',
  ALERT = 'alert',
  AUTOSEND = 'autosend',
  CREDENTIAL = 'credential',
  USER = 'user',
  PASSWORD = 'password',
  USAGE = 'usage', // UI only
}

const defaultTableItems = () => ({
  [outgoingServerEnum.EMAIL]: [],
  [outgoingServerEnum.FAX]: [],
  [outgoingServerEnum.ALERT]: [],
  [outgoingServerEnum.AUTOSEND]: [],
});

const OutGoingServerTable = (props) => {
  const { value } = props;
  const [tableData, setTableData] = useState(defaultTableItems());

  const { getDeviceSettingsLocString, getDeviceTableColumnsLocString } = LocalizeHelper();

  const sendToEmail = getDeviceSettingsLocString(props.id, 'send_to_email');
  const internetFax = getDeviceSettingsLocString(props.id, 'internet_fax');
  const alert = getDeviceSettingsLocString(props.id, 'email_alerts');
  const autoSend = getDeviceSettingsLocString(props.id, 'auto_send');
  const usageEmail = getDeviceSettingsLocString(props.id, 'usage_email');
  const usageAlert = getDeviceSettingsLocString(props.id, 'usage_alert');
  const usageAutosend = getDeviceSettingsLocString(props.id, 'usage_autosend');

  const serverAddress = getDeviceTableColumnsLocString(props.id, 'serverName');
  const serverPort = getDeviceTableColumnsLocString(props.id, 'port');
  const serverUsage = getDeviceTableColumnsLocString(props.id, 'serverUsage');

  const usageItems = [
    {
      id: outgoingServerEnum.EMAIL,
      label: sendToEmail,
      tag: usageEmail,
    },
    {
      id: outgoingServerEnum.FAX,
      label: internetFax,
      tag: internetFax,
    },
    {
      id: outgoingServerEnum.ALERT,
      label: alert,
      tag: usageAlert,
    },
    {
      id: outgoingServerEnum.AUTOSEND,
      label: autoSend,
      tag: usageAutosend,
    },
  ];

  useEffect(() => {
    const transformData1 = (data) => {
      let id = 0;
      const result = { email: [], fax: [], alert: [], autosend: [] };

      data.forEach((item) => {
        const newItem = {
          id: id++,
          address: item.serverAddress,
          port: item.serverPort,
          usage: '',
          rowConfig: {
            selected: false,
            item: {
              address: item.serverAddress,
              port: item.serverPort,
              email: item?.serverUsage?.email ? item.serverUsage.email === 'true' : false,
              fax: item?.serverUsage?.fax ? item.serverUsage.fax === 'true' : false,
              alert: item?.serverUsage?.alert ? item.serverUsage.alert === 'true' : false,
              autosend: item?.serverUsage?.autosend ? item.serverUsage.autosend === 'true' : false,
            },
          },
        };

        if (newItem.rowConfig.item.email) {
          newItem.usage += 'Email, ';
          result.email.push(newItem);
        }
        if (newItem.rowConfig.item.fax) {
          newItem.usage += 'Fax, ';
          result.fax.push(newItem);
        }
        if (newItem.rowConfig.item.alert) {
          newItem.usage += 'Email Alerts, ';
          result.alert.push(newItem);
        }
        if (newItem.rowConfig.item.autosend) {
          newItem.usage += 'AutoSend, ';
          result.autosend.push(newItem);
        }

        newItem.usage = newItem.usage.slice(0, -2); // Remove trailing comma and space
      });

      return result;
    };

    const answerData1 = transformData1(value);
    setTableData(answerData1);
  }, []);

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden';
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      {
        id: outgoingServerEnum.ADDRESS,
        label: serverAddress,
      },
      {
        id: outgoingServerEnum.PORT,
        label: serverPort,
      },
      {
        id: outgoingServerEnum.USAGE,
        label: serverUsage,
      },
    ];
  }, []);

  return (
    <>
      {usageItems.map(({ id, tag }) => (
        <FlexColumnTableWrap>
          {<TextBlack>{tag}</TextBlack>}
          <FlexColumnTable>
            <Table
              columns={tableColumns}
              data={tableData[id]}
              className={'widthColAuto'}
              data-testid={'id-outgoing-services-table-' + id}
              preferences={{
                width: [
                  { columnId: outgoingServerEnum.ADDRESS, width: 35 },
                  { columnId: outgoingServerEnum.PORT, width: 15 },
                  { columnId: outgoingServerEnum.USAGE, width: 50 },
                ],
              }}
            />
          </FlexColumnTable>
        </FlexColumnTableWrap>
      ))}
    </>
  );
};

export default OutGoingServerTable;
