import {
  colorGray2,
  colorGray5,
  fontFamilyRegular,
  fontSize3,
  lineHeight3,
} from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';

export const DeviceNotExistWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  padding: 16px;
`;

export const WarningLabel = styled.div`
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  font-family: ${fontFamilyRegular};
`;

export const WarningIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorGray5};
  background: ${colorGray2};
`;
