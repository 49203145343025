import {Accordion} from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenterBetween } from '../../../GlobalStyles/GlobalStyles';

export const EditFeatureListWrap = styled(FlexRowAlignCenterBetween)`
  .batch-edit-feature-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const EditFeatureList = styled.ul`
  width: 100%;
  word-break: break-word;
  list-style: none;
  // white-space: nowrap;
`;

export const EditFeatureListItem = styled.li`
  align-items: center;
  background-color: ${tokens.colorWhite};
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  box-sizing: border-box;
  color: ${tokens.colorGray10};
  justify-content: space-between;
  position: relative;
  width: 100%;
  word-break: break-word;
`;

export const EditFeatureAccordion = styled(Accordion)`
  // border-bottom: 1px solid #ebebeb;
  // border-color: #ebebeb !important;
  // border-radius: 0px !important;
  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 8px 21px 8px 32px;
  }
  div[id='${(props) => props.id}-body'] {
    margin: 0px 24px 0px 32px;
    padding: 24px 0px;
  }
`;
