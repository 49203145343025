import React from 'react';
import LocalizeHelper from '../helper/LocalizeHelper';

export const CustomPasswordValidator = (
  regexData,
  constraintsData,
  settingsID,
  feSettingsData = [],
) => {
  const passwordComplexityValue =
    feSettingsData.length &&
    feSettingsData.filter((_data) => _data.name === 'ews-password.complexity')[0]?.value;

  const minPasswordLength =
    feSettingsData.length &&
    feSettingsData.filter((_data) => _data.name === 'ews-password.min-length')[0]?.value;

  let requirementsRegex;
  let withCharacter;
  const minimumVal =
    settingsID === 'ews-admin-Proposed-password'
      ? parseInt(minPasswordLength)
      : parseInt(constraintsData.minimum);
  const maximumVal = parseInt(constraintsData.maximum);
  const constraintDataType = constraintsData.type;
  let requireRegexCharacter = 4;

  const {
    getRequirePasswordStrings,
    getRequirePasswordStringsWithCount,
    getRequirePasswordStringsWithRange,
  } = LocalizeHelper();

  if (minimumVal && minimumVal > 0) {
    withCharacter = true;
  } else {
    withCharacter = false;
  }

  if (minPasswordLength > 0 && minPasswordLength < 4) {
    requireRegexCharacter = minPasswordLength;
  }

  const getRequirements = (data, id) => {
    return {
      id: data.id,
      label: getRequirePasswordStrings(id),
    };
  };

  const getRequirementsWithCount = (data, id, count) => {
    return {
      id: data.filter((data) => data.id === id)[0]?.id,
      label: getRequirePasswordStringsWithCount(id, count),
    };
  };

  const getRequirementsWithRange = (id, min, max) => {
    return {
      id: 'numbers',
      label: getRequirePasswordStringsWithRange(id, min, max),
    };
  };

  const removeItems = ['minimumCharacter', 'maximumCharacter'];
  const newArray = regexData && regexData.filter((v) => !removeItems.includes(v.id));

  if (constraintDataType === 'string') {
    if (minimumVal && minimumVal > 0) {
      requirementsRegex = [
        getRequirementsWithCount(regexData, 'minimumCharacter', minimumVal),
        {
          id: 'regexCharacter',
          label: getRequirePasswordStringsWithCount('regexCharacter', requireRegexCharacter),
          requirements: newArray && newArray.map((data) => getRequirements(data, data.id)),
        },
      ];
    } else {
      requirementsRegex =
        regexData &&
        regexData
          .map((data) => (data.id !== 'maximumCharacter' ? getRequirements(data, data.id) : null))
          .filter(Boolean);
    }
  } else {
    const requirementsArray = [getRequirementsWithRange('error_range', minimumVal, maximumVal)];
    if (minimumVal && minimumVal > 0) {
      const minimumValLength = minimumVal.toString().length;
      requirementsRegex = [
        {
          ...getRequirementsWithCount(regexData, 'minimumCharacter', minimumValLength),
          requirements: requirementsArray,
        },
      ];
    } else {
      requirementsRegex = requirementsArray;
    }
  }

  const [errorState, setErrorState] = React.useState(false);
  const [confPassErrorState, setConfPassErrorState] = React.useState(false);
  const [requirementsItems, setRequirementsItems] = React.useState(requirementsRegex);

  React.useEffect(() => {
    if (minPasswordLength && minPasswordLength > 0) {
      if (!passwordComplexityValue || passwordComplexityValue === 'false') {
        const filteredPeople = requirementsRegex.filter(
          (item) =>
            item.id !== 'regexCharacter' &&
            item.id !== 'upperCase' &&
            item.id !== 'lowercase' &&
            item.id !== 'numbers' &&
            item.id !== 'specialCharacter',
        );
        delete filteredPeople[0].requirements;
        setRequirementsItems(filteredPeople);
      } else {
        setRequirementsItems(requirementsRegex);
      }
    } else {
      if (!passwordComplexityValue || passwordComplexityValue === 'false') {
        setRequirementsItems([]);
      }
      setRequirementsItems([]);
    }
  }, [minPasswordLength]);

  React.useEffect(() => {
    if (minPasswordLength && minPasswordLength > 0) {
      if (!passwordComplexityValue || passwordComplexityValue === 'false') {
        const filteredPeople = requirementsRegex.filter((item) => {
          return (
            item.id !== 'regexCharacter' &&
            item.id !== 'upperCase' &&
            item.id !== 'lowercase' &&
            item.id !== 'numbers' &&
            item.id !== 'specialCharacter'
          );
        });
        delete filteredPeople[0].requirements;
        setRequirementsItems(filteredPeople);
      } else {
        setRequirementsItems(requirementsRegex);
      }
    } else {
      if (!passwordComplexityValue || passwordComplexityValue === 'false') {
        setRequirementsItems([]);
      }
      setRequirementsItems([]);
    }
  }, [passwordComplexityValue]);

  const errorMessageObj = {
    requirementsErrorTitle: getRequirePasswordStrings('error-message'),
    toggleButtonAriaLabel: '',
  };

  const [hide, setHide] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState(errorMessageObj);

  const [confirmPasswordError, setConfirmPasswordError] = React.useState(errorMessageObj);

  const minimumCharactersRegex = regexData.filter((data) => data.id == 'minimumCharacter')[0]
    ?.regex;
  const minimumCharacters = new RegExp(minimumCharactersRegex);

  function validateIntPassword(event) {
    const updateRequirementStatus = (requirements, id, condition) => {
      const index = requirements.findIndex((el) => el.id === id);
      requirements[index]['status'] = condition ? 'complete' : 'incomplete';
      return !condition;
    };

    const updateNumberRequirementStatus = (requirements, id, condition, errorCondition) => {
      const index = requirements.findIndex((el) => el.id === id);
      requirements[index]['status'] = condition
        ? 'complete'
        : errorCondition
        ? 'error'
        : 'incomplete';
      return !condition;
    };

    const copyRequirements = [...requirementsItems];
    let err = false;

    if (event.length) {
      if (minimumCharactersRegex) {
        err = updateRequirementStatus(
          copyRequirements,
          'minimumCharacter',
          minimumCharacters.test(event),
        );
      }
      if (/^-?\d+$/.test(event)) {
        if (minimumVal && !maximumVal && withCharacter) {
          err = updateRequirementStatus(
            copyRequirements[0].requirements,
            'numbers',
            event >= minimumVal,
          );
        }
        if (!minimumVal && maximumVal && withCharacter) {
          err = updateRequirementStatus(
            copyRequirements[0].requirements,
            'numbers',
            event <= maximumVal,
          );
        }
        if (minimumVal && maximumVal && withCharacter) {
          err = updateNumberRequirementStatus(
            copyRequirements[0].requirements,
            'numbers',
            event >= minimumVal && event <= maximumVal,
            event > maximumVal,
          );
        }
      } else {
        err = updateRequirementStatus(copyRequirements, 'numbers', false);
      }
    }

    if (err) {
      setPasswordError(errorMessageObj);
    }

    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }

  function validateConfirmPassword(inputVal, confirmPwdValue, errorMessage) {
    let err = false;
    if (confirmPwdValue.length && inputVal !== confirmPwdValue) {
      err = true;
      setConfirmPasswordError({ requirementsErrorTitle: errorMessage, toggleButtonAriaLabel: '' });
    } else {
      err = false;
    }
    setConfPassErrorState(err);
    return err;
  }

  function validatePassword(event) {
    const maximumCharactersRegex = regexData.filter((data) => data.id == 'maximumCharacter')[0]
      ?.regex;
    const lowerCaseRegex = regexData.filter((data) => data.id == 'lowercase')[0]?.regex;
    const upperCaseRegex = regexData.filter((data) => data.id == 'upperCase')[0]?.regex;
    const numberRegex = regexData.filter((data) => data.id == 'numbers')[0]?.regex;
    const specialCharacterRegex = regexData.filter((data) => data.id == 'specialCharacter')[0]
      ?.regex;
    const maximumCharacters = new RegExp(maximumCharactersRegex);
    const lowerCase = new RegExp(lowerCaseRegex);
    const upperCase = new RegExp(upperCaseRegex);
    const number = new RegExp(numberRegex);
    const specialCharacter = new RegExp(specialCharacterRegex);
    const copyRequirements = [...requirementsItems];

    const indexValue = (idValue) => {
      return copyRequirements.findIndex((el) => el.id === idValue);
    };

    let err = false;
    let minMaxPassErr = false;
    if (settingsID === 'ews-admin-Proposed-password') {
      const index = indexValue('minimumCharacter');

      if (minimumCharactersRegex && index >= 0) {
        if (event.length >= minimumVal) {
          copyRequirements[index]['status'] = 'complete';
          minMaxPassErr = false;
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
          minMaxPassErr = true;
        }
      }
    } else {
      if (minimumCharactersRegex) {
        const index = indexValue('minimumCharacter');
        if (minimumCharacters.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
        }
      }
    }
    if (maximumCharactersRegex) {
      const index = indexValue('minimumCharacter');
      if (withCharacter) {
        if (maximumCharacters.test(event)) {
          err = false;

          copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
            'minimumCharacter',
            minimumVal,
          );

          const minPasswordLengthRegex = `^.{${minPasswordLength},}$`;
          const minPasswordLengthRegexVal = new RegExp(minPasswordLengthRegex);

          if (
            settingsID === 'ews-admin-Proposed-password'
              ? minPasswordLengthRegexVal.test(event)
              : minimumCharacters.test(event)
          ) {
            copyRequirements[index]['status'] = 'complete';
            minMaxPassErr = false;
          } else {
            copyRequirements[index]['status'] = 'incomplete';
            err = true;
            minMaxPassErr = true;
          }
        } else {
          err = true;
          minMaxPassErr = true;
          copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
            'passwordCharacters',
            maximumVal,
          );
          copyRequirements[index]['status'] = 'error';
        }
      }
    }

    function passwordComplexity() {
      if (upperCaseRegex) {
        if (withCharacter) {
          const index = copyRequirements[1].requirements.findIndex((el) => el.id === 'upperCase');
          if (upperCase.test(event)) {
            copyRequirements[1].requirements[index]['status'] = 'complete';
          } else {
            copyRequirements[1].requirements[index]['status'] = 'incomplete';
            err = true;
            setPasswordError(errorMessageObj);
          }
        }
      }
      if (lowerCaseRegex) {
        if (withCharacter) {
          const index = copyRequirements[1].requirements.findIndex((el) => el.id === 'lowercase');
          if (lowerCase.test(event)) {
            copyRequirements[1].requirements[index]['status'] = 'complete';
          } else {
            copyRequirements[1].requirements[index]['status'] = 'incomplete';
            err = true;
            setPasswordError(errorMessageObj);
          }
        }
      }
      if (numberRegex) {
        if (withCharacter) {
          const index = copyRequirements[1].requirements.findIndex((el) => el.id === 'numbers');
          if (number.test(event)) {
            copyRequirements[1].requirements[index]['status'] = 'complete';
          } else {
            copyRequirements[1].requirements[index]['status'] = 'incomplete';
            err = true;
            setPasswordError(errorMessageObj);
          }
        }
      }
      if (specialCharacterRegex) {
        if (withCharacter) {
          const index = copyRequirements[1].requirements.findIndex(
            (el) => el.id === 'specialCharacter',
          );
          if (specialCharacter.test(event)) {
            copyRequirements[1].requirements[index]['status'] = 'complete';
          } else {
            copyRequirements[1].requirements[index]['status'] = 'incomplete';
            err = true;
            setPasswordError(errorMessageObj);
          }
        }
      }
    }

    if (settingsID === 'ews-admin-Proposed-password') {
      const passwordComplexityRes =
        typeof passwordComplexityValue === 'string'
          ? passwordComplexityValue === 'true'
          : passwordComplexityValue;
      if (passwordComplexityRes && minPasswordLength && minPasswordLength !== '0') {
        passwordComplexity();

        const CompleteRegexChar = copyRequirements[1].requirements.filter(
          (_data) => _data.status === 'complete',
        ).length;

        if (CompleteRegexChar >= requireRegexCharacter) {
          copyRequirements[1]['status'] = 'complete';
          err = minMaxPassErr ? minMaxPassErr : false;
        } else {
          copyRequirements[1]['status'] = 'incomplete';
          err = true;
          setPasswordError(errorMessageObj);
        }
      }
    } else {
      passwordComplexity();
    }

    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }

  return {
    validatePassword,
    validateIntPassword,
    validateConfirmPassword,
    errorState,
    confPassErrorState,
    requirementsItems,
    hide,
    setHide,
    passwordError,
    confirmPasswordError,
  };
};
