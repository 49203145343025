import React from 'react';
import { InformationIcon } from '../Icons/InformationIcon';
import { InputLabel } from './InputLabel';
import { TitleContainer } from './Styles';

export const InputTitle = (props) => {
  const { title, tooltipMessage, disable, info, isTextBold , isContentAfter} = props;
  return (
    <TitleContainer>
       <InputLabel title={title} disable={disable} isTextBold={isTextBold} isContentAfter = {isContentAfter}/>
      {info ? (
        <InformationIcon
          infoMessage={tooltipMessage}
          size={16}
          color="colorGray5"
          isDisabled={disable}
          {...props}
        />
      ) : null}
    </TitleContainer>
  );
};
