import React from 'react';
import LocalizeHelper from '../helper/LocalizeHelper';
export const PasswordValidator = (regexData, constraintsData) => {
  let requirementsRegex;
  let withCharacter;
  const minimumVal = parseInt(constraintsData.minimum);
  const maximumVal = parseInt(constraintsData.maximum);
  const constraintDataType = constraintsData.type;
  const {
    getRequirePasswordStrings,
    getRequirePasswordStringsWithCount,
    getRequirePasswordStringsWithRange,
  } = LocalizeHelper();
  if (minimumVal && minimumVal > 0) {
    withCharacter = true;
  } else {
    withCharacter = false;
  }
  if (constraintDataType == 'string') {
    if (minimumVal && minimumVal > 0) {
      const removeItems = ['minimumCharacter', 'maximumCharacter'];
      const newArray = regexData && regexData.filter((v) => !removeItems.includes(v.id));
      requirementsRegex = [
        {
          id: regexData && regexData.filter((data) => data.id == 'minimumCharacter')[0]?.id,
          label: getRequirePasswordStringsWithCount('minimumCharacter', minimumVal),
          requirements:
            newArray &&
            newArray.map((data) => {
              return {
                id: data.id,
                label: getRequirePasswordStrings(data.id),
              };
            }),
        },
      ];
    } else {
      requirementsRegex =
        regexData &&
        regexData
          .map((data, index) => {
            if (data.id !== 'maximumCharacter') {
              return {
                id: data.id,
                label: getRequirePasswordStrings(data.id),
              };
            }
          })
          .filter((data) => data != undefined);
    }
  } else {
    if (minimumVal && minimumVal > 0) {
      const minimumValLength = minimumVal.toString().length;
      requirementsRegex = [
        {
          id: regexData && regexData.filter((data) => data.id == 'minimumCharacter')[0]?.id,
          label: getRequirePasswordStringsWithCount('minimumCharacter', minimumValLength),
          requirements: [
            {
              id: 'numbers',
              label: getRequirePasswordStringsWithRange('error_range', minimumVal, maximumVal),
            },
          ],
        },
      ];
    } else {
      requirementsRegex = [
        {
          id: 'numbers',
          label: getRequirePasswordStringsWithRange('error_range', minimumVal, maximumVal),
        },
      ];
    }
  }
  const [errorState, setErrorState] = React.useState(false);
  const [confPassErrorState, setConfPassErrorState] = React.useState(false);
  const [requirementsItems, setRequirementsItems] = React.useState(requirementsRegex);
  const [hide, setHide] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState({
    requirementsErrorTitle: getRequirePasswordStrings('error-message'),
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState({
    requirementsErrorTitle: getRequirePasswordStrings('error-message'),
    toggleButtonAriaLabel: '',
  });
  function validatePassword(event) {
    const minimumCharactersRegex = regexData.filter((data) => data.id == 'minimumCharacter')[0]
      ?.regex;
    const maximumCharactersRegex = regexData.filter((data) => data.id == 'maximumCharacter')[0]
      ?.regex;
    const lowerCaseRegex = regexData.filter((data) => data.id == 'lowercase')[0]?.regex;
    const upperCaseRegex = regexData.filter((data) => data.id == 'upperCase')[0]?.regex;
    const numberRegex = regexData.filter((data) => data.id == 'numbers')[0]?.regex;
    const specialCharacterRegex = regexData.filter((data) => data.id == 'specialCharacter')[0]
      ?.regex;
    const minimumCharacters = new RegExp(minimumCharactersRegex);
    const maximumCharacters = new RegExp(maximumCharactersRegex);
    const lowerCase = new RegExp(lowerCaseRegex);
    const upperCase = new RegExp(upperCaseRegex);
    const number = new RegExp(numberRegex);
    const specialCharacter = new RegExp(specialCharacterRegex);
    const copyRequirements = [...requirementsItems];
    let err = false;
    if (minimumCharactersRegex) {
      const index = copyRequirements.findIndex((el) => el.id === 'minimumCharacter');
      if (minimumCharacters.test(event)) {
        copyRequirements[index]['status'] = 'complete';
      } else {
        copyRequirements[index]['status'] = 'incomplete';
        err = true;
      }
    }
    if (maximumCharactersRegex) {
      const index = copyRequirements.findIndex((el) => el.id === 'minimumCharacter');
      if (withCharacter) {
        if (maximumCharacters.test(event)) {
          err = false;
          copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
            'minimumCharacter',
            minimumVal,
          );
          if (minimumCharacters.test(event)) {
            copyRequirements[index]['status'] = 'complete';
          } else {
            copyRequirements[index]['status'] = 'incomplete';
            err = true;
          }
        } else {
          err = true;
          copyRequirements[index]['label'] = getRequirePasswordStringsWithCount(
            'passwordCharacters',
            maximumVal,
          );
          copyRequirements[index]['status'] = 'error';
        }
      } else {
        if (maximumCharacters.test(event)) {
          err = false;
          const isMaximum = copyRequirements.filter((el) => el.id === 'maximumCharacter');
          if (isMaximum.length) {
            copyRequirements.shift();
          }
        } else {
          err = true;
          const isMaximum = copyRequirements.filter((el) => el.id === 'maximumCharacter');
          if (!isMaximum.length) {
            copyRequirements.unshift({
              id: 'maximumCharacter',
              label: getRequirePasswordStringsWithCount('passwordCharacters', maximumVal),
              status: 'error',
            });
          }
        }
      }
    }
    if (upperCaseRegex) {
      if (withCharacter) {
        const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'upperCase');
        if (upperCase.test(event)) {
          copyRequirements[0].requirements[index]['status'] = 'complete';
        } else {
          copyRequirements[0].requirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      } else {
        const index = copyRequirements.findIndex((el) => el.id === 'upperCase');
        if (upperCase.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      }
    }
    if (lowerCaseRegex) {
      if (withCharacter) {
        const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'lowercase');
        if (lowerCase.test(event)) {
          copyRequirements[0].requirements[index]['status'] = 'complete';
        } else {
          copyRequirements[0].requirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      } else {
        const index = copyRequirements.findIndex((el) => el.id === 'lowercase');
        if (lowerCase.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      }
    }
    if (numberRegex) {
      if (withCharacter) {
        const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'numbers');
        if (number.test(event)) {
          copyRequirements[0].requirements[index]['status'] = 'complete';
        } else {
          copyRequirements[0].requirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      } else {
        const index = copyRequirements.findIndex((el) => el.id === 'numbers');
        if (number.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      }
    }
    if (specialCharacterRegex) {
      if (withCharacter) {
        const index = copyRequirements[0].requirements.findIndex(
          (el) => el.id === 'specialCharacter',
        );
        if (specialCharacter.test(event)) {
          copyRequirements[0].requirements[index]['status'] = 'complete';
        } else {
          copyRequirements[0].requirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      } else {
        const index = copyRequirements.findIndex((el) => el.id === 'specialCharacter');
        if (specialCharacter.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
          setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
        }
      }
    }
    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }
  function validateIntPassword(event) {
    const minimumCharactersRegex = regexData.filter((data) => data.id == 'minimumCharacter')[0]
      ?.regex;
    const minimumCharacters = new RegExp(minimumCharactersRegex);
    const copyRequirements = [...requirementsItems];
    let err = false;
    if (event.length) {
      if (minimumCharactersRegex) {
        const index = copyRequirements.findIndex((el) => el.id === 'minimumCharacter');
        if (minimumCharacters.test(event)) {
          copyRequirements[index]['status'] = 'complete';
        } else {
          copyRequirements[index]['status'] = 'incomplete';
          err = true;
        }
      }
      if (/^-?\d+$/.test(event)) {
        if (minimumVal && !maximumVal) {
          if (withCharacter) {
            const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'numbers');
            if (event >= minimumVal) {
              copyRequirements[0].requirements[index]['status'] = 'complete';
            } else {
              copyRequirements[0].requirements[index]['status'] = 'incomplete';
              err = true;
              setPasswordError({
                requirementsErrorTitle: getRequirePasswordStrings('error-message'),
              });
            }
          } else {
            const index = copyRequirements.findIndex((el) => el.id === 'numbers');
            if (event >= minimumVal) {
              copyRequirements[index]['status'] = 'complete';
            } else {
              copyRequirements[index]['status'] = 'incomplete';
              err = true;
              setPasswordError({
                requirementsErrorTitle: getRequirePasswordStrings('error-message'),
              });
            }
          }
        }
        if (!minimumVal && maximumVal) {
          if (withCharacter) {
            const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'numbers');
            if (event <= maximumVal) {
              copyRequirements[0].requirements[index]['status'] = 'complete';
            } else {
              copyRequirements[0].requirements[index]['status'] = 'incomplete';
              err = true;
              setPasswordError({
                requirementsErrorTitle: getRequirePasswordStrings('error-message'),
              });
            }
          } else {
            const index = copyRequirements.findIndex((el) => el.id === 'numbers');
            if (event <= maximumVal) {
              copyRequirements[index]['status'] = 'complete';
            } else {
              copyRequirements[index]['status'] = 'incomplete';
              err = true;
              setPasswordError({
                requirementsErrorTitle: getRequirePasswordStrings('error-message'),
              });
            }
          }
        }
        if (minimumVal && maximumVal) {
          if (withCharacter) {
            const index = copyRequirements[0].requirements.findIndex((el) => el.id === 'numbers');
            if (event >= minimumVal && event <= maximumVal) {
              copyRequirements[0].requirements[index]['status'] = 'complete';
            } else {
              if (event > maximumVal) {
                copyRequirements[0].requirements[index]['status'] = 'error';
                err = true;
                setPasswordError({
                  requirementsErrorTitle: getRequirePasswordStrings('error-message'),
                });
              } else {
                copyRequirements[0].requirements[index]['status'] = 'incomplete';
                err = true;
                setPasswordError({
                  requirementsErrorTitle: getRequirePasswordStrings('error-message'),
                });
              }
            }
          } else {
            const index = copyRequirements.findIndex((el) => el.id === 'numbers');
            if (event >= minimumVal && event <= maximumVal) {
              copyRequirements[index]['status'] = 'complete';
            } else {
              if (event > maximumVal) {
                copyRequirements[index]['status'] = 'error';
                err = true;
                setPasswordError({
                  requirementsErrorTitle: getRequirePasswordStrings('error-message'),
                });
              } else {
                copyRequirements[index]['status'] = 'incomplete';
                err = true;
                setPasswordError({
                  requirementsErrorTitle: getRequirePasswordStrings('error-message'),
                });
              }
            }
          }
        }
      } else {
        const index = copyRequirements.findIndex((el) => el.id === 'numbers');
        copyRequirements[index]['status'] = 'incomplete';
        err = true;
        setPasswordError({ requirementsErrorTitle: getRequirePasswordStrings('error-message') });
      }
    }
    setErrorState(err);
    setRequirementsItems(copyRequirements);
    return err;
  }
  function validateConfirmPassword(inputVal, confirmPwdValue, errorMessage) {
    let err = false;
    if (confirmPwdValue.length && inputVal !== confirmPwdValue) {
      err = true;
      setConfirmPasswordError({ requirementsErrorTitle: errorMessage, toggleButtonAriaLabel: '' });
    } else {
      err = false;
    }
    setConfPassErrorState(err);
    return err;
  }
  return {
    validatePassword,
    validateIntPassword,
    validateConfirmPassword,
    errorState,
    confPassErrorState,
    requirementsItems,
    hide,
    setHide,
    passwordError,
    confirmPasswordError,
  };
};
