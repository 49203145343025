import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import logger from '../../src/utils/logger';

class DeviceClient {
  baseUrl: string;
  authProvider: any;

  constructor(baseUrl: string, authProvider: any) {
    this.baseUrl = `${baseUrl}/v1`;
    this.authProvider = authProvider;
  }

  async getDeviceAppCollection(deviceId: string) {
    try {
      const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider);
      const response = await client.show(deviceId);
      return response.data;
    } catch (error) {
      logger.error('API error for getDeviceAppCollection in DeviceCacheClient : ', error.message);
      return null; // Ensure null is returned on error
    }
  }
}

export default DeviceClient;
