import React, { useEffect, useState } from 'react';
import CategoryTreeHelper from '../../../helper/CategoryTreeHelper';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';

const idAll = 'all';
const categorySeparator = '.';

const CategoryTree = (props) => {
  //props 
  const {isWex} = props;
  
  // helper
  const { getDeviceCategoryLocString } = LocalizeHelper();
  const { getPolicyCategories, filterPolicyAttributes } = CategoryTreeHelper();

  // Strings
  const categoryTitle = getDeviceCategoryLocString('title');
  const categoryAll = getDeviceCategoryLocString('all');

  const [selectedNode, setSelectedNode] = useState(idAll);
  const [categoryNodes, setCategoryNodes] = useState([]);
  const expandedCategoryNodes = [idAll];

  useEffect(() => {
    const populate = (start) => {
      return getPolicyCategories(start, props.featuresData).map((category) => {
        const startWith = [...start, category];
        const id = [idAll, ...startWith].join(categorySeparator);
        const attributes = filterPolicyAttributes(startWith, props.featuresData);
        setSelectedNode(id);
        expandedCategoryNodes.push(...expandedCategoryNodes, id);

        return {
          id,
          label: getDeviceCategoryLocString(category),
          nodes: populate(startWith),
          totalChildren: attributes.length,
        };
      });
    };
    const newNodes = populate([]);
    setCategoryNodes(newNodes);
  }, [props.featuresData]);

  const handleChange = (id) => {
    setSelectedNode(id);
  };

  return (
    <Style.CategoryTreeWrap>
      <Style.CategoryTreeTitle data-testid={'id-edit-modal-category-tree-title'}>{categoryTitle}</Style.CategoryTreeTitle>
      <Style.CategoryTreeColumn>
        <Style.CustomTreeView
          isWex={isWex}
          className={'custom-tree-view'}
          defaultExpandedNodes={expandedCategoryNodes}
          defaultSelectedNodes={[selectedNode]}
          selectedNodes={[selectedNode]}
          onChange={(e, id) => handleChange(id)}
          data-testid={'id-edit-modal-custom-tree-view'}
          nodes={[
            {
              id: idAll,
              label: categoryAll,
              totalChildren: props.featuresData.length || null,
              nodes: categoryNodes,
              className: 'customtreeview',
            },
          ]}
        />
      </Style.CategoryTreeColumn>
    </Style.CategoryTreeWrap>
  );
};
export default CategoryTree;
