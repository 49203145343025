import { RootStateOrAny, useSelector } from 'react-redux';

export const useStoreState = () => {
  const configState = useSelector((state: RootStateOrAny) => state.configReducer);
  const configureDevicesState = useSelector(
    (state: RootStateOrAny) => state.configureDevicesReducer,
  );
  const configCategoryDataState = useSelector((state: RootStateOrAny) => state.categoryReducer);
  const configBatchModeDataState = useSelector((state: RootStateOrAny) => state.batchModeReducer);
  const deviceConfigDataState = useSelector((state: RootStateOrAny) => state.deviceConfigReducer);

  return {
    configState,
    configureDevicesState,
    configCategoryDataState,
    configBatchModeDataState,
    deviceConfigDataState,
  };
};
