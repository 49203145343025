import styled from 'styled-components';
import { FlexColumnDirection, FlexRowAlignCenterBetween } from '../../GlobalStyles/GlobalStyles';

export const ModalBody = styled.div`
  height: calc(100% - 208px); //(96px -> header)
  display: flex;
  flex-wrap: wrap;
  // height: 100%;
  padding: 34px 24px 28px 26px;
`;

export const ModalBodyRow = styled.div`
  display: flex;
  height: 100%;
  flex: 1 0 100%;
  gap: 24px;
`;

export const CategoryTreeWrap = styled(FlexColumnDirection)`
  width: 350px;
  flex-shrink: 0;
  @media (max-width: 991px) {
    width: 25%;
  }
`;

export const CategoryWrap = styled(FlexColumnDirection)`
  flex-basis: 100%;
`;

export const EditFiltersWrap = styled(FlexRowAlignCenterBetween)``;
