const TextBoxValidator = (description, value) => {
  const { constraints } = description;

  let errorType = '';
  let errorCharLength = '';
  let errorMinRange = '';
  let errorMaxRange = '';

  const minimum = constraints.minimum;
  const maximum = constraints.maximum;
  const dataType = constraints.type;
  const dataRegex = new RegExp(constraints.regex);

  const checkValue = value || '';

  if (checkValue.length) {
    if (minimum !== undefined) {
      if (dataType === 'string') {
        if (value.trim().length <= 0) {
          errorType = 'error_empty';
        } else if (value.length < minimum) {
          errorType = 'error_minimum';
          errorCharLength = minimum;
        }
      } else {
        if (value < minimum) {
          errorType = 'error_range';
          errorMinRange = minimum;
          errorMaxRange = maximum;
        }
      }
    }
    if (maximum !== undefined) {
      if (dataType === 'string') {
        if (value.length > maximum) {
          errorType = 'error_maximum';
          errorCharLength = maximum;
        }
      } else {
        if (value > maximum) {
          errorType = 'error_range';
          errorMinRange = minimum;
          errorMaxRange = maximum;
        }
      }
    }
    if (constraints.regex !== undefined && errorType == '') {
      if (!dataRegex.test(value)) {
        errorType = 'error_regex';
      }
    }
  }

  return { errorType, errorCharLength, errorMinRange, errorMaxRange };
};

export default TextBoxValidator;
