import AssetsProviderFactory from '../../src/assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import ECPAssetsProvider from '../../src/assets/ECPAssetsProvider';

type ManageMfeProps = {
  country?: string;
  language?: string;
  authProvider: JarvisAuthProvider;
};

let localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  authProvider: null,
};

export const setProps = (props) => {
  localProps.authProvider = props.authProvider;
  localProps.language = props.localization.language;
  localProps.country = props.localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string) => {
  const props = getProps();
  const assetsProvider: ECPAssetsProvider = AssetsProviderFactory.create(
    props?.language ?? 'en',
    props?.country ?? 'US',
  );
  const assetKey = `${subkey}`;

  return assetsProvider.getText(assetKey);
};
