import { colorGray4, colorGray7 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenter, styleAttributes11, theme } from '../../../GlobalStyles/GlobalStyles';
import { Table } from '@veneer/core';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens';

export const StyledTable = styled(Table)`
  tbody > tr:nth-of-type(2n + 1) {
    background: ${(props) => (props.wex ? 'rgba(77, 82, 229, 0.1)' : `${Veneer.colorGray1}`)};
  }
`;

export const SubFeatureValue = styled(FlexRowAlignCenter)`
  ${styleAttributes11}
  font-family: Forma DJR UI !important;
  white-space: break-spaces;
  flex-wrap: wrap;
`;

export const SubFeatureText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.type === 'text' ? '3' : 'unset')};
  -webkit-box-orient: vertical;
  overflow: ${(props) => (props.type === 'text' ? 'hidden' : 'auto')};
  max-height: ${(props) => (props.type === 'text' ? 'calc(3 * 1.5 * 14px)' : 'none')};
  font-size: 14px;
  line-height: 1.5;
`;

export const SubFeatureTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const SubFeatureTextButton = styled.div`
  display: flex;
  align-items: end;
  color: #035c84;
  cursor: pointer;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumnTableWrap = styled.div`
  flex-shrink: 0;
  flex-basis: 100%;
  margin-bottom: 24px;
`;

export const FlexColumnTable = styled.div``;

export const TextBlack = styled.div`
  font-family: ${theme.fontFamilyPrimary};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
  overflow-wrap: anywhere;
  padding-bottom: 4px;
`;
