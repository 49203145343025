import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import LocalizeHelper from './LocalizeHelper';

const CustomValidationHelper = (editViewData, setCustomErrorData) => {
  const { getRequirePasswordStrings } = LocalizeHelper();

  const snmpV3ValidateIds = [
    `${FleetSvcPolicyAttributeId.SNMPV3}.user-name`,
    `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
  ];

  const ewsMinPasswordValidateIds = [
    `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.min-length`,
    `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
  ];

  const checkSnmpV3AuthPass = (_name, _value) => {
    const valueTrim = _value?.trim();
    const lowerValue = valueTrim?.toLowerCase();
    const checkAttr = `${FleetSvcPolicyAttributeId.SNMPV3}.user-name`;

    const filteredSettingsData = editViewData
      .filter((el) => {
        return snmpV3ValidateIds.some((f) => {
          return f === el.name;
        });
      })
      .map((data) => {
        if (data.name === _name) {
          return [{ name: data.name, value: lowerValue }];
        } else {
          return [{ name: data.name, value: data.value ? data.value.toLowerCase() : '' }];
        }
      })
      .flat(1);

    const passPhraseValue = filteredSettingsData
      .map(function (_item) {
        if (_item.name !== checkAttr) {
          return _item.value;
        }
      })
      .filter(function (_res) {
        return _res !== undefined;
      })[0];

    const userNameValue = filteredSettingsData
      .map(function (_item) {
        if (_item.name === checkAttr) {
          return _item.value;
        }
      })
      .filter(function (_res) {
        return _res !== undefined;
      })[0];

    const isContains =
      lowerValue !== '' && userNameValue ? passPhraseValue?.indexOf(userNameValue) !== -1 : false;

    if (isContains) {
      setCustomErrorData({
        name: `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
        error: true,
        errorMsg: { requirementsErrorTitle: getRequirePasswordStrings('notContainUserNameError') },
      });

      return {
        name: `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
        error: true,
        errorMsg: { requirementsErrorTitle: getRequirePasswordStrings('notContainUserNameError') },
      };
    } else {
      setCustomErrorData({
        name: `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
        error: false,
        errorMsg: '',
      });

      return {
        name: `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
        error: false,
        errorMsg: '',
      };
    }
  };

  const checkEwsMinPasswordLength = (_name, _value) => {
    const valueTrim = isNaN(_value) ? _value?.trim() : _value;
    const lowerValue = isNaN(valueTrim) ? valueTrim?.toLowerCase() : valueTrim;
    const checkAttr = `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.min-length`;

    const filteredSettingsData = editViewData
      .filter((el) => {
        return ewsMinPasswordValidateIds.some((f) => {
          return f === el.name;
        });
      })
      .map((data) => {
        if (data.name === _name) {
          return [{ name: data.name, value: lowerValue }];
        } else {
          return [
            {
              name: data.name,
              value: data.value ? (isNaN(data.value) ? data.value.toLowerCase() : data.value) : '',
            },
          ];
        }
      })
      .flat(1);

    const ewsPasswordValue = filteredSettingsData
      .map(function (_item) {
        if (_item.name !== checkAttr) {
          return _item.value;
        }
      })
      .filter(function (_res) {
        return _res !== undefined;
      })[0];

    const minPassValue = filteredSettingsData
      .map(function (_item) {
        if (_item.name === checkAttr) {
          return _item.value;
        }
      })
      .filter(function (_res) {
        return _res !== undefined;
      })[0];

    const isNotEquals = ewsPasswordValue
      ? minPassValue
        ? ewsPasswordValue.length < minPassValue
        : false
      : false;

    if (isNotEquals) {
      setCustomErrorData({
        name: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
        error: true,
        errorMsg: { requirementsErrorTitle: getRequirePasswordStrings('error-message') },
      });

      return {
        name: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
        error: true,
        errorMsg: { requirementsErrorTitle: getRequirePasswordStrings('error-message') },
      };
    } else {
      setCustomErrorData({
        name: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
        error: false,
        errorMsg: '',
      });

      return {
        name: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
        error: false,
        errorMsg: '',
      };
    }
  };

  const getCustomValidate = (settings) => {
    let result;
    switch (settings.id) {
      case `${FleetSvcPolicyAttributeId.SNMPV3}.user-name`:
      case `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`:
        result = checkSnmpV3AuthPass(settings.id, settings.value);
        break;

      case `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`:
      case `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.min-length`:
        result = checkEwsMinPasswordLength(settings.id, settings.value);
        break;
    }

    return result;
  };

  return { getCustomValidate };
};

export default CustomValidationHelper;
