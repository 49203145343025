import { CategoryEnum, CategoryTreeEnum } from '../config/CategoryEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import { CategoryTree } from '../dataModel/CategoryTree';

const CategoryTreeHelper = () => {
  const { policyCategories } = CategoryTree();
  const getPolicyAttributeCategory = (id: CategoryEnum | string): CategoryEnum[] => {
    const found = policyCategories.find((value) => value.id === id);
    return found ? found.categories : [];
  };

  const getPolicyAttributeDescription = (id: FleetSvcPolicyAttributeId | string) => {
    const categories = getPolicyAttributeCategory(id);
    return categories ? categories : [];
  };

  const filterPolicyAttributes = (
    startsWith: CategoryEnum[],
    attributes: { id: CategoryEnum | string }[],
  ): any[] => {
    return attributes.filter((attribute) => {
      const policyCategory: CategoryEnum[] = getPolicyAttributeCategory(attribute.id);
      return (
        policyCategory &&
        policyCategory.length >= startsWith.length &&
        startsWith.every((cat, i) => cat === policyCategory[i])
      );
    });
  };

  const getPolicyCategories = (
    startsWith: CategoryEnum[],
    attributes: { id: CategoryEnum | string }[],
  ): CategoryEnum[] => {
    const categories = [];
    attributes.forEach((attribute) => {
      const policyCategory: CategoryEnum[] = getPolicyAttributeCategory(attribute.id);

      if (
        policyCategory &&
        policyCategory.length > startsWith.length &&
        startsWith.every((cat, i) => cat === policyCategory[i]) &&
        !categories.includes(policyCategory[startsWith.length])
      ) {
        categories.push(policyCategory[startsWith.length]);
      }
    });

    if (categories.length) {
      const sortedCategories = [];
      for (const category in CategoryTreeEnum) {
        if (categories.includes(CategoryTreeEnum[category])) {
          sortedCategories.push(CategoryTreeEnum[category]);
        }
      }
      return sortedCategories;
    }

    return [];
  };

  return {
    getPolicyAttributeCategory,
    getPolicyAttributeDescription,
    filterPolicyAttributes,
    getPolicyCategories,
  };
};

export default CategoryTreeHelper;
