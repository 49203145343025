import { Button } from '@veneer/core';
import styled from 'styled-components';
import {
  FlexColumnDirection,
  FlexRowAlignCenter,
  styleAttributes3,
  styleAttributes5,
} from '../../../GlobalStyles/GlobalStyles';

export const FlexRow = styled.div`
  display: flex;
`;

export const EditFeatureHeaderWrap = styled(FlexColumnDirection)``;

export const CategoryNameWrapper = styled(FlexRow)`
  ${styleAttributes5}
  margin-top: '4px';
`;

export const EditFeatureHeader = styled(FlexRowAlignCenter)``;

export const EditFeatureIcon = styled(FlexRow)``;

// Start
export const EditFeatureTitle = styled.div`
  ${styleAttributes3}
`;

export const InfoButton = styled(Button)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')}!important;
}
  padding: 4px !important;
  border: 0px !important;
`;
