import { categoryStore } from '../../../src/utils/constants';

const initialState = {
  rulesData: [],
  policyData: [],
  featuresData: [],
  settingsData: [],
  tempData: '',
  loading: false,
  controlledPolicyError: null,
};

const batchModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryStore.config.SET_BATCH_RULES_DATA:
      return {
        ...state,
        rulesData: [...state.rulesData, ...action.payload],
      };
    case categoryStore.config.SET_BATCH_POLICY_DATA:
      return {
        ...state,
        policyData: [...state.policyData, ...action.payload],
      };
    case categoryStore.config.SET_BATCH_FEATURES_DATA:
      return {
        ...state,
        featuresData: [...state.featuresData, ...action.payload],
      };
    case categoryStore.config.SET_BATCH_SETTINGS_DATA:
      return {
        ...state,
        settingsData: [...state.settingsData, ...action.payload],
      };
    case categoryStore.config.SET_TEMP_BATCH_DATA:
      return {
        ...state,
        tempData: action.payload,
      };
    case categoryStore.config.SET_CONTROLLED_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        controlledPolicyError: null,
      };
    case categoryStore.config.SET_CONTROLLED_POLICY:
      return {
        ...state,
        policyData: state.policyData.map((element) => {
          for (const i in element) {
            return {
              [i]: element[i].map((data) => {
                const id = data.id.substring(3);
                const filteredPolicy = action.payload.length
                  ? action.payload.filter((policy) => policy.attributeName == id)
                  : [];
                return {
                  ...data,
                  controlledPolicy: filteredPolicy.length ? filteredPolicy[0].controlledPolicy : [],
                };
              }),
            };
          }
        }),
        featuresData: state.featuresData.map((element) => {
          const id = element.id.substring(3);
          const filteredPolicy = action.payload.filter((policy) => policy.attributeName == id);
          return {
            ...element,
            controlledPolicy: filteredPolicy.length ? filteredPolicy[0].controlledPolicy : [],
          };
        }),
        loading: false,
      };
    case categoryStore.config.SET_CONTROLLED_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        controlledPolicyError: action.payload,
      };
    case categoryStore.config.RESET_BATCH_INITIAL_DATA:
      return {
        ...state,
        rulesData: [],
        policyData: [],
        featuresData: [],
        settingsData: [],
      };
    default:
      return state;
  }
};

export default batchModeReducer;
