import React from 'react';
import { useDispatch } from 'react-redux';
import { getConfigStoreActions } from '../../../src/store/config/init';
import { useStoreState } from '../../../src/store/useStoreState';
import { useConstructor } from '../../../src/utils/customHook';
import { setProps } from '../../../src/utils/multiLang';
import { DeviceConfiguration } from '../Main/DeviceConfiguration';
import { useFlags } from 'launchdarkly-react-client-sdk'

export const RootComponent = (props) => {
  const { configState } = useStoreState();
  const { startRootComponent } = configState;
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));
  let LDFlags = useFlags();

  // init all redux stores
  useConstructor(() => {
    setProps(props);

    dispatchAll(getConfigStoreActions(props));
  });

  return (
    startRootComponent && (
      <>
        <DeviceConfiguration {...props} LDFlags={LDFlags} />
      </>
    )
  );
};
