import {Modal} from '@veneer/core';
import styled from 'styled-components';

export const InfoModalHeader = styled.h1`
  font-family: Forma DJR Micro;
  color: #212121;
  font-size: 32px;
  line-height: 40px;
`;
export const InfoModalContent = styled.p`
  font-family: Forma DJR Micro;
  color: #212121;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`;

export const InfoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const InfoModalWrap = styled(Modal)`
  .vn-modal--content {
    width: calc(100% - 150px - 150px);
    padding: 40px 40px 44px;
    @media (max-width: 991px) {
      width: calc(100% - 24px - 24px);
    }
  }
  .vn-modal__close {
    top: 20px;
    right: 20px;
    @media (max-width: 991px) {
      top: 8px;
      right: 8px;
    }
  }
`;
