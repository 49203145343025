import React, { Fragment } from 'react';
import RadioButtonControl from './Controls/RadioButtonControl';

const LdapServerAuth = (props) => {
  return (
    <Fragment>
      <RadioButtonControl {...props} />
    </Fragment>
  );
};

export default LdapServerAuth;

