import { useI18n } from '@jarvis/react-portal-addons';
import { IconHelp } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext, useState } from 'react';
import { AppConfig } from '../../../../../src/config/App.config';
import EditDataContext from '../../../../context/EditDataContext';
import CategoryTreeHelper from '../../../../helper/CategoryTreeHelper';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import InfoModal from '../../../CommonExtended/InfoModal/InfoModal';
import { EntitlementFeature } from '../../../EntitlementFeature/EntitlementFeature';
import * as Style from './Styles';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const FeatureHeader = (props) => {
  const { features, selectedData } = props;
  const { getPolicyAttributeDescription } = CategoryTreeHelper();
  const { t } = useI18n();
  const { getDeviceFeatureLocString, getDeviceCategoryLocString, getDeviceFeatureModalString } =
    LocalizeHelper();
  const { checkboxFeature, onUpdateCheckedData, searchValue } = useContext(EditDataContext);
  const name = getDeviceFeatureLocString(features.id, 'name');
  const description = getDeviceFeatureModalString(features.id, 'description');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;

  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };
  const category = getPolicyAttributeDescription(features.id);
  const categoryLevel = category.map((x) => getDeviceCategoryLocString(x)).join(' > ');
  const isChecked = checkboxFeature.filter((el) => el.id === features.id)[0].inputDisable;
  const onChangeCheckBox = (e) => {
    onUpdateCheckedData(e);
  };

  function getHighlightedText(text, highlight) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <Style.DecorateNameWrap
            key={i}
            color={
              part.toLowerCase() === highlight.toString().toLowerCase() ? '#9FE5FF' : 'transparent'
            }
          >
            {part}
          </Style.DecorateNameWrap>
        ))}
      </span>
    );
  }

  const featureComponent = () => {
    return (
      <Fragment>
        <Style.FeatureTitleWrap>
          <Style.FeatureTitle data-testid={'id-batch-view-edit-feature-title'}>
            {getHighlightedText(name, searchValue)}
          </Style.FeatureTitle>
          <Style.InfoButton
            onClick={() => {
              handleInfoModal();
            }}
            appearance="secondary"
            leadingIcon={<IconHelp color="colorHpBlue7" size={20} />}
            data-testid={'id-batch-view-info-icon'}
          />
          {AppConfig.entitlementFeature.featureFlag &&
          features.entitlementId &&
          ldAdvancedEntitlement ? (
            <EntitlementFeature entitlementId={ldAdvancedEntitlement} styling={'batchView'} />
          ) : null}
        </Style.FeatureTitleWrap>
      </Fragment>
    );
  };

  return (
    <Style.EditFeatureHeaderWrap>
      <Style.EditFeatureHeader>
        <Style.EditFeatureIcon>
          <Style.FeatureTitleCheckBox
            id="checkbox-unchecked1"
            label={featureComponent()}
            name={`${features.id}`}
            checked={!isChecked}
            onChange={(e) => onChangeCheckBox(e)}
          />
          <InfoModal
            show={showInfoModal}
            handleClose={handleInfoModal}
            title={name}
            content={description}
            data-testid={'id-batch-view-info-modal'}
          />
        </Style.EditFeatureIcon>
      </Style.EditFeatureHeader>
    </Style.EditFeatureHeaderWrap>
  );
};

export default FeatureHeader;
