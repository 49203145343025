import { IconWarningAlt } from '@veneer/core';
import React, { useContext } from 'react';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';
export const NoFilterData = (props) => {
  const { getCommonStrings, getDeviceCategoryLocString } = LocalizeHelper();

  const { selectedCategoryName, setSelectedNode, setSelectedCategory } =
    useContext(EditDataContext);

  const idAll = 'all';

  const handleOnChangeAllCate = () => {
    setSelectedNode(idAll);

    setSelectedCategory([]);
  };

  return (
    <Style.NoFilterDataCard>
      <Style.WarningIconWrap>
        <Style.WarningIconBorder>
          <IconWarningAlt size={24} />
        </Style.WarningIconBorder>
      </Style.WarningIconWrap>

      <Style.NoFilterDataDescWrap>
        <Style.NoFilterDataDesc1>
          {!props.checkedFeaturesToggle && props.searchValue.length
            ? getCommonStrings('searchNotFound', {
                category: !!selectedCategoryName
                  ? getDeviceCategoryLocString(selectedCategoryName)
                  : getDeviceCategoryLocString('all'),
              })
            : getCommonStrings('selectNotFound', {
                category: !!selectedCategoryName
                  ? getDeviceCategoryLocString(selectedCategoryName)
                  : getDeviceCategoryLocString('all'),
              })}
        </Style.NoFilterDataDesc1>

        <Style.NoFilterDataDesc1>
          {!props.checkedFeaturesToggle && props.searchValue.length
            ? getCommonStrings('searchNotFoundLabel')
            : getCommonStrings('selectNotFoundLabel')}
        </Style.NoFilterDataDesc1>

        {!props.checkedFeaturesToggle && props.searchValue.length ? (
          <Style.NoFilterDataDesc2 onClick={handleOnChangeAllCate}>
            {getCommonStrings('searchInCategory', { category: getDeviceCategoryLocString('all') })} 
          </Style.NoFilterDataDesc2>
        ) : null}
      </Style.NoFilterDataDescWrap>
    </Style.NoFilterDataCard>
  );
};
