import React, { Fragment } from 'react';
import { FeatureIdEnum } from '../../../../config/FeatureEnums';
import Ipv4 from '../../../CustomizableComponents/Ipv4/Ipv4';
import OutgoingServersControl from '../../../CustomizableComponents/OutGoingServers/OutgoingServersControl';

const CustomFeatureSettingsList = (props) => {
  let component;
  switch (props.id) {
    case FeatureIdEnum.IPv4_information:
      component = <Ipv4 {...props} />;
      break;
    case FeatureIdEnum.Outgoing_Servers:
      component = <OutgoingServersControl {...props} />;
      break;
  }

  return <Fragment>{component}</Fragment>;
};

export default CustomFeatureSettingsList;
