export enum FeatureIdEnum {
  Asset_Number = 'fe-asset-number',
  Company_Name = 'fe-company-name',
  Contact_Person = 'fe-contact-person',
  CORS = 'fe-cors',
  Device_Location = 'fe-device-location',
  Device_Name = 'fe-device-name',
  Duplex_Binding = 'fe-duplex-binding',
  Outgoing_Servers = 'fe-outgoing-servers',
  Date_Time_Format = 'fe-date-time-format',
  Time_Zone = 'fe-time-zone',
  Ews_Language = 'fe-ews-language',
  Time_Service = 'fe-time-service',
  Ews_Admin_Password = 'fe-ews-password',
  LDAP_SignIn_Setup = 'fe-ldap-signin-setup',
  PJL_Password = 'fe-pjl-password',
  Remote_Configuration_Password = 'fe-remote-configuration-password',
  SNMP_V1_V2 = 'fe-snmp-v1-v2',
  SNMP_V3 = 'fe-snmp-v3',
  Control_Panel_Language = 'fe-ctrl-panel-language',
  Date_And_Time = 'fe-date-and-time',
  Sleep_Delay = 'fe-sleep-delay',
  Sleep_Schedule = 'fe-sleep-schedule',
  Sleep_Settings = 'fe-sleep-settings',
  Service_Access_Code = 'fe-service-access-code',
  Cartridge_Policy = 'fe-cartridge-policy',
  Cartridge_VeryLowAction_Black = 'fe-cartridge-verylowaction-black',
  Cartridge_VeryLowAction_Color = 'fe-cartridge-verylowaction-color',
  Delay_VeryLow_Message = 'fe-delayverylow_messsage',
  Airpint_fax = 'fe-airprint-fax',
  Airprint_Scan = 'fe-airprint-scan',
  Airprint_SecureScan = 'fe-airprint-secure-scan',
  DHCPv4_FQDN_Compliance_With_RFC_4702 = 'fe-dhcpv4-fqdn-compliance-with-rfc-4702',
  Link_Settings = 'fe-link-Settings',
  Bt_Low_Energy = 'fe-bluetooth-low-energy',
  Ftp_print = 'fe-ftp-printing',
  EWS_Config = 'fe-ews-config',
  Dns_Server = 'fe-dns-server',
  IP_Config_Precedence = 'fe-ip-config-precedence',
  IPv4_information = 'fe-ipv4-information',
  IPv6_Information = 'fe-ipv6-information',
  Default_Media_Size = 'fe-default-media-size',
  Default_Media_Type = 'fe-default-media-type',
  Work_Path_Enablement = 'fe-work-path-enablement',
  Web_App_Deployment = 'fe-web-app-deployment',
  CA_Certificate = 'fe-ca-certificate',
  ID_Certificate = 'fe-id-certificate',
  EmailAddress_MessageSetting = 'fe-emailAddress-messageSetting',
  SaveTo_SharePoint = 'fe-saveto-sharePoint',
  SendTo_Email = 'fe-sent-email',
  SaveTo_NetworkFolder = 'fe-saveto-networkFolder',
  Smart_CloudPrint = 'fe-smart-cloud-print',
  HPJetAdvantage_MoreApps = 'fe-hpjetadvantage-moreApps',
  Dot1x_Authentication = 'fe-dot1x-authentication',
  Wifi_Direct = 'fe-wifi-direct',
  Restrict_Color = 'fe-restrict-color',
  TrayAdministration = 'fe-tray-administration',
  StoredDataPin_Protection = 'fe-stored-data-pin-protection',
  ColorCopyMode = 'fe-color-copy-mode',
  Copy_JobBuild = 'fe-copy-job-build',
  HP_WebServices = 'fe-hp-webservices',
  Toner_Collection_Unit = 'fe-toner-collection-unit',
  Cartridge_Protection = 'fe-cartridge-protection',
  Home_Screen_customization_Futuresmart = 'fe-home-screen-customization-futuresmart',
}
export enum FeatureEntitlementEnum {
  basic = 'basicsecurity',
  advance = 'advancesecurity',
}
export enum FeatureEffectivePolicyEnum {
  controlledPolicy = 'controlledPolicy',
  PartialControlledPolicy = 'partialControlledPolicy',
  relatedPolicy = 'relatedPolicy',
}
