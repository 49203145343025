import { FeatureIdEnum } from '../config/FeatureEnums';

const FilePickerHelper = () => {
  const getAllowedFileTypes = (key) => {
    let acceptFileExts;
    switch (key) {
      case `${FeatureIdEnum.CA_Certificate}`:
        acceptFileExts = '.cer, .pem, .der, .p7b';
        break;
      case `${FeatureIdEnum.ID_Certificate}`:
        acceptFileExts = '.pfx';
        break;
    }

    return { acceptFileExts };
  };

  return {
    getAllowedFileTypes,
  };
};
export default FilePickerHelper;
