import { useFlags } from 'launchdarkly-react-client-sdk';
import { MockLaunchDarklyFeatures } from '../../tests/mocks/launchDarkly';

const LaunchDarklyVariables = (props) => {
  const LaunchDarklyFeatures = props.isWex ? MockLaunchDarklyFeatures : useFlags()?.dcFeatureControl;

  const getLaunchDarklyFeatures = () => {
    return LaunchDarklyFeatures;
  };

  return {
    getLaunchDarklyFeatures,
  };
};

export default LaunchDarklyVariables;
