import React from 'react';
import CategoryContent from '../Category/Content/CategoryContent';
import CategoryTree from '../CategoryTree/CategoryTree';
import * as Style from './Styles';
import LocalizeHelper from '../../../helper/LocalizeHelper';

const Body = (props) => {
  const { getDeviceModalLocString } = LocalizeHelper();
  return (
    <Style.ModalBody>
      <Style.ModalBodyRow>
        <Style.CategoryTreeWrap>
          <CategoryTree featuresData={[props.featuresData]} isWex = {props.isWex} />
        </Style.CategoryTreeWrap>
        {!props.featuresData.editMode ? (
          <Style.EditContentWrap>
            <CategoryContent
              featuresData={props.featuresData}
              entitlements={props.entitlements}
              isWex={props.isWex}
            />
          </Style.EditContentWrap>
        ) : (
          <Style.EditContentWrap>
            {getDeviceModalLocString('work_inprogress')}
          </Style.EditContentWrap>
        )}
      </Style.ModalBodyRow>
    </Style.ModalBody>
  );
};

export default Body;
