import React from 'react';
import * as Style from './Styles';

const InfoModal = ({ title, content, show, handleClose }) => (
  <Style.InfoModalWrap
    maxWidth="calc(100% + 40px + 40px)"
    expanded
    closeButton
    show={show}
    onClose={handleClose}
    closeOnBlur={true}
    children={
      <Style.InfoModalContainer>
        <Style.InfoModalHeader>{title}</Style.InfoModalHeader>
        <Style.InfoModalContent>{content}</Style.InfoModalContent>
      </Style.InfoModalContainer>
    }
  />
);

export default InfoModal;
