import React, { Fragment } from 'react';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';
import { SubFeatureValue } from '../../../ControlTemplates/SectionField/Styles';
import * as Style from '../../Styles';

export const CAIDCertifcate = (props) => {
  const { getDeviceFeatureModalString } = LocalizeHelper();
  const content = getDeviceFeatureModalString(props.featureId, 'readviewContent');

  return (
    <Fragment>
      <Style.SubFeature>
        <SubFeatureValue id={`${props.featureId}-value`}>{content}</SubFeatureValue>
      </Style.SubFeature>
    </Fragment>
  );
};
