import { store } from '../../../src/utils/constants';

const initialState = {
  startRootComponent: false,
  deviceId: '',
  deviceModelNumber: '',
  showMultipleConfigUi: false,
  selectedDeviceIdList: [],
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.config.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.config.DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case store.config.DEVICE_MODEL_NUMBER:
      return {
        ...state,
        deviceModelNumber: action.payload,
      };
    case store.config.SHOW_MULTIPLE_CONFIG_UI:
      return {
        ...state,
        showMultipleConfigUi: action.payload,
      };
    case store.config.SELECTED_DEVICE_ID_LIST:
      return {
        ...state,
        selectedDeviceIdList: action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
