
import logger from '../../utils/logger';

const useConfigureDevice = async (client, devices, settings) => {
  let response, error;

  await client
    .configureDevice(devices, settings)
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      logger.error("API error for configureDevice :",err.message);
      error = err.message;
    });
  return { response, error };
};

const getPolicyDetails = async (client, devices) => {
  let response, error;

  await client
    .searchEffectivePolicies(devices)
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      logger.error("API error for getPolicyDetails :",err.message);
      error = err;
    });
  return { response, error };
};

const getCertificateDetails = async (client, filename, filetype, contents) => {
  let response, error;

  await client
    .uploadFile(filename, filetype, contents)
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      logger.error("API error for getCertificateDetails:",err.message);
      error = err;
    });
  return { response, error };
};

export const fleetMgtSvc = {
  useConfigureDevice,
  getPolicyDetails,
  getCertificateDetails,
};
