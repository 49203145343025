import { IconInfo } from '@veneer/core';
import styled from 'styled-components';
import { Button } from '@veneer/core';

export const FlexRow = styled.div`
  display: flex;
  width: 400px;
  padding-bottom: 8px;
`;

export const PasswordField = styled.div`
  display: flex;
  width: 400px;
  border-radius: 8px;
`;

export const ConfirmPasswordField = styled(PasswordField)``;

export const ButtonWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;
export const StyledButton = styled(Button)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)!important')}
};
  ${(props) =>
    props.isWex &&
  `min-width: 65% !important;
   width: 92px;
   height: 36px ;
   padding: 8px 20px 8px 20px;
   gap: 8px;
   border-radius: 360px !important;
   border: 1px;
   opacity: 0px;`
  }
`;

export const InputWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const InlineInfo = styled.div`
  flex-direction: row;
  display: flex;
  text-align: justify;
  display-flex: center;
`;

export const InfoIcon = styled(IconInfo)`
  padding-right: 6px;
`;
