import { Accordion, IconWarningAlt } from '@veneer/core';
import styled from 'styled-components';
import { FlexRowAlignCenterBetween } from '../../../GlobalStyles/GlobalStyles';
import primitives from '@veneer/primitives';

 export const IconWarningAltStyle = styled(IconWarningAlt)`
 color : ${primitives.color.darkOrange6}!important;
 `;

export const EditFeatureListWrap = styled(FlexRowAlignCenterBetween)`
  .edit-feature-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const EditFeatureList = styled.ul`
  width: 100%;
  word-break: break-word;
  list-style: none;
  // white-space: nowrap;
`;

export const EditFeatureListItem = styled.li`
  align-items: center;
  background-color: ${primitives.color.white};
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  box-sizing: border-box;
  color: ${primitives.color.gray10};
  justify-content: space-between;
  position: relative;
  width: 100%;
  word-break: break-word;
`;

export const EditFeatureAccordion = styled(Accordion)`
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 8px 21px 8px 32px;
  }
  div[id='${(props) => props.id}-body'] {
    margin: 0px 24px 0px 32px;
    padding: 24px 0px;
  }
`;
