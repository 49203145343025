import {Coachmark} from '@veneer/core';
import { IconInfo } from '@veneer/core';
import styled from 'styled-components';
import {
  fontSize12LineHeight16,
  fontSize16LineHeight20,
  styleAttributes3,
  styleAttributes8,
} from '../../GlobalStyles/GlobalStyles';
import { IconWarningAlt } from '@veneer/core';

export const IconWarningAltNotifyStyle = styled(IconWarningAlt)`
color: #FF8900 !important;`;

export const Notify = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  padding: 10px 8px;
  background: rgba(208, 103, 2, 0.1);
  border-radius: 8px;
  margin-bottom: 24px;
`;
export const NotifyContent = styled.div``;
export const NotifyHeader = styled.label`
  ${styleAttributes3}
`;
export const NotifyDesc = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  ${styleAttributes8};
`;

export const NotifyDescContent = styled.p``;

export const NotifyDescIcon = styled(IconInfo)`
  &:hover {
    cursor: pointer;
  }
`;
export const CustomCoachMark = styled(Coachmark)`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;
export const CustomCoachMarkTitle = styled.div`
  font-family: 'Forma DJR Micro';
  color: #ffffff;
  ${fontSize16LineHeight20}
`;
export const CustomCoachMarkDesc = styled.div`
  font-family: 'Forma DJR Micro';
  color: #ebebeb;
  ${fontSize12LineHeight16}
`;
