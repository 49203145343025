import { IconInfo } from '@veneer/core';
import { Button } from '@veneer/core';
import styled from 'styled-components';
import { Modal } from '@veneer/core';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens'

export const FlexRow = styled.div`
  display: flex;
  width: 400px;
  padding-bottom: 8px;
`;

export const PasswordField = styled.div`
  display: flex;
  width: 400px;
  border-radius: 8px;
`;

export const ConfirmPasswordField = styled(PasswordField)``;

export const ButtonWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const InputWrap = styled.div<{ marginBottom?: string }>`
  width: 400px;
  margin-bottom: ${(props) => props.marginBottom || '16px'};
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TextBoxWrap = styled.div`
  width: 450px;
`;

export const InlineInfo = styled.div`
  flex-direction: row;
  display: flex;
  text-align: justify;
  display-flex: center;
`;

export const InfoIcon = styled(IconInfo)`
  padding-right: 6px;
`;

export const CustomCancelButton = styled(Button)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)!important')}
};
color: ${(props) => props.disabled && '#ADADAD !important'};
span {
  font-family: 'Forma DJR Micro' !important;
}
  letter-spacing: 0.02em;
  min-width: 92px !important;
  ${(props) =>
    props.isWex &&
    `width: 92px;
     height: 36px ;
     padding: 8px 20px 8px 20px;
     gap: 8px;
     border-radius: 360px !important;
     border: 1px;
     opacity: 0px;`
    };
`;

export const ButtonGroupWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
`;

export const ButtonSelectedLabel = styled.div`
  color: #404040;
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//Custom Add Root Modal
export const CustomAddRootModal = styled(Modal)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')}!important;
}
  .vn-modal--dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .vn-modal-title {
    padding-bottom: 20px;
  }
  .vn-modal--content {
    min-width: 612px !important;
    max-width: 612px !important;
  }
  .vn-modal--footer > div > button {
    margin: 0;
    margin-right: 12px;
    width: 131px;
    height: 36px;
    border-radius: 360px !important;
}
  .vn-modal--footer {
    ${(props) =>
      props.isWex &&
      `width: 131px ;
       height: 36px ;
       padding: 8px 20px 8px 0px;
       border-radius: 360px !important;`
      };
      padding-top: 8px;
      justify-content: left;
      display: flex;
      gap: 12px;
      white-space: nowrap;
      text-align: center;
      letter-spacing: 0.32px;   }    
`
export const TableWrapper = styled.div`
  tbody > tr:nth-of-type(2n + 1) {
    background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : `${Veneer.colorGray1}` )};
  }
  max-width: 916px;
`;
