import React from 'react';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import * as Style from './Styles';

const CategoryHeader = (props) => {
  const { categorykey } = props;
  const { getDeviceCategoryLocString } = LocalizeHelper();

  return (
    <Style.EditCategoryTitle data-testid={'id-edit-modal-category-title'}>
      {' '}
      {getDeviceCategoryLocString(categorykey)}
    </Style.EditCategoryTitle>
  );
};

export default CategoryHeader;
