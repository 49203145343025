import {Accordion} from '@veneer/core';
import styled from 'styled-components';

// Starts

export const EditCategoryCard = styled.div`
  background: #ffffff;
  box-shadow: rgb(33 33 33 / 10%) 0px 4px 16px;
  border-radius: 12px;
  .edit-category-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const EditCategoryAccordion = styled(Accordion)`
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 14.2px 21px 14.2px 20px;
  }

  div[id='${(props) => props.id}-body'] {
    margin: 0px;
    padding: 0px;
  }
`;
