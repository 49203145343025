import { useDispatch } from 'react-redux';
import { ConstraintsScopeKeyEnum } from '../config/ConstraintsEnums';
import { SubfeatureIdMap } from '../dataModel/SubfeatureIdMap';
import { setRulesUpdate } from '../store/deviceConfig/action';

const ConstraintsRuleParser = () => {
  const dispatch = useDispatch();

  const mapFilter = (resource, setting, schemaVal) => {
    const SubfeatureIds = SubfeatureIdMap.filter(
      (item) => item.settingsName === setting && item.resource === resource,
    )?.map((obj) => ({ ...obj, schemaVal, valueCheck: true }));

    return SubfeatureIds;
  };

  let ruleMap = [];

  const validateIfRuleExists = (constraints, currentSettingName) => {
    constraints?.rules
      ? constraints?.rules?.forEach((rule) => {
          const effect = rule.effect;

          Object.keys(rule.condition)?.forEach(function eachKey(key) {
            const conditionScopes = rule.condition[key];

            const filteredScopeVal = conditionScopes?.map((conditionScopeItem) => {
              const scope = conditionScopeItem.scope;
              const schema = Object.keys(conditionScopeItem.schema)[0];
              const schemaVal = conditionScopeItem.schema[schema];

              const scopeSplitVal = scope.split('/');
              const scopeResource = scopeSplitVal[2];
              const scopeSetting = scopeSplitVal[4];

              let schemaValue;
              if (!Array.isArray(schemaVal)) {
                schemaValue = schemaVal;
              } else {
                schemaValue = schemaVal[0];
              }

              let mapFilterVal = mapFilter(scopeResource, scopeSetting, schemaValue);

              return mapFilterVal;
            });
            const dependsOn = [].concat.apply([], filteredScopeVal);

            const ruleObj = {
              ruleType: key,
              effect: effect,
              currentSettingName,
            };

            ruleMap = [Object.assign(ruleObj, { dependsOn })];
          });
        })
      : (ruleMap = []);

    return ruleMap;
  };

  const tempConditionCheck = (rules, settings) => {
    let rulesPayload;

    const getBooleanString = (value) => {
      if (value === true) return 'true';
      if (value === false) return 'false';
      return value;
    };

    const updateRulesPayload = (settingsData, valueCheck) => {
      if (settingsData.tempCheck) {
        rulesPayload = { settingsName: settingsData.settingsName, valueCheck };
      } else {
        dispatch(setRulesUpdate({ settingsName: settingsData.settingsName, valueCheck }));
      }
    };

    const condionCheck = (scope, settingsData) => {
      scope?.map((scopeItem) => {
        const isSameSettingsName = scopeItem.settingsName === settingsData?.settingsName;
        const isSameResource = scopeItem.resource === settingsData?.resource;

        if (isSameSettingsName && isSameResource) {
          const schemaValue = getBooleanString(scopeItem.schemaVal);
          const fleetValue = getBooleanString(settingsData?.fleetValue);

          const valueCheck = schemaValue === fleetValue;
          updateRulesPayload(settingsData, valueCheck);
        }
      });

      return scope;
    };

    rules?.map((rulesDataMap) => {
      condionCheck(rulesDataMap.dependsOn, settings);
    });

    return rulesPayload;
  };

  const setRuleEffect = (rules) => {
    let currentSettingName = '';
    let effect = '';
    const ruleEffects = rules
      ?.map((rulesDataMap) => {
        const scopes = rulesDataMap.dependsOn;
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ALLOF) {
          const allOfTrue =
            scopes?.filter((scopesItem) => scopesItem.valueCheck === true).length === scopes.length;
          if (!allOfTrue) {
            currentSettingName = rulesDataMap.currentSettingName;
            effect = rulesDataMap.effect;
            return { currentSettingName, effect };
          }
        }
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ANYOF) {
          const anyOfTrue =
            scopes?.filter((scopesItem) => scopesItem.valueCheck === true).length >= 1;
          if (!anyOfTrue) {
            currentSettingName = rulesDataMap.currentSettingName;
            effect = rulesDataMap.effect;
            return { currentSettingName, effect };
          }
        }
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ONEOF) {
          const oneOfTrue =
            scopes?.filter((scopesItem) => scopesItem.valueCheck === true).length === 1;
          if (!oneOfTrue) {
            currentSettingName = rulesDataMap.currentSettingName;
            effect = rulesDataMap.effect;
            return { currentSettingName, effect };
          }
        }
      })
      ?.filter(function (element) {
        return element !== undefined;
      });

    return ruleEffects ? ruleEffects : [];
  };

  return {
    validateIfRuleExists,
    tempConditionCheck,
    setRuleEffect,
  };
};

export default ConstraintsRuleParser;
