import GetCustomDeviceCacheValueHelper from './GetCustomDeviceCacheValueHelper';

const GetDeviceCacheValueHelper = () => {
  const getDeviceCacheValue = (
    serviceGunData,
    serviceSettingsData,
    deviceCacheData,
    isUnSupportedPlugin,
    isCustomMethod,
    defaultValue,
  ) => {
    let deviceCacheResult = {};

    const filteredfleetData = Object.fromEntries(
      (Object.entries(deviceCacheData) as [any, { cdmMetaData: { serviceGun: string } }][]).filter(
        ([key, value]) => value?.cdmMetaData?.serviceGun === serviceGunData,
      ),
    );

    const originalKey = Object.keys(filteredfleetData)[0]; // Get the original key
    const newKey = filteredfleetData[originalKey]?.cdmMetaData?.serviceGun; // Get the new key from the serviceGun property

    const modifiedObject = {
      [newKey]: filteredfleetData[originalKey],
    };

    const finalFleetData = modifiedObject[serviceGunData];
    const splitCdmData = serviceSettingsData.split('.');

    let result = finalFleetData;

    if (finalFleetData !== undefined) {
      if (isCustomMethod) {
        result = GetCustomDeviceCacheValueHelper(serviceGunData, finalFleetData);
      } else {
        splitCdmData.forEach((index) => {
          if (result[index] !== undefined) {
            result = result[index];
          } else {
            result = 'undefined';
          }
        });
      }

      if (result !== 'undefined') {
        deviceCacheResult['fleetResult'] = 'success';
        if (serviceSettingsData === 'cdmData.snmpV1V2Config.enabled') {
          deviceCacheResult['fleetValue'] = 'true';
        } else {
          deviceCacheResult['fleetValue'] =
            result !== '' ? result : defaultValue ? defaultValue : '';
        }
      } else {
        deviceCacheResult['fleetResult'] = 'failed';
        deviceCacheResult['fleetValue'] = defaultValue ? defaultValue : null;
        if (isUnSupportedPlugin) {
          deviceCacheResult['visible'] = false;
        }
      }
    } else {
      deviceCacheResult['fleetResult'] = 'failed';
      deviceCacheResult['fleetValue'] = defaultValue ? defaultValue : null;
      if (isUnSupportedPlugin) {
        deviceCacheResult['visible'] = false;
      }
    }
    return deviceCacheResult;
  };

  return {
    getDeviceCacheValue,
  };
};

export default GetDeviceCacheValueHelper;
