import { colorGray4, colorGray7 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenter, fontSize16LineHeight20 } from '../../GlobalStyles/GlobalStyles';

export const TitleContainer = styled(FlexRowAlignCenter)`
  margin-bottom: 8px;
`;

export const Title = styled.div`
  ${fontSize16LineHeight20}
  font-family: 'Forma DJR UI' !important;
  color: ${(props) => (props.disable ? colorGray4 : colorGray7)};
`;

export const TitleBold = styled.div`
  :after {
    content: ${(props) => (props.isContentAfter ? "'*'" : null)};
    margin-left: $layout-size1;
  }
  color: ${colorGray7} !important;
  font-family: 'Forma DJR UI';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;
