import { colorGray10 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { FlexRowCenter, FlexRowDirection } from '../GlobalStyles/GlobalStyles';

// * colorGray10 ~ 212121

export const Wrapper = styled.div`
  min-height: 300px;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  color: ${colorGray10};
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  height: 100px;
`;

export const ErrorMessage = styled(FlexRowCenter)`
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const ContentWrapper = styled(FlexRowDirection)``;

export const SectionsWrapper = styled.div`
  flex: 1 1 0;
  transition: all 1s ease;

  @media (max-width: 768px) {
    width: 100%;
    transition: all 1s ease;
  }
`;
