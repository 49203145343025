import { Select } from '@veneer/core';
import React, { Fragment, useEffect } from 'react';
import { InputTitle } from '../../CommonExtended/InputTitle/InputTitle';
import Ipv4TextBoxControl from './Ipv4TextBoxControl';
import { InputWrap } from './Styles';

const Ipv4 = (props) => {
  const groupedData = () => {
    const flatArray = props.subfeaturesdata
      ?.map((data) => {
        return Array.isArray(data.fleetValue) ? data.fleetValue : [];
      })
      .flat(1);

    const groupBy = flatArray?.reduce(
      (map, e) => ({
        ...map,
        [e.group]: [...(map[e.group] ?? []), e],
      }),
      [],
    );

    return groupBy;
  };

  const optionItems = Object.keys(groupedData() || {}).map(function (keyName, keyIndex) {
    return { value: keyName, label: `Wired ${keyIndex}` };
  });

  const [value, setValue] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const onChange = (selectedOption) => setValue([selectedOption.value]);

  useEffect(() => {
    setSelectedValues(groupedData()[value[0]]);
  }, [value]);
  return (
    <Fragment>
      <InputWrap>
        <InputTitle title={'Interface'} info={'device_settings.info'} />
        <Select
          id={'device_settings.attribute'}
          clearIcon={false}
          helperTextVisibility="auto"
          options={optionItems}
          value={value}
          onChange={onChange}
        />
      </InputWrap>
      {props.subfeaturesdata?.map((subfeature) => {
        const settingsValue =
          selectedValues && selectedValues.length
            ? selectedValues.find((data) => data.id === subfeature.id).value
            : '';
        return (
          <Ipv4TextBoxControl
            device_settings={subfeature}
            featuresId={props.id}
            isControlledPolicy={props.isControlledPolicy}
            settingsValue={settingsValue}
          />
        );
      })}
    </Fragment>
  );
};

export default Ipv4;
