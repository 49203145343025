import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { fetchLDClientSideID } from '../src/utils/constants';
import EcpComponent from './ecpComponent';
import { ShellProps } from './types/shell';
import WexComponent from './wexComponent';

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

function Root(props) {
  return <>{props.isWex ? <WexComponent {...props} /> : <EcpComponent {...props} />}</>;
}

export default withLDProvider({
  clientSideID: fetchLDClientSideID() as string,
})(Root);
