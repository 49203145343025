import { FeatureIdEnum } from '../../config/FeatureEnums';
import {
  editableFieldsMapping,
  localizeValueMapping,
  tableValueIds,
} from '../../config/tableIdMap';
import LocalizeHelper from '../LocalizeHelper';

export const EmailAddressMessageHelper = (subFeatureId, values) => {
  const { getDeviceConfigurationsLocString, getCommonStrings, getDeviceValueLocString } =
    LocalizeHelper();

  const splitKeys = Object.entries(editableFieldsMapping).reduce((acc, [key, value]) => {
    acc[key] = value.split('.');
    return acc;
  }, {});

  const getValueAndEditableFromPropsData = (id, values) => {
    const value = id
      .split('.')
      .reduce((acc, key) => (acc && acc[key] ? acc[key] : undefined), values);
    const editable = splitKeys[id]
      ? splitKeys[id].reduce((acc, key) => (acc && acc[key] ? acc[key] : undefined), values)
      : undefined; // or any other default value
    const localizable = localizeValueMapping.includes(id);
    return { value, editable, localizable };
  };

  const tableData = tableValueIds.map((id) => {
    const { value, editable, localizable } = getValueAndEditableFromPropsData(id, values);
    // const option_name = getDeviceConfigurationsLocString(
    //   `${FeatureIdEnum.EmailAddress_MessageSetting}`,
    //   id,
    //   EmailAddressMessageHelper,
    // );
    const option_name = getDeviceConfigurationsLocString(
      `${FeatureIdEnum.EmailAddress_MessageSetting}`,
      id,
      subFeatureId,
    );

    const valueLocString = localizable
      ? value
        ? getDeviceValueLocString(`${FeatureIdEnum.EmailAddress_MessageSetting}`, `${id}.${value}`)
        : ''
      : value;

    return {
      id,
      option_name,
      value: valueLocString,
      userEditable: editable ? getCommonStrings(editable) : '',
    };
  });

  return { emailAddressMessageData: tableData };
};
