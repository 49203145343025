import { categoryStore } from '../../../src/utils/constants';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';

const initialState = {
  pluginName: '',
  rulesData: [],
  rulesEffect: [],
  policyData: [],
  featuresData: [],
  settingsData: [],
  editViewData: [],
  tempFeature: [],
  tempRules: [],
  tempRulesEffect: [],
  loading: false,
  controlledPolicyError: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryStore.config.SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case categoryStore.config.SAVE_PLUGIN_NAME:
      return {
        ...state,
        pluginName: action.payload,
      };
    case categoryStore.config.SET_RULES_DATA:
      return {
        ...state,
        rulesData: [...state.rulesData, ...action.payload],
      };
    case categoryStore.config.SET_RULES_DATA_UPDATE:
      return {
        ...state,
        rulesData: state.rulesData.map((element) => {
          return {
            ...element,
            dependsOn: element.dependsOn.map((data) =>
              data.settingsName === action.payload.settingsName
                ? { ...data, valueCheck: action.payload.valueCheck }
                : data,
            ),
          };
        }),
      };
    case categoryStore.config.GET_RULES_EFFECT:
      return {
        ...state,
        rulesEffect: action.payload,
      };
    case categoryStore.config.SET_POLICY_DATA:
      return {
        ...state,
        policyData: [...state.policyData, ...action.payload],
      };
    case categoryStore.config.SET_CONTROLLED_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        controlledPolicyError: null,
      };
    case categoryStore.config.SET_CONTROLLED_POLICY:
      return {
        ...state,
        policyData: state.policyData.map((element) => {
          for (const i in element) {
            return {
              [i]: element[i].map((data) => {
                const id = data.id.substring(3);
                const filteredPolicy = action.payload.length
                  ? action.payload.filter((policy) => policy.attributeName == id)
                  : [];
                return {
                  ...data,
                  controlledPolicy: filteredPolicy.length ? filteredPolicy[0].controlledPolicy : [],
                };
              }),
            };
          }
        }),
        featuresData: state.featuresData.map((element) => {
          const id = element.id.substring(3);
          const filteredPolicy = action.payload.filter((policy) => policy.attributeName == id);
          return {
            ...element,
            controlledPolicy: filteredPolicy.length ? filteredPolicy[0].controlledPolicy : [],
          };
        }),
        loading: false,
      };
    case categoryStore.config.SET_CONTROLLED_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        controlledPolicyError: action.payload,
      };
    case categoryStore.config.SET_FEATURES_DATA:
      try {
        return {
          ...state,
          featuresData: [...state.featuresData, ...action.payload],
        };
      } catch {
        console.log('Warning Occurred in SET_FEATURES_DATA');
      }
    case categoryStore.config.SET_SETTINGS_DATA:
      try {
        return {
          ...state,
          settingsData: [...state.settingsData, ...action.payload],
        };
      } catch {
        console.log("Warning Occurred in SET_SETTINGS_DATA'");
      }
    case categoryStore.config.SET_TEMP_FEATURE:
      try {
        return {
          ...state,
          tempFeature: action.payload,
          editViewData: action.payload.length
            ? action.payload[0].subfeatures.reduce(function (filtered, element) {
                let newFleetData;
                if (element.accessMode !== SubFeatureAccessModeEnum.READONLY) {
                  const rulesEffect = state.tempRulesEffect.filter(
                    (data) => data.currentSettingName === element.settingsName,
                  );
                  newFleetData = {
                    name: element.attribute,
                    value: element.fleetValue,
                    error: false,
                    required: element.required,
                    settingsName: element.settingsName,
                    rulesEffect: rulesEffect.length ? true : false,
                  };
                }

                if (newFleetData) {
                  filtered.push(newFleetData);
                }

                return filtered;
              }, [])
            : action.payload,
        };
      } catch {
        console.log("Warning Occurred in SET_TEMP_FEATURE'");
      }
    case categoryStore.config.SET_TEMP_FEATURE_UPDATE:
      return {
        ...state,
        tempFeature: state.tempFeature.map((element) => {
          return {
            ...element,
            subfeatures: element.subfeatures.map((data) =>
              data.settingsName === action.payload.settingsName
                ? { ...data, fleetValue: action.payload.fleetValue, tempCheck: true }
                : data,
            ),
          };
        }),
        editViewData: state.editViewData.reduce(function (filtered, element) {
          if (element.name === action.payload.attribute) {
            const newFleetData = {
              name: element.name,
              value: action.payload.fleetValue,
              error: action.payload.error,
              required: element.required,
              settingsName: element.settingsName,
              rulesEffect: element.rulesEffect,
            };
            filtered.push(newFleetData);
          } else {
            filtered.push(element);
          }
          return filtered;
        }, []),
      };
    case categoryStore.config.SET_TEMP_RULES:
      // Todo: Save only related Feature Rules not all rules
      return {
        ...state,
        tempRules: action.payload,
      };
    case categoryStore.config.SET_TEMP_RULES_UPDATE:
      return {
        ...state,
        tempRules: state.tempRules.map((element) => {
          return {
            ...element,
            dependsOn: element.dependsOn.map((data) =>
              data.settingsName === action.payload.settingsName
                ? { ...data, valueCheck: action.payload.valueCheck }
                : data,
            ),
          };
        }),
      };
    case categoryStore.config.SET_TEMP_RULES_EFFECT:
      return {
        ...state,
        tempRulesEffect: action.payload,
        editViewData: state.editViewData.reduce(function (filtered, element) {
          const rulesEffect = action.payload.filter(
            (data) => data.currentSettingName === element.settingsName,
          );
          const newFleetData = {
            name: element.name,
            value: element.value,
            error: element.error,
            required: element.required,
            settingsName: element.settingsName,
            rulesEffect: rulesEffect.length ? true : false,
          };
          filtered.push(newFleetData);
          return filtered;
        }, []),
      };
    case categoryStore.config.RESET_INITIAL_DATA:
      return {
        ...state,
        rulesData: [],
        rulesEffect: [],
        policyData: [],
        featuresData: [],
        settingsData: [],
      };
    default:
      return state;
  }
};

export default categoryReducer;
