import { FeatureEffectivePolicyEnum } from '../config/FeatureEnums';
const GetControlledPolicyHelper = () => {
  const getControlledPolicyData = (response) => {
    const controlledPolicySettings = response.items.map((data) => {
      return data.settings.map((element) => {
        return { ...element, deviceId: data.id };
      });
    });

    const controlledPolicy = response.items.map((data) => {
      return data.policies;
    });

    const controlledPolicyResult = controlledPolicySettings.reduce((res, arr, index) => {
      arr.forEach(({ attributeName, policyId, deviceId }) => {
        res[attributeName] = res[attributeName] || { occurrences: 0 };
        res[attributeName]['controlledPolicy'] = res[attributeName]['controlledPolicy'] || [];
        res[attributeName]['attributeName'] = res[attributeName]['attributeName'] || '';
        res[attributeName]['deviceID'] = res[attributeName]['deviceID'] || [];
        const matchedPolicy = controlledPolicy
          .map((a) => a.filter(({ id }) => id === policyId))
          .filter(({ length }) => length)
          .flat(1);
        const policyName = matchedPolicy[0]?.name;

        if (!res[attributeName]['controlledPolicy'].includes(policyName)) {
          res[attributeName]['attributeName'] = attributeName;
          if (policyName !== undefined) {
            res[attributeName]['controlledPolicy'].push(policyName);
            res[attributeName].occurrences += 1;
          } else {
            if (!res[attributeName]['controlledPolicy'].includes('deviceSpecificPolicy')) {
              res[attributeName]['controlledPolicy'].push('deviceSpecificPolicy');
              res[attributeName].occurrences += 1;
            }
          }
        }

        if (!res[attributeName]['deviceID'].includes(deviceId)) {
          res[attributeName]['deviceID'].push(deviceId);
        }
      });

      return res;
    }, []);

    const controlledPolicyArr = Object.keys(controlledPolicyResult).map(
      (key) => controlledPolicyResult[key],
    );

    const effectivePolicyArr = controlledPolicyArr.map((data) => {
      return {
        ...data,
        policyType: !data.deviceID.length
          ? FeatureEffectivePolicyEnum.relatedPolicy
          : data.deviceID.length !== 0 && data.deviceID.length == response.items.length
          ? FeatureEffectivePolicyEnum.controlledPolicy
          : FeatureEffectivePolicyEnum.PartialControlledPolicy,
      };
    });

    return effectivePolicyArr;
  };

  return {
    getControlledPolicyData,
  };
};

export default GetControlledPolicyHelper;
