import LocalizeHelper from '../helper/LocalizeHelper';

const TimeConverter = ({ time24 }) => {
  const { getCommonStrings } = LocalizeHelper();
  const time = time24.toString().match(/^([0-9]|1\d|2[0-3])(:)([0-5]?\d)$/);

  if (time === null) {
    return '--';
  }
  // Convert hours component to 12-hour format
  let hours = parseInt(time[1], 10);
  let minutes = parseInt(time[3], 10);
  const suffix = hours >= 12 ? getCommonStrings("time-format-suffix.PM") : getCommonStrings("time-format-suffix.AM");
  hours = ((hours + 11) % 12 + 1);

  // Pad hours and minutes with zeros if they are less than 10
  // const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Return the formatted time
  return `${hours}:${formattedMinutes} ${suffix}`;
}
export default TimeConverter;
