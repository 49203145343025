import styled from 'styled-components';
import { IconWarningAlt } from '@veneer/core';

export const IconWarningAltNotifyStyle = styled(IconWarningAlt)`
color: #FF8900 !important;`;


export const AdvanceFeatureCard = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 10px;
  background: rgba(208, 103, 2, 0.1);
  border-radius: 8px;
`;

export const AdvanceFeatureCardContent = styled.div`
  padding-left: 8px;
`;

export const AdvanceFeatureCardTitle = styled.label`
  font-family: 'Forma DJR Micro';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #212121;
`;

export const AdvanceFeatureCardDesc = styled.p`
  font-family: 'Forma DJR Micro';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #404040;
`;
