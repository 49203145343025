import tokens from '@veneer/tokens';
import { colorGray4, colorGray7 } from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';

export const InputWrap = styled.div`
  > div .vn-radio-button__icon::before {
    width: ${(props) => props.isWex && '10px'};
    height: ${(props) => props.isWex && '10px'};
  }
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
