import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { storeAllowSetting, storeSettingsList } from '../../src/store/configureDevices/action';
import { useStoreState } from '../../src/store/useStoreState';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) {
    return;
  }

  callBack();
  setHasBeenCalled(true);
};

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return { didMount };
};

const _useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(window.matchMedia(query).matches);
  }, []);

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    const handleChange = (e) => setMatches(e.matches);
    matchQueryList.addEventListener('change', handleChange);

    return () => {
      matchQueryList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};

const useResponsive = () => {
  const lg = 1280;
  const md = 1020;
  const sm = 768;
  const isPC = _useMediaQuery(`(min-width: ${md}px)`);
  const isTablet = _useMediaQuery(`(min-width: ${sm}px) and (max-width: ${md - 1}px)`);
  const isMobile = _useMediaQuery(`(max-width: ${sm - 1}px)`);

  return { isPC, isTablet, isMobile };
};

type ReturnTypes = [(string) => void, (e) => void];

const useNodeSearch = (nodes, setGroupNodes, defaultNodes): ReturnTypes => {
  const [searchItem, setSearchItem] = useState('');

  const getAllGroupLabels = (obj, res) => {
    for (let item of obj) {
      res.push({ id: item.id, label: item.label });
      if (item.hasOwnProperty('nodes')) {
        getAllGroupLabels(item.nodes, res);
      }
    }
    return res;
  };

  const labels = useMemo(() => getAllGroupLabels(defaultNodes, []), [defaultNodes]);

  useEffect(() => {
    if (searchItem === '') return;

    document
      .querySelectorAll('[data-testid="setting-tree-wrapper"] div:nth-child(2) ul li')
      .forEach((el) => el.classList.add('searched-item'));

    document.querySelectorAll('.searched-item div').forEach((el, idx) => {
      let regex = new RegExp(searchItem, 'gi');
      el.innerHTML = nodes[idx].label.replace(regex, `<span class="highlight">$&</span>`);
    });
  }, [nodes]);

  const handleSearch = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (searchItem === '') {
      setGroupNodes(defaultNodes);
    } else {
      const res = labels
        .filter(({ label }) => label.toLowerCase().includes(searchItem.toLowerCase()))
        .reduce((arr, { id, label }) => {
          return arr.concat({
            id: id,
            label: label,
          });
        }, []);
      setGroupNodes(res);
    }
  };

  return [setSearchItem, handleSearch];
};

const useConfiguration = (type) => {
  const { configureDevicesState } = useStoreState();
  const { settingsList } = configureDevicesState;
  const dispatch = useDispatch();

  const updateSettingsList = (checkboxClicked, enableEditButton, settings) => {
    if (enableEditButton) {
      if (!checkboxClicked) {
        deleteSetting();
        return;
      }

      if (previousSettingExists()) {
        deleteSetting();
      }

      dispatch(
        storeSettingsList(
          settingsList.concat({
            id: type,
            config: settings,
          }),
        ),
      );
    } else if (!enableEditButton || !checkboxClicked) {
      deleteSetting();
    }
  };

  const previousSettingExists = () => {
    return Object.keys(settingsList).includes(type);
  };

  const deleteSetting = () => {
    dispatch(storeSettingsList(settingsList.filter((set) => set.id !== type)));
  };

  return [updateSettingsList];
};

const useCheckbox = (defaultVal, type) => {
  const { configureDevicesState } = useStoreState();
  const { allowedSettingsList } = configureDevicesState;
  const dispatch = useDispatch();

  const [checkboxClicked, setCheckboxClicked] = useState(defaultVal);

  useEffect(() => {
    if (checkboxClicked) {
      dispatch(storeAllowSetting(allowedSettingsList.concat(type)));
    } else {
      deleteSetting();
    }
  }, [checkboxClicked]);

  const toggleCheckbox = () => {
    setCheckboxClicked((prev) => !prev);
  };

  const deleteSetting = () => {
    dispatch(storeAllowSetting(allowedSettingsList.filter((el) => el !== type)));
  };

  return [checkboxClicked, toggleCheckbox];
};

export {
  useConstructor,
  usePrevious,
  useDidMount,
  useResponsive,
  useNodeSearch,
  useConfiguration,
  useCheckbox,
};
