import React, { Fragment, memo } from 'react';
import { SubFeatureAccessModeEnum } from '../../../config/SubFeatureEnums';
import * as Style from './Styles';

const Settings = (props) => {
  const { deviceSettingForms, accessMode,indent,isWex } = props;

  return (
    <>
      {deviceSettingForms &&
        deviceSettingForms.map((setting) => {
          return (
            <Style.SettingsWarp key={setting.key} indent = {indent} isWex={isWex}>
              {accessMode !== SubFeatureAccessModeEnum.READONLY ? (
                <Fragment>{setting.form}</Fragment>
              ) : null}
            </Style.SettingsWarp>
          );
        })}
    </>
  );
};

export default memo(Settings);
