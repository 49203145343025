import { TreeView } from '@veneer/core';
import styled from 'styled-components';
import { FlexColumnDirection, styleAttributes2 } from '../../GlobalStyles/GlobalStyles';

export const CategoryTreeWrap = styled(FlexColumnDirection)`
  height: 100%;
`;

export const CategoryTreeTitle = styled.h5`
  ${styleAttributes2}
  padding: 4px 0px;
`;

export const CategoryTreeColumn = styled.h5`
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 44px); // - 24px (header) - 20px (bottom padding)
  margin-top: 12px;
`;

export const CustomTreeView = styled(TreeView)`
.customtreeview.css-0 > div:first-child {
  background:  ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')}
}
.customtreeview ul li.css-0 > div {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')}
}
  .customtreeview span {
    font-family: 'Forma DJR Micro';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #212121;
    &:last-child {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;
    }
  }
`;
