import { useContext } from 'react';
import { ContentBrokerSvcClient } from '@jarvis/web-stratus-client';
import { shellProps } from '../shellProps';
import logger from '../../src/utils/logger';

const useContentBroker: (stack) => {
  fetchContentResources: (uuid) => Promise<any>;
} = (stack) => {
  const { authProvider } = useContext(shellProps);

  const ContentClient = new ContentBrokerSvcClient(stack, authProvider);


  const fetchContentResources = async (uuid) => {
    const params = {
      method: "POST",
      url: "managedappinfos/managedapp",
      header: {
          "X-HTTP-Method-Override": "GET"
      },
      body: JSON.stringify({
        startIndex: 0,
        maxResultSetSize: -1,
        criterionList: [
          {
            operation: "in",
            propertyName: "uuid",
            propertyValue: uuid
          },
          {
            operation: "in",
            propertyName: "state",
            propertyValue: ["Published", "Private"]
          },
          {
            operation: "eq",
            propertyName: "latest",
            propertyValue: true
          }
        ]
      })
    }

    return await ContentClient.relayContentMgt(params)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        logger.error("API error for fetchDeviceResources in useDeviceCache_Hook : ",err.message);
      });
  };

  return {
    fetchContentResources,
  };
};

export default useContentBroker;
