import { store } from '../../../src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.config.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDeviceId = (_payload) => {
  return {
    type: store.config.DEVICE_ID,
    payload: _payload,
  };
};

export const storeDeviceModelNumber = (_payload) => {
  return {
    type: store.config.DEVICE_MODEL_NUMBER,
    payload: _payload,
  };
};

export const storeShowMultipleConfigUi = (_payload) => {
  return {
    type: store.config.SHOW_MULTIPLE_CONFIG_UI,
    payload: _payload,
  };
};

export const storeSelectedDeviceIdList = (_payload) => {
  return {
    type: store.config.SELECTED_DEVICE_ID_LIST,
    payload: _payload,
  };
};
