export const MockEffectivePolicy = {
    "offset": 0,
    "limit": -1,
    "size": 1,
    "items": [
        {
            "id": "661cc94f72cc43df124e5be1",
            "tenantId": "20b0a1bc-c24b-485a-8cc4-27d0e4b94bf6",
            "deviceId": "AQAAAAGLf1CTcAAAAAFh93wj",
            "policies": [
                {
                    "id": "661cc918d3dd6d5a952b7522",
                    "name": "outgoing server"
                }
            ],
            "settings": [
                {
                    "policyId": "661cc918d3dd6d5a952b7522",
                    "groupId": "32a63531-8b3b-405f-99cb-88fd706f290d",
                    "attributeName": "outgoing-servers",
                    "setting": {
                        "name": "outgoing-servers.overwrite-existing",
                        "value": "false"
                    },
                    "assess": true,
                    "remediate": false,
                    "ignoreIfUnsupported": true,
                    "unentitled": false,
                    "entitlements": [],
                    "severity": "moderate"
                },
                {
                    "policyId": "661cc918d3dd6d5a952b7522",
                    "groupId": "32a63531-8b3b-405f-99cb-88fd706f290d",
                    "attributeName": "outgoing-servers",
                    "setting": {
                        "name": "outgoing-servers.servers",
                        "value": "[{\"address\":\"111\",\"port\":\"1111\",\"splitEmail\":\"524288\",\"ssl\":true,\"validateCert\":false,\"auth\":true,\"credential\":\"outgoing-servers.servers.credential.device-user\",\"email\":false,\"alert\":true,\"fax\":false,\"autosend\":false}]"
                    },
                    "assess": true,
                    "remediate": false,
                    "ignoreIfUnsupported": true,
                    "unentitled": false,
                    "entitlements": [],
                    "severity": "moderate"
                },
                {
                    "policyId": "661cc918d3dd6d5a952b7522",
                    "groupId": "32a63531-8b3b-405f-99cb-88fd706f290d",
                    "attributeName": "outgoing-servers",
                    "setting": {
                        "name": "outgoing-servers.mode",
                        "value": "outgoing-servers.mode.overwrite"
                    },
                    "assess": true,
                    "remediate": false,
                    "ignoreIfUnsupported": true,
                    "unentitled": false,
                    "entitlements": [],
                    "severity": "moderate"
                }
            ],
            "conflicts": []
        }
    ]
}