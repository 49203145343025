import styled from 'styled-components';
import { theme } from '../../GlobalStyles/GlobalStyles';
import primitives from '@veneer/primitives';

export const ModalFooter = styled.div.attrs((props) => {
  return { className: 'vn-button-group--responsive' };
})`
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledButton = styled.a`
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  font-family: ${theme.fontFamilyPrimary};
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  min-width: 80px;
  padding: 5px 15px;
  display: inline-flex;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  white-space: nowrap;
  outline: 0px;
  box-shadow: none;
  z-index: 1;
  color: ${(props) =>
    props.disabled ? '#adadad' : props.isWex ? primitives.color.darkNavy7 : '#027aae'};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? 'transparent'
        : props.isWex
        ? primitives.color.darkNavy0
        : 'rgba(2, 122, 174, 0.05)'};

    border-color: transparent;

    color: ${(props) =>
      props.disabled ? '#adadad' : props.isWex ? primitives.color.darkNavy7 : 'rgb(3, 92, 132)'};
    text-decoration: none;
  }
`;
