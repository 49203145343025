import { IconWarningAlt } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../../../config/App.config';
import EditDataContext from '../../../../context/EditDataContext';
import SettingsList from '../../Settings/List/SettingsList';
import FeatureHeader from '../Header/FeatureHeader';
import * as Style from './Styles';
import LocalizeHelper from '../../../../../src/helper/LocalizeHelper';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const FeatureList = (props) => {
  const { selectedData,isWex } = props;

  const { checkboxFeature, selectedCategory, selectedFeatures } = useContext(EditDataContext);

  const [selectedFeaturesList, setSelectedFeaturesList] = useState([]);
  const [sortedSelectedFeaturesListData, setSortedSelectedFeaturesListData] = useState([]);

  const { getDeviceFeatureLocString} = LocalizeHelper();

  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;

  useEffect(() => {
    setSelectedFeaturesList(props.features);
  }, []);

  useEffect(() => {
    const updatedFeature = selectedCategory.length
      ? checkboxFeature.filter((elem) => selectedFeatures.find(({ id }) => elem.id === id))
      : props.features;
    setSelectedFeaturesList(updatedFeature);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedFeaturesList(props.features);
  }, [props.features]);

  useEffect(() => {
    const sorted = selectedFeaturesList.slice().sort((a, b) => {
      return getDeviceFeatureLocString(a.id, 'name').toLowerCase() < getDeviceFeatureLocString(b.id, 'name').toLowerCase()? -1:  1;});
    setSortedSelectedFeaturesListData(sorted);
  }, [selectedFeaturesList]);

  return (
    <Fragment>
      <Style.EditFeatureListWrap>
        <Style.EditFeatureList>
          <Style.EditFeatureListItem>
            {sortedSelectedFeaturesListData.map((data) => {
              const entitlementCondition = selectedData.map((data) =>
                data.solutions?.includes('ws-hp.com/smcloud-advanced'),
              );

              const enableWarning =
                (AppConfig.entitlementFeature.featureFlag &&
                  data.entitlementId &&
                  ldAdvancedEntitlement &&
                  entitlementCondition?.includes(false)) ||
                (AppConfig.controlledPolicy.featureFlag && data.effectivePolicy) ||
                (AppConfig.notSupportedFeature.featureFlag && data.notSupportedDevices?.length);
              return (
                <Style.EditFeatureAccordion
                  items={[
                    {
                      content: <SettingsList featuresData={data} selectedData={selectedData} isWex={isWex} />,
                      header: {
                        centralArea: <FeatureHeader features={data} selectedData={selectedData} />,
                      },
                      id: `batch-edit-${data.id}-body`,
                    },
                  ]}
                  behavior={'singleExpand'}
                  id={`batch-edit-${data.id}`}
                  className={'batch-edit-feature-accordion'}
                />
              );
            })}
          </Style.EditFeatureListItem>
        </Style.EditFeatureList>
      </Style.EditFeatureListWrap>
    </Fragment>
  );
};

export default FeatureList;
