import { useContext } from 'react';
import DeviceClient from '../api/DeviceCacheForAppDeployment';
import { shellProps } from '../shellProps';
import { getDeviceCacheEnvConfig } from '../utils/dynamicMfeConstants';
import logger from '../utils/logger';

const useDeviceCacheForApp = (stack) => {
  const config = getDeviceCacheEnvConfig(stack);
  const { authProvider } = useContext(shellProps);
  const DevicesClient = new DeviceClient(config.deviceCacheUrl, authProvider);
  const fetchDeviceAppCollection = async (deviceId) => {
    return await DevicesClient.getDeviceAppCollection(deviceId)
      .then((result) => {
        return result;
        })
      .catch((err) => {
        logger.error("API error for fetchDeviceAppCollection in useDeviceApp_Hook : ", err.message);
        });
  };
  return {
    fetchDeviceAppCollection,
  };
};
  export default useDeviceCacheForApp;
