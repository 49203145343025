import React from 'react';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';
import * as Style from '../../../Feature/Styles';

const IPConfigPrecedenceCard = ({ id, value }) => {

    const { getDeviceSettingsLocString } = LocalizeHelper();

    const output = value.map(item => item.method);
    let component = (
        <div>
            {output.map((value, index) => (
                <React.Fragment key={index}>
                    <Style.AccordionTitle>
                        {getDeviceSettingsLocString(id, value)}
                    </Style.AccordionTitle>

                </React.Fragment>
            ))}
        </div>
    );


    return component;
};

export default IPConfigPrecedenceCard;
