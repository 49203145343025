import { TreeView } from '@veneer/core';
import styled from 'styled-components';
import { FlexColumnDirection, styleAttributes2 } from '../../GlobalStyles/GlobalStyles';

export const CategoryTreeWrap = styled(FlexColumnDirection)`
  height: 100%;
`;

export const CategoryTreeTitle = styled.h5`
  ${styleAttributes2}
  padding: 4px 0px;
`;

export const CategoryTreeColumn = styled.h5`
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 44px); // - 24px (header) - 20px (bottom padding)
  margin-top: 12px;
`;

export const CustomTreeView = styled(TreeView)`
  .batchviewcustomtree.css-0 > div:first-child {
    background: rgba(2, 122, 174, 0.1);
  }
  .batchviewcustomtree ul li.css-0 > div {
    background: rgba(2, 122, 174, 0.1);
  }
  .batchviewcustomtree span {
    font-family: 'Forma DJR Micro';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #212121;
  }
  .batchviewcustomtree span.total-children {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    display: flex;
    font-family: 'Forma DJR Micro';
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;
    width: 24px;
    height: 20px;
    background: rgba(33, 33, 33, 0.1);
    border-radius: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }
`;
