import React, { useContext } from 'react';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { AppConstants } from '../../../utils/appConstants';
import * as Style from './Styles';

export const EditableToggleBatchMode = (props) => {
  const { getCommonStrings } = LocalizeHelper();
  const { checkedFeaturesToggle, setCheckedFeaturesToggle } = useContext(EditDataContext);

  return (
    <Style.EditableToggleWrap>
      <Style.ToggleButton
        onChange={(value) => setCheckedFeaturesToggle(value)}
        on={checkedFeaturesToggle}
      />
      <Style.EditableToggleText>
        {getCommonStrings(AppConstants.localization.commonstrings.showSelectedItems)}
      </Style.EditableToggleText>
    </Style.EditableToggleWrap>
  );
};
