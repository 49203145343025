import {Modal} from '@veneer/core';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { styleAttributes9 } from '../GlobalStyles/GlobalStyles';

export const CustomModal = styled(Modal)`
  .vn-modal--dialog {
    display: flex;
  }

  .vn-modal--content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px) !important; // margin(24px + 24px)
    height: calc(100% - 48px) !important; // margin(24px + 24px)
    box-sizing: border-box;
    padding: 0 !important;
    min-height: 550px;
    border-radius: 0px;
  }

  .vn-modal--body {
    height: calc(100% - 98px); //(98px -> footer)
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${Veneer.colorGray0};
    overflow: hidden;
  }

  .vn-modal--footer {
    border-top: 1px solid rgba(33, 33, 33, 0.1);
    padding: 31.5px 24px 31.5px 26px;
    background: ${Veneer.colorGray0};
    width: calc(100% - 50px); // margin(24px + 24px)
  }
`;

export const SubTitle = styled.div`
  ${styleAttributes9}
  padding: 23px 26px 0px;
`;
