import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import logger from '../../src/utils/logger';

class DeviceClient {
  baseUrl;
  authProvider;

  constructor(baseUrl, authProvider) {
    this.baseUrl = baseUrl + '/v1';
    this.authProvider = authProvider;
  }

  getDeviceResourcesCollection = async (deviceId) => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider);
    const response = await client.getDeviceResources(deviceId).catch((error) => {
      logger.error('API error for getDeviceResourcesCollection in DeviceCacheClient : ', error.message)
      return null;
    });
    const deviceResourcesCollection = response?.data;
    return deviceResourcesCollection;
  };
}

export default DeviceClient;
