import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { AppConfig } from '../../../config/App.config';
import CategoryList from '../Category/List/CategoryList';
import CategoryTree from '../CategoryTree/CategoryTree';
import { EditableToggleBatchMode } from '../EditableToggleBatchMode/EditableToggleBatchMode';
import SearchFeature from '../SearchFeature/SearchFeature';
import * as Style from './Styles';

const Body = (props) => {
  const { selectedData , isWex} = props;
  const LDSearch = useFlags()?.dcSearch;
  const LDShowSelectedItems = useFlags()?.dcShowSelectedItems;
  return (
    <Style.ModalBody>
      <Style.ModalBodyRow>
        <Style.CategoryTreeWrap>
          <CategoryTree />
        </Style.CategoryTreeWrap>
        <Style.CategoryWrap>
          {AppConfig.editFilters.featureFlag ? (
            <Style.EditFiltersWrap>
              <div>
                {AppConfig.batchModeSearch.featureFlag && LDSearch ? <SearchFeature /> : null}
              </div>
              {AppConfig.batchModeToggleFilter.featureFlag && LDShowSelectedItems ? (
                <EditableToggleBatchMode />
              ) : null}
            </Style.EditFiltersWrap>
          ) : null}

          <CategoryList selectedData={selectedData} isWex={isWex} />
        </Style.CategoryWrap>
      </Style.ModalBodyRow>
    </Style.ModalBody>
  );
};

export default Body;
