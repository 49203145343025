import React, { useEffect } from 'react';
import { AppConfig } from '../../../config/App.config';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { AppConstants } from '../../../utils/appConstants';
import * as Style from './Styles';

const Notification = (props) => {
  const { notSupportedDevices } = props;
  const { offset } = React.useContext(EditDataContext);
  const notSupportedDevicesArr = notSupportedDevices.map((data) => {
    return data;
  });
  const [divRef, setDivRef] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const { getNotificationStrings } = LocalizeHelper();

  const spliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      const chunkChild = chunk.map((data, index) => {
        return <Style.CustomCoachMarkDesc>{data}</Style.CustomCoachMarkDesc>;
      });
      const obj = {
        title: (
          <Style.CustomCoachMarkTitle>
            {getNotificationStrings(
              AppConstants.localization.notification.key,
              AppConstants.localization.notification.coachMarkTitle,
            )}
          </Style.CustomCoachMarkTitle>
        ),
        description: [...chunkChild],
      };
      res.push(obj);
    }
    return res;
  };

  let coachmarkSteps;
  if (notSupportedDevicesArr.length) {
    coachmarkSteps = spliceIntoChunks(notSupportedDevicesArr, AppConfig.notSupportedFeature.pagination);
  }

  useEffect(() => {
    setActive(false);
  }, [offset]);

  const handleOpen = () => {
    setActive(true);
    setActiveStep(0);
  };

  const handleChange = (event, stepIndex) => {
    setActiveStep(stepIndex);
  };
  return (
    <Style.Notify>
      <Style.IconWarningAltNotifyStyle filled={true} size={24} />
      <Style.NotifyContent>
        <Style.NotifyHeader>
          {getNotificationStrings(
            AppConstants.localization.notification.key,
            AppConstants.localization.notification.title,
          )}
        </Style.NotifyHeader>
        <Style.NotifyDesc>
          <Style.NotifyDescContent>
            {getNotificationStrings(
              AppConstants.localization.notification.key,
              AppConstants.localization.notification.desc,
            )}
          </Style.NotifyDescContent>
          <Style.NotifyDescIcon size={16} ref={setDivRef} onClick={handleOpen} />
          <Style.CustomCoachMark
            placement="bottom"
            closeButton
            activeStep={activeStep}
            anchorElement={divRef}
            steps={coachmarkSteps}
            isActive={active}
            onClose={() => setActive(false)}
            onChange={(event, stepIndex) => handleChange(event, stepIndex)}
          />
        </Style.NotifyDesc>
      </Style.NotifyContent>
    </Style.Notify>
  );
};
export default Notification;
