import React, { useContext, useEffect } from 'react';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';

const SearchFeature = (props) => {
  const { selectedCategory, setSearchValue, selectedCategoryName, setSelectedCategoryName } =
    useContext(EditDataContext);
  const { getDeviceCategoryLocString, getCommonStrings } = LocalizeHelper();

  useEffect(() => {
    const selectedCategoryKey = selectedCategory[selectedCategory.length - 1];
    setSelectedCategoryName(selectedCategoryKey);
  }, [selectedCategory]);

  return (
    <Style.CustomSearch
      placeholder={getCommonStrings('searchInCategory', {
        category: !!selectedCategoryName
          ? getDeviceCategoryLocString(selectedCategoryName)
          : getDeviceCategoryLocString('all'),
      })}
      onChange={(v) => setSearchValue(v)}
      data-testid={'id-batch-view-search'}
    />
  );
};

export default SearchFeature;
