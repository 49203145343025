import { Card } from '@veneer/core';
import React, { useContext } from 'react';
import { shellProps } from '../../../shellProps';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';
export const AdvanceFeatureWarningMessage = (props) => {
  const { showMultipleConfigUi, selectedDeviceIdList} = useContext(shellProps);
  const { getCommonStrings } = LocalizeHelper();
  const content = () => {
    return (
      <Style.AdvanceFeatureCard>
      <Style.IconWarningAltNotifyStyle filled={true} size={24} />
      <Style.AdvanceFeatureCardContent>
        <Style.AdvanceFeatureCardTitle>
          {getCommonStrings('entitlementAdvanceLabel')}
        </Style.AdvanceFeatureCardTitle>
        <Style.AdvanceFeatureCardDesc>{
        showMultipleConfigUi && selectedDeviceIdList?.length > 1
                ?  getCommonStrings('entitlementAdvanceSubtitleForBatchView')
                :    getCommonStrings('entitlementAdvanceSubtitleForEditView')}
       
        </Style.AdvanceFeatureCardDesc>
      </Style.AdvanceFeatureCardContent>
    </Style.AdvanceFeatureCard>
    );
  };

  return (
    <div>
      <Card
        content={content() as any}
        customStyle={{
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      />
    </div>
  );
};
