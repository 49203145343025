import styled, { css } from 'styled-components';

export const theme = {
  fontFamilyPrimary: 'Forma DJR Micro',
};

// *color:colorGray7 ~ #404040
// *color:colorGray10 ~ #212121
// *color:colorGray5 = '#737373';


export const fontFamilyColorGray10 = css`
  font-family: Forma DJR Micro;
  color: #212121;
`;

export const fontFamilyColorGray7 = css`
  font-family: Forma DJR Micro;
  color: #404040;
`;

export const fontFamilyColorGray5 = css`
  font-family: Forma DJR Micro;
  color: #737373;
`;

export const fontSize24LineHeight32 = css`
  font-size: 24px;
  line-height: 32px;
`;

export const fontSize20LineHeight28 = css`
  font-size: 20px;
  line-height: 28px;
`;

export const fontSize16LineHeight20 = css`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const fontSize16LineHeight24 = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const fontSize14LineHeight20 = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
`;

export const fontSize12LineHeight16 = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
`;

export const styleAttributes1 = css`
  ${fontFamilyColorGray10}
  ${fontSize20LineHeight28}
`;

export const styleAttributes2 = css`
  ${fontFamilyColorGray7}
  ${fontSize20LineHeight28}
`;

export const styleAttributes3 = css`
  ${fontFamilyColorGray10}
  ${fontSize16LineHeight20}
`;

export const styleAttributes4 = css`
  ${fontFamilyColorGray7}
  ${fontSize16LineHeight20}
`;

export const styleAttributes5 = css`
  ${fontFamilyColorGray10}
  ${fontSize14LineHeight20}
`;

export const styleAttributes6 = css`
  ${fontFamilyColorGray7}
  ${fontSize14LineHeight20}
`;

export const styleAttributes7 = css`
  ${fontFamilyColorGray10}
  ${fontSize24LineHeight32}
`;

export const styleAttributes8 = css`
  ${fontFamilyColorGray7}
  ${fontSize12LineHeight16}
`;

export const styleAttributes9 = css`
  ${fontFamilyColorGray7}
  ${fontSize16LineHeight24}
`;

export const styleAttributes10 = css`
  ${fontFamilyColorGray5}
  ${fontSize14LineHeight20}
`;

export const styleAttributes11 = css`
  ${fontFamilyColorGray10}
  ${fontSize16LineHeight24}
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexRowJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexRowAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexRowAlignCenterBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexRowDirection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumnDirection = styled.div`
  display: flex;
  flex-direction: column;
`;
