
import { IconWarningAlt } from '@veneer/core';
import React from 'react';
import { DeviceNotExistWrap, WarningIcon, WarningLabel } from './Styles';
import LocalizeHelper from '../../../helper/LocalizeHelper';
export const DeviceNotExist = () => {
  const {
    getCommonStrings
  } = LocalizeHelper();
  return (
    <DeviceNotExistWrap>
      <WarningIcon data-testid="warningIcon">
        <IconWarningAlt size={24} />
      </WarningIcon>
      <WarningLabel data-testid="warningLabel">{getCommonStrings('warningLabelText')}</WarningLabel>
    </DeviceNotExistWrap>
  );
};
