import DefaultPolicyConstraints from '@manageability/constraints/policy.json';
import { featureConstraintsMap } from '../dataModel/FeatureConstraintsMap';
import ConstraintsBatchMode from '../dataParser/ConstraintsBatchMode';
const GetFeaturesDataHelper = () => {
  const { getPluginConstraints } = ConstraintsBatchMode();
  const getFeaturesData = (pluginDatas) => {
    const uniqpluginDatas = pluginDatas?.filter(
      (value, index, self) => index === self.findIndex((t) => t.plugInName === value.plugInName),
    );

    if (pluginDatas?.length) {
      if (uniqpluginDatas.length > 1) {
        const supportedDeviceResourcesArray = pluginDatas.map((data) => {
          return getPluginConstraints(data.plugInName)?.supportedDeviceResources.map((item) => {
            return Object.assign(
              {},
              {
                modelNumber: data.number,
                modelName: data.name,
                ...item,
              },
            );
          });
        });

        const supportedDeviceResources = supportedDeviceResourcesArray.flat(1);

        const resourceids = supportedDeviceResources.map((id) => id?.resource);

        const filteredsupportedResources = supportedDeviceResources?.filter(
          (resource, index) => resource && !resourceids.includes(resource, index + 1),
        );

        const policyData = DefaultPolicyConstraints;

        const results = policyData.filter(({ resource: id1 }) =>
          filteredsupportedResources.some(({ resource: id2 }) => id2 === id1),
        );

        const extractUniqueId = (arr) => {
          const res = [];
          const finalRes = [];

          for (const element of arr) {
            res[element] = res[element] || { occurrences: 0 };
            res[element].occurrences += 1;
          }

          for (const resultData of results) {
            if (res[resultData.resource].occurrences < supportedDeviceResourcesArray.length) {
              finalRes.push({ resource: resultData.resource });
            }
          }

          return finalRes;
        };

        const uniqueids = extractUniqueId(resourceids);

        if (uniqueids.length) {
          const initializeResource = () => ({
            occurrences: 0,
            supportedDevices: [],
            resourceName: '',
            notSupportedDFeatures: [],
          });

          const initializeResourceInfo = (resource, settings) => ({
            occurrences: 0,
            supportedDevices: [],
            resourceName: resource,
            notSupportedDFeatures: [],
            settings: settings,
          });

          const isDeviceExcluded = (settings, modelNumber) =>
            settings.some((data) => data.excludeDeviceModelNumberList?.includes(modelNumber));

          const getFeaturesForResource = (featureConstraintsMap, resource, settings) =>
            featureConstraintsMap
              .filter((element) =>
                element.constraints.some((data) => data.resource.includes(resource)),
              )
              .map((element) => element.featureid)
              .filter((element) => element !== undefined);

          const processResource = (
            res,
            resource,
            modelName,
            modelNumber,
            settings,
            featureConstraintsMap,
          ) => {
            let resourceInfo = res[resource] || initializeResourceInfo(resource, settings);

            if (!resourceInfo.supportedDevices.includes(modelName)) {
              if (!isDeviceExcluded(settings, modelNumber)) {
                resourceInfo.supportedDevices.push(modelName);
                resourceInfo.occurrences += 1;
              }

              const featuresConstraintsData = getFeaturesForResource(
                featureConstraintsMap,
                resource,
                settings,
              );

              const supportedFeatures = getFeaturesForResource(
                featureConstraintsMap,
                resource,
                settings,
              );

              if (
                supportedFeatures.length &&
                supportedFeatures.length !== featuresConstraintsData.length
              ) {
                const notSupportedFeaturesArr = featuresConstraintsData.filter(
                  (el) => !supportedFeatures.includes(el),
                );

                const notSupportedFeaturesRes = notSupportedFeaturesArr.map((data) => ({
                  featureId: data,
                  deviceName: modelName,
                }));

                resourceInfo.notSupportedDFeatures.push(...notSupportedFeaturesRes);
              }
            }

            res[resource] = resourceInfo;
          };

          const supportedDeviceOccurenceData = supportedDeviceResourcesArray.reduce((res, arr) => {
            arr.forEach(({ modelName, modelNumber, resource, settings }) => {
              processResource(
                res,
                resource,
                modelName,
                modelNumber,
                settings,
                featureConstraintsMap,
              );
            });
            return res;
          }, {});

          for (const resultData of results) {
            if (
              resultData.resource ==
                supportedDeviceOccurenceData[resultData.resource].resourceName &&
              (supportedDeviceOccurenceData[resultData.resource].occurrences <
                supportedDeviceResourcesArray.length ||
                supportedDeviceOccurenceData[resultData.resource].notSupportedDFeatures.length)
            ) {
              const modelNames = pluginDatas.map((plugInData) => {
                return plugInData.name;
              });
              const notSupportedPlugins = modelNames.filter(
                (plugins) =>
                  !supportedDeviceOccurenceData[resultData.resource].supportedDevices.includes(
                    plugins,
                  ),
              );

              const uniqueNotSupportedPlugins = notSupportedPlugins.filter((item, pos) => {
                return notSupportedPlugins.indexOf(item) == pos;
              });

              let notSupportedFeatures;

              if (
                supportedDeviceOccurenceData[resultData.resource].occurrences <
                supportedDeviceResourcesArray.length
              ) {
                const notSuppotedResource =
                  supportedDeviceOccurenceData[resultData.resource].resourceName;

                const supportedFeatures = featureConstraintsMap
                  .map((element) => {
                    if (
                      element.constraints.some((data) =>
                        data.resource.includes(notSuppotedResource),
                      )
                    ) {
                      return {
                        featureId: element.featureid,
                        deviceName: uniqueNotSupportedPlugins,
                      };
                    }
                  })
                  .filter((elements) => {
                    return elements !== undefined;
                  });

                notSupportedFeatures = supportedFeatures;
              } else {
                const array =
                  supportedDeviceOccurenceData[resultData.resource].notSupportedDFeatures;

                const output = array.reduce(function (o, cur) {
                  // Get the index of the key-value pair.
                  const occurs = o.reduce(function (n, item, i) {
                    return item.featureId === cur.featureId ? i : n;
                  }, -1);

                  // If the name is found,
                  if (occurs >= 0) {
                    // append the current value to its list of values.
                    o[occurs].deviceName = o[occurs].deviceName.concat(cur.deviceName);

                    // Otherwise,
                  } else {
                    // add the current item to o (but make sure the value is an array).
                    const obj = {
                      featureId: cur.featureId,
                      deviceName: [cur.deviceName],
                    };
                    o = o.concat([obj]);
                  }

                  return o;
                }, []);

                notSupportedFeatures = output;
              }
              const filterUniq = supportedDeviceResources
                .filter(
                  (supportedDeviceResourcesData) =>
                    supportedDeviceResourcesData.resource === resultData.resource,
                )
                .map((item) => {
                  delete item.modelName;
                  delete item.modelNumber;
                  return {
                    ...item,
                    isSupport: false,
                    notSupportedDevices: uniqueNotSupportedPlugins,
                    notSupportedFeatures: notSupportedFeatures,
                  };
                });

              filterUniq.forEach((newData) => {
                let index = results.findIndex((o) => o.resource === newData.resource);
                if (index !== -1) results[index] = { ...results[index], ...newData };
                else results.push(newData);
              });
            }
          }
        }
        return results;
      } else {
        const results = getPluginConstraints(pluginDatas[0].plugInName)?.supportedDeviceResources;
        return results;
      }
    } else {
      const results = DefaultPolicyConstraints;
      return results;
    }
  };

  return {
    getFeaturesData,
  };
};

export default GetFeaturesDataHelper;
