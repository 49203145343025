import React from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';

const Header = () => {
  // Localization Helper
  const { getDeviceModalLocString } = LocalizeHelper();

  return (
    <Style.ModalHeader>
      <Style.ModalTitle data-testid={'id-batch-view-title'}>
        {getDeviceModalLocString('batch-mode_modal-title')}
      </Style.ModalTitle>
    </Style.ModalHeader>
  );
};

export default Header;
