import styled from 'styled-components';
import { FlexRowCenter } from '../../GlobalStyles/GlobalStyles';

export const LoadingWrapper = styled(FlexRowCenter)`
  flex: 1;
  height: 10rem;
  //background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;
