import { useDispatch } from 'react-redux';
import CategoryTreeHelper from '../helper/CategoryTreeHelper';
import { setFeaturesData, setRulesData, setSettingsData } from '../store/deviceConfig/action';
import CategoryControl from './CategoryControl';
import ConstraintsRuleParser from './ConstraintsRuleParser';
import logger from '../../src/utils/logger';

const BatchModeDeviceConfigParser = () => {
  const { getAsyncJsonData } = CategoryControl();
  const dispatch = useDispatch();
  const { validateIfRuleExists, tempConditionCheck } = ConstraintsRuleParser();
  const { getPolicyAttributeDescription } = CategoryTreeHelper();

  const getBatchModeModalData = (constraintsResources, deviceModelNumber) => {
    return new Promise((resolve, reject) => {
      getAsyncJsonData()
        .then((combinedSettings: any) => {
          const dataModelFeatures = [];
          const dataModelSettings = [];

          // Iterate DataModal
          Object.keys(combinedSettings).forEach((key) => {
            const combineDatas = combinedSettings[key];

            // Adding Categoryname and filter visible data
            const filterCombineDataFeatures = combineDatas
              ?.map((_combineData) => {
                const categoryLevel = getPolicyAttributeDescription(_combineData.id);
                return { ..._combineData, categoryName: key, categoryLevel };
              })
              .filter(
                (_resultCombineData) =>
                  (_resultCombineData.configurable === undefined ||
                    _resultCombineData.configurable === true) &&
                  (_resultCombineData.visible === undefined || _resultCombineData.visible === true),
              );

            // Filter settings
            const filterCombineDataSettings = combineDatas
              ?.map((_combineData) => {
                return _combineData.subfeatures;
              })
              .flat(1);

            dataModelFeatures.push(...filterCombineDataFeatures);
            dataModelSettings.push(...filterCombineDataSettings);
          });

          // Compare Datamodal with constraints data
          // Added constraints values in settings Data
          const finalSettings = dataModelSettings
            ?.map((_dataModelSetting) => {
              const res = constraintsResources?.find(
                (_constraintsResource) =>
                  _constraintsResource.resource === _dataModelSetting.resource,
              );
              if (res) {
                const constraints = res.settings?.find(
                  (_constraintsSetting) =>
                    _constraintsSetting.name === _dataModelSetting.settingsName,
                );

                const isExcludeDeviceModelNumber =
                  constraints?.excludeDeviceModelNumberList?.filter(
                    (e) => deviceModelNumber.indexOf(e) !== -1,
                  );

                if (
                  constraints &&
                  isExcludeDeviceModelNumber?.length !== deviceModelNumber.length
                ) {
                  return {
                    ..._dataModelSetting,
                    notSupportedFeatures: res.notSupportedFeatures,
                    constraints,
                    fleetValue: constraints.defaultValue ? constraints.defaultValue : '',
                  };
                }
              }
            })
            .filter(function (_resultSetting) {
              return _resultSetting !== undefined;
            });

          // Adopt all the filtered constraints Data
          const resultFeatures = dataModelFeatures
            ?.map((_dataModelFeatue) => {
              return {
                ..._dataModelFeatue,
                subfeatures: _dataModelFeatue.subfeatures
                  ?.map((_subfeature) => {
                    const result = finalSettings?.find(
                      (_finalSetting) =>
                        _finalSetting.id === _subfeature.id &&
                        (_subfeature.visible == undefined || _subfeature.visible),
                    );
                    return result;
                  })
                  .filter(function (_resultSubfeature) {
                    return _resultSubfeature !== undefined;
                  }),
              };
            })
            .filter(function (_resultDataModelFeatue) {
              return _resultDataModelFeatue.subfeatures.length;
            });

          // Added not supported devices as feature level
          const finalFeatures = resultFeatures?.map((_resultFeature) => {
            const filteredSettings = _resultFeature.subfeatures.filter(
              (_subfeature) => _subfeature.notSupportedFeatures && _subfeature.notSupportedFeatures,
            );

            const filterNotSupportedFeatures = filteredSettings
              ?.map((_filteredSetting) => {
                return _filteredSetting.notSupportedFeatures;
              })
              .flat(1);

            const notSuppotedFeaturesResult = filterNotSupportedFeatures.filter((c, index) => {
              return filterNotSupportedFeatures.indexOf(c) === index;
            });

            if (notSuppotedFeaturesResult.length) {
              if (_resultFeature.id === notSuppotedFeaturesResult[0].featureId) {
                _resultFeature.notSupportedDevices = notSuppotedFeaturesResult[0].deviceName;
              }
            }

            return {
              ..._resultFeature,
              subfeatures: _resultFeature.subfeatures?.map((_subfeature) => {
                delete _subfeature.notSupportedDevices;
                return {
                  ..._subfeature,
                };
              }),
            };
          });

          // Check Rules Data
          const rulesExistsData = finalSettings
            ?.map((_finalSetting) => {
              return validateIfRuleExists(_finalSetting.constraints, _finalSetting.settingsName);
            })
            .flat(1);

          // Filter dependsOn values
          const filteredRulesExistsData = rulesExistsData.filter((_rulesExistsData) => {
            return _rulesExistsData.dependsOn.length;
          });

          // Dispatched Data to redux
          dispatch(setFeaturesData(finalFeatures));
          dispatch(setSettingsData(finalSettings));
          dispatch(setRulesData(filteredRulesExistsData));

          // Check Rules Condition
          if (filteredRulesExistsData.length) {
            finalSettings?.map((_setting) => {
              return tempConditionCheck(filteredRulesExistsData, _setting);
            });
          }

          resolve('Success');
        })
        .catch((err) => {
          logger.error('BatchModeDeviceConfigParser_TemplateControlsParser :: getMergedTabControls rejecting with error', err);
          reject(err);
        });
    });
  };

  return {
    getBatchModeModalData,
  };
};

export default BatchModeDeviceConfigParser;
