export const appConfigDataResponse = {
  connType: ['print-iot'],
  0: 'print-iot',
  deviceId: 'AQAAAAGRLJJXlAAAAAEIMk76',
  hardware: null,

  identity: {
    assetNumber: '55555',
    benefitsControlModel: 'none',
    biosVersion: null,
    bizModel: '',
    bleHPSpecVersion: '',
    colorSupported: true,
    connectionType: null,
    countryRegion: '',
    deviceSegment: 'SMB',
    deviceUuid: 'a2457b25-8dcd-4e6d-9e9c-fac3958eb810',
    displayProfile: '',
    externalReference: null,
    firmwareVersion: '2554780_236187',
    friendlyName: 'HP Color LaserJet MFP E87740',
    language: '',
    lastUpdatedAt: '2024-08-07T11:21:59.279Z',
    location: 'A',
    makeAndModel: {
      family: null,
      manufacturer: 'hp',
      name: 'HP Color LaserJet Managed MFP E877dn',
      number: '5QK03A',
      productId: null,
      series: 'HP Color LaserJet Managed MFP E877, E87740, E87750, E87760, E87770',
      sku: null,
      type: null,
    },
    misc: null,
    platformIdentifier: 'gen2',
    programLevel: 'Base',
    serialNumber: 'JASPERAPP1',
    supplyDelivery: '',
    supplyType: 'toner',
  },

  images: [
    {
      url: 'https://devices.us1.api.ws-hp.com/devices/v1/images/3789a65e7c70e3f5d7fda272eeac9ddf/42x26.png',
    },
    {
      url: 'https://devices.us1.api.ws-hp.com/devices/v1/images/3789a65e7c70e3f5d7fda272eeac9ddf/130x102.png',
    },
    {
      url: 'https://devices.us1.api.ws-hp.com/devices/v1/images/3789a65e7c70e3f5d7fda272eeac9ddf/170x128.png',
    },
    {
      url: 'https://devices.us1.api.ws-hp.com/devices/v1/images/3789a65e7c70e3f5d7fda272eeac9ddf/300x245.png',
    },
  ],

  lastUpdatedAt: '2024-08-12T06:48:59.331Z',

  mediaTypes: {
    defaultMediaSource: '',
    inputs: [
      {
        mediaSourceId: 'tray-3',
        state: '',
        stateReason: '',
        type: '',
      },
      {
        mediaSourceId: 'tray-2',
        state: '',
        stateReason: '',
        type: '',
      },
      {
        mediaSourceId: 'tray-1',
        state: '',
        stateReason: '',
        type: '',
      },
    ],
    lastUpdatedAt: '2024-08-07T11:24:39.938Z',
    outputs: [],
    paths: [],
  },

  network: {
    adapters: [
      {
        enabled: false,
        hostname: 'NPI10244F',
        ipv4: { enabled: true, address: { ip: '15.77.128.102' } },
        ipv6: { enabled: true, address: { ip: '2620:0:a02:e00d:16cb:19ff:fe10:244f' } },
        macAddress: '14:cb:19:10:24:4f',
        name: 'eth1',
        type: 'wiredEthernet',
      },
      {
        enabled: false,
        hostname: 'NPI10244F',
        ipv4: { enabled: true, address: { ip: '15.77.128.102' } },
        ipv6: { enabled: true, address: { ip: '2620:0:a02:e00d:16cb:19ff:fe10:244f' } },
        macAddress: '14:cb:19:10:24:4f',
        name: 'eth0',
        type: 'wiredEthernet',
      },

      { enabled: false, ipv4: { address: {} }, ipv6: { address: {} }, name: 'usb', type: '' },
    ],
    lastUpdatedAt: '2024-08-12T06:30:36.616Z',
  },

  outOfSync: false,
  ownership: {
    accountId: '3eb01392-0954-4a57-a965-445efccc3581',
    fqTenantId: null,
    lastUpdatedAt: '2024-08-07T11:22:11.061Z',
    ownershipId: null,
    userId: null,
  },

  software: {
    apps: [
      {
        installedAt: '2024-08-08T08:53:55.925Z',
        license: '',
        store: 'app-deploy',
        title: 'Regus plugin',
        type: 'workpath-app',
        updatedAt: '2024-08-08T08:53:55.925Z',
        uuid: '89bdd004-3f3b-487c-9fd0-830a29d16c16',
        version: '1.01.090',
      },
      {
        installedAt: '2024-08-08T08:54:43.417Z',
        license: '',
        store: 'app-deploy',
        title: 'test',
        type: 'workpath-app',
        updatedAt: '2024-08-08T08:54:43.417Z',
        uuid: 'ed08471f-0db5-4404-9872-8944a87ae1f2',
        version: '1.3',
      },
      {
        installedAt: '2024-08-08T08:51:35.006Z',
        license: '',
        store: 'app-deploy',
        title: 'Regus plugin',
        type: 'workpath-app',
        updatedAt: '2024-08-08T08:51:35.006Z',
        uuid: '89bdd004-3f3b-487c-9fd0-830a29d16c16',
        version: '1.01.090',
      },
    ],
    bios: null,
    lastUpdatedAt: '2024-08-12T02:23:29.017Z',
    os: null,
  },

  solutionConfig: {
    cloudShortcutsAvailable: false,
    cloudShortcutsEnabled: false,
    eprintEmailAddress: 'vyywt67wy493@email.devpie.hpeprint.com',
    eprintSupported: true,
    printOnTheGoSupport: 'autoRelease',
  },

  solutionMetadata: {
    NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe: {
      active: 'true',
      entitlementType: 'premium',
      lastAssessed: 'Mon Aug 12 06:40:04 UTC 2024',
      policyId: '66797e85951bda2c20e550eb',
      result: 'highRisk',
      securityPolicy: 'Essential Security Template',
      securityPolicyCount: 1,
      state: 'assessFailed',
    },
  },

  solutions: [
    'ws-hp.com/sds',
    'ws-hp.com/hpsm',
    ,
    'ws-hp.com/r4b',
    'ws-hp.com/smcloud',
    'ws-hp.com/smcloud-advanced',
    'ws-hp.com/fleet-proxy',
    'ws-hp.com/sds-advanced',
    'ws-hp.com/enterprise-devicemgmt',
    'ws-hp.com/fleet-proxy-sn',
    'ws-hp.com/enterprise-telemetry',
  ],

  status: {
    acceptingJobs: true,
    connectionState: 'online',
    connectionStateCode: 1,
    connectionStateLastUpdatedAt: '2024-08-07T11:21:53.952Z',
    ippPrinterStateReasons: [],
    lastUpdatedAt: '2024-08-12T06:48:58.613Z',
    powerState: 'ACTIVE_NORMAL',
    printerState: 'IDLE',
    printerStateReasons: ['NONE'],
    printerStateSeverity: 'NONE',
    scanADFState: 'NOT_ADF_SCANNER',
    scanState: 'NOT_SCANNER',
  },

  supplies: {
    lastUpdatedAt: '2024-08-12T06:48:59.331Z',
    overallLevelStateCode: null,
    consumables: [],
  },

  timestamp: {
    claimRevokeTime: null,
    claimTime: '2024-08-07T11:22:11.061Z',
    deviceTime: null,
    firmwareDate: null,
    firstRegistrationTime: '2024-08-07T11:21:27.000Z',
    lastRegistrationTime: null,
    lastResetTime: '2024-08-07T11:21:27.892Z',
    lastSeenTime: '2024-08-12T06:48:25.000Z',
    lastUpdatedAt: '2024-08-12T06:48:58.613Z',
    manufactureDate: null,
    purchasedDate: null,
  },
  type: 'iot-printer',
};
