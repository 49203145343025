export const contentBrokerResponse = {
    data: {
  resourceList: [
    {
      apkPackageName: 'com.sender2.muraleed.hackerr',
      autoSignState: 'NotStarted',
      categoryType: 'DocumentMgt',
      compatibleDeviceVersions: [],
      contactEmail: 'prathibha.muraleedhara@hp.com',
      date: 1565222400000,
      dateAdded: 1569513956967,
      dateApproved: 1569545216515,
      datePublished: 1569545217872,
      defaultConfig: '',
      enrollmentPlan: 'Listed',
      feePaymentDate: 1625097600000,
      fqTenantId:
        '5c1c2547bf965dee00465a3b/8a8903c2-760a-4728-a46b-fef4c56fe473/597b9631-597c-4170-b14b-e945a2d610c2/656fee04-40c7-4a90-a96d-6d4e50b20934',
      hpApp: true,
      incompatibleDeviceVersions: [],
      installFile: 'app-release.apk',
      installationDataSynced: 1723348785828,
      isAddedAppClientSecret: false,
      lastUpdateTime: 1591547864850,
      latest: true,
      licenseType: 'None',
      metadata: {
        ' 5d6753a8-ef6b-4983-8613-a2db64be310b': {
          asAttachment: false,
          class:
            'net.xoaframework.solutionplatform.ws.v1.contentmgt.apps.managed.ApcManagedAppMetaData',
          dataId: '5d6753a8-ef6b-4983-8613-a2db64be310b',
          dataKind: 'Icon50x50',
          dataType: 'File',
          downloadLink:
            'https://d5bl54l33ylvh.cloudfront.net/public/a80dcb26-cb75-4765-8e17-422008fcb511/1271e86f-7542-4ca3-8814-e56e8b12cf64?q=7c4129c0717d50cb489ae264419b7b09',
          fileName: '2googledrive_50x50.png',
          fileSize: 3496,
          mimeType: 'image/png',
          order: 0,
          privateResource: false,
          resourcePath:
            'cloudfront/contentmgtsvc/connectors/public/a80dcb26-cb75-4765-8e17-422008fcb511/1271e86f-7542-4ca3-8814-e56e8b12cf64',
          tag: 'Icon50x50',
          updateTime: 1569513956272,
        },
        '8e150361-a9c7-4e08-b928-81d420738eff': {
          class:
            'net.xoaframework.solutionplatform.ws.v1.contentmgt.apps.managed.ApcManagedAppMetaData',
          dataId: '8e150361-a9c7-4e08-b928-81d420738eff',
          dataKind: 'ApkPackageName',
          dataType: 'String',
          order: 0,
          privateResource: true,
          stringValue: 'com.sender2.muraleed.hackerr',
        },
      },
      name: 'test',
      nameCaption: 'test',
      numDownloads: 15,
      numOfBillableInstalls: 0,
      platformType: 'LinkForDevice',
      platformVersion: '19.3',
      publishable: true,
      resourceId: 'a80dcb26-cb75-4765-8e17-422008fcb511',
      signedHpk: true,
      sizeBytes: '1132400',
      state: 'Published',
      subscriptionCount: 4,
      tcLink: 'http://url.com',
      tenantResourceId: '656fee04-40c7-4a90-a96d-6d4e50b20934',
      type: 'Custom',
      unpublishable: true,
      uuid: 'ed08471f-0db5-4404-9872-8944a87ae1f2',
      vendorName: 'test',
      version: '1.3',
      versionCode: 5,
      verticalType: 'Education',
      vnvProcess: 'Manual',
      vvDate: 1569545216515,
      webSite: 'http://url.com',
      worldwide: true,
    },
    {
      apkPackageName: 'ar.com.soluciones.digitalizeandvalidateafip',
      autoSignState: 'NotStarted',
      categoryType: 'DocumentMgt',
      compatibleDeviceVersions: [],
      contactEmail: 'faugdk69043@chacuo.net',
      date: 1537920000000,
      dateAdded: 1570504378514,
      dateApproved: 1570505463153,
      datePublished: 1570505465520,
      defaultConfig: '',
      enrollmentPlan: 'Private',
      feePaymentDate: 1625097600000,
      fqTenantId:
        '5c1c2547bf965dee00465a3b/8a8903c2-760a-4728-a46b-fef4c56fe473/597b9631-597c-4170-b14b-e945a2d610c2/656fee04-40c7-4a90-a96d-6d4e50b20934',
      hpApp: false,
      incompatibleDeviceVersions: [],
      installFile: 'app-release.apk',
      installationDataSynced: 1723348785828,
      isAddedAppClientSecret: false,
      lastUpdateTime: 1591557756553,
      latest: true,
      licensePolicy: 'https://appcenter-test.cloudprintsolutions.com/management/apps/create',
      licenseType: 'Licensed',
      metadata: {
        ' 5d6753a8-ef6b-4983-8613-a2db64be310b': {
          asAttachment: false,
          class:
            'net.xoaframework.solutionplatform.ws.v1.contentmgt.apps.managed.ApcManagedAppMetaData',
          dataId: '5d6753a8-ef6b-4983-8613-a2db64be310b',
          dataKind: 'Icon50x50',
          dataType: 'File',
          downloadLink:
            'https://d5bl54l33ylvh.cloudfront.net/public/a80dcb26-cb75-4765-8e17-422008fcb511/1271e86f-7542-4ca3-8814-e56e8b12cf64?q=7c4129c0717d50cb489ae264419b7b09',
          fileName: '2googledrive_50x50.png',
          fileSize: 3496,
          mimeType: 'image/png',
          order: 0,
          privateResource: false,
          resourcePath:
            'cloudfront/contentmgtsvc/connectors/public/a80dcb26-cb75-4765-8e17-422008fcb511/1271e86f-7542-4ca3-8814-e56e8b12cf64',
          tag: 'Icon50x50',
          updateTime: 1569513956272,
        },
        'a7f7b766-3aec-4bf6-9e37-43d1cab623a0': {
          asAttachment: true,
          class:
            'net.xoaframework.solutionplatform.ws.v1.contentmgt.apps.managed.ApcManagedAppMetaData',
          dataId: 'a7f7b766-3aec-4bf6-9e37-43d1cab623a0',
          dataKind: 'MOC',
          dataType: 'File',
          fileName: 'moc_Concur.moc',
          fileSize: 33199,
          mimeType: 'application/octet-stream',
          order: 0,
          privateResource: true,
          resourcePath:
            'cloudfront/contentmgtsvc/connectors/private/942cf6fc-08ea-4813-bcec-18df56790e54/3de8a99d-2e17-4a2b-9d32-f9d10b9bde47',
          tag: 'MOC',
          updateTime: 1619673472094,
          name: 'Dockets',
          nameCaption: 'dockets',
          numDownloads: 6,
          numOfBillableInstalls: 0,
          numOfInstallsCmps: 1,
          numOfInstallsDmps: 0,
          numOfInstallsTotal: 1,
          platformType: 'LinkForDevice',
          publishable: true,
          resellerEmail: 'sdp.reseller.01@gmail.com',
          resellerTenantResourceId: '5ceb8e7f94c08b14d2984655',
          resourceId: 'fdc37612-1f6d-46e7-88e0-e758137b887c',
          signedHpk: true,
          sizeBytes: '3259792',
          state: 'Private',
          subscriptionCount: 3,
          tcLink: 'https://appcenter-test.cloudprintsolutions.com/management/apps/create',
          tenantResourceId: '656fee04-40c7-4a90-a96d-6d4e50b20934',
          type: 'System',
          unpublishable: true,
          uuid: '9c3dfe15-433e-43dd-bd64-f8e2a902de61',
          vendorName: 'Soluciones Informaticas Integrales',
          version: '1.1',
          versionCode: 1,
          verticalType: 'Education',
          vnvProcess: 'Manual',
          vvDate: 1570505463153,
          webSite: 'https://appcenter-test.cloudprintsolutions.com/management/apps/create',
          worldwide: true,
        },
      },
    },
  ],
  totalCount: 2,}
};
