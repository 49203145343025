import React from 'react';
import { CustomCancelButton } from '../Styles';

const Button = (props) => {
  const { label, disable, handleClick , isWex} = props;
  return (
    <CustomCancelButton
      appearance="secondary"
      disabled={disable}
      onClick={() => handleClick(label)}
      isWex = {isWex}
    >
      {label}
    </CustomCancelButton>
  );
};

export default Button;
