import { DeviceDataModel } from '../dataModel/Device';
import { EWSDataModel } from '../dataModel/Ews';
import { NetworkDataModel } from '../dataModel/Network';
import { SecurityDataModel } from '../dataModel/Security';
import { SuppliesDataModel } from '../dataModel/Supplies';
import { SolutionsDataModel } from '../dataModel/Solutions';
import { DigitalSendDataModel } from '../dataModel/DigitalSend';
import {WebServicesDataModel} from '../dataModel/WebServices';
import {WirelessDataModel} from '../dataModel/Wireless';
import { CopierDataModel } from '../dataModel/Copier';
// Thought was to see now if static map has to be created, it should be more like singleton and probably
// attached at level where it's avaialable to both edit and create template.
// Todo:  The same is not acheived, we need to see through as we write template mocks of parsing with controls.
const CategoryControl = () => {
  let instance;

  const getJsonData = () => {
    const CopierCopy = JSON.parse(JSON.stringify(CopierDataModel));
    const DeviceCopy = JSON.parse(JSON.stringify(DeviceDataModel));
    const EWSDataCopy = JSON.parse(JSON.stringify(EWSDataModel));
    const SecurityDataCopy = JSON.parse(JSON.stringify(SecurityDataModel));
    const SuppliesDataCopy = JSON.parse(JSON.stringify(SuppliesDataModel));
    const NetworkDataCopy = JSON.parse(JSON.stringify(NetworkDataModel));
    const SolutionDataCopy = JSON.parse(JSON.stringify(SolutionsDataModel));
    const DigitalSendDataCopy = JSON.parse(JSON.stringify(DigitalSendDataModel));
    const WebServicesDataCopy = JSON.parse(JSON.stringify(WebServicesDataModel));
    const WirelessDataCopy = JSON.parse(JSON.stringify(WirelessDataModel));

    return {
      copier : CopierCopy,
      device: DeviceCopy,
      ews: EWSDataCopy,
      security: SecurityDataCopy,
      supplies: SuppliesDataCopy,
      network: NetworkDataCopy,
      solutions: SolutionDataCopy,
      digitalSend: DigitalSendDataCopy,
      webServices: WebServicesDataCopy,
      wireless: WirelessDataCopy,
    };
  };

  return {
    getJsonData,
    getAsyncJsonData: function () {
      return new Promise((resolve, reject) => {
        if (!instance) {
          instance = getJsonData();
        }
        if (instance === undefined) reject(instance);
        else resolve(instance);
      });
    },
  };
};

export default CategoryControl;
