import { IconWarningAlt } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { shellProps } from '../../../../../src/shellProps';
import { AppConfig } from '../../../../config/App.config';
import { FeatureEffectivePolicyEnum } from '../../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../../config/SubFeatureEnums';
import EditDataContext from '../../../../context/EditDataContext';
import CategoryTreeHelper from '../../../../helper/CategoryTreeHelper';
import TempDataHelper from '../../../../helper/TempDataHelper';
import { setTempRules } from '../../../../store/deviceConfig/action';
import { useStoreState } from '../../../../store/useStoreState';
import SettingsList from '../../Settings/List/SettingsList';
import FeatureHeader from '../Header/FeatureHeader';
import * as Style from './Styles';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const FeatureContent = (props) => {
  const { featuresData, entitlements, isWex } = props;
  const { getPolicyAttributeDescription } = CategoryTreeHelper();
  const dispatch = useDispatch();
  const { deviceConfigDataState } = useStoreState();
  const { rulesData, rulesEffect } = deviceConfigDataState;
  const { getEditViewData } = TempDataHelper();
  const { setTempFeatures, setTempSettings, setEditViewData } = useContext(EditDataContext);
  const { showMultipleConfigUi } = useContext(shellProps);

  const accordionItems = useMemo(() => {
    const items = [];
    const category = getPolicyAttributeDescription(featuresData.id);
    const entitlementCondition = entitlements?.includes('ws-hp.com/smcloud-advanced');
    const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
    const enableWarning =
      (AppConfig.entitlementFeature.featureFlag &&
        featuresData.entitlementId &&
        ldAdvancedEntitlement &&
        !entitlementCondition) ||
      (AppConfig.controlledPolicy.featureFlag && featuresData.effectivePolicy);

    items.push({
      id: `edit-${featuresData.id}-body`,
      class: 'edit-feature-accordion-body',
      expanded: true,
      content: <SettingsList featuresData={featuresData} entitlements={entitlements} isWex={isWex}/>,
      header: {
        centralArea: (
          <FeatureHeader
            category={category}
            featuresId={featuresData.id}
            featuresData={featuresData}
            isWex={isWex}
          />
        ),
        endArea: enableWarning ? (
          <Style.IconWarningAltStyle
            filled={true}
            size={24}
            data-testid={'id-edit-modal-feature-header-warning-icon'}
          />
        ) : null,
      },
    });

    return items;
  }, [featuresData]);

  useEffect(() => {
    const controlledPolicyFeatures = {
      ...featuresData,
      subfeatures: featuresData.subfeatures
        .map((data) => {
          return data;
        })
        .filter((data) => data.accessMode !== SubFeatureAccessModeEnum.READWRITE_DISABLE),
    };

    const finalFeaturesData =
      featuresData.effectivePolicy &&
      featuresData.effectivePolicy === FeatureEffectivePolicyEnum.controlledPolicy
        ? controlledPolicyFeatures
        : featuresData;

    setTempFeatures([finalFeaturesData]);
    setTempSettings(finalFeaturesData.subfeatures);
    setEditViewData(getEditViewData(rulesEffect, [finalFeaturesData], showMultipleConfigUi));
    dispatch(setTempRules(rulesData));
  }, []);

  return (
    <Fragment>
      {accordionItems.length > 0 && (
        <Style.EditFeatureListWrap>
          <Style.EditFeatureList>
            <Style.EditFeatureListItem>
              <Style.EditFeatureAccordion
                items={accordionItems}
                behavior={'singleExpand'}
                id={`edit-${featuresData.id}`}
                className={'edit-feature-accordion'}
              />
            </Style.EditFeatureListItem>
          </Style.EditFeatureList>
        </Style.EditFeatureListWrap>
      )}
    </Fragment>
  );
};

export default FeatureContent;
