import React from 'react';
import { AppTitle, TextAlignCenter,WarningIcon } from './Styles';
import { Avatar, IconWarningAlt } from '@veneer/core';
import LocalizeHelper from '../../../helper/LocalizeHelper';

const AppDeploymentWithoutApps = (props) => {
  const { getCustomizableComponentStrings } = LocalizeHelper();
  return <div>
      <TextAlignCenter>
      <WarningIcon data-testid="warningIcon">
        <IconWarningAlt size={24} />
      </WarningIcon>
        <AppTitle>{getCustomizableComponentStrings('noAppsInstalledInEditView')}</AppTitle>
        <AppTitle>{getCustomizableComponentStrings('installAppsByPoliciesInEditView')}</AppTitle>
        <AppTitle>{getCustomizableComponentStrings('goToNavigationForAppInstallation')}</AppTitle>
      </TextAlignCenter>
    </div>;
}

export default AppDeploymentWithoutApps;
