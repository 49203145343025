import { store } from '../../../src/utils/constants';

const initialState = {
  settingsList: [],
  allowedSettingsList: [],
  selectedSetting: '',
  showModal: false,
};

const configureDevicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.configureDevices.SETTINGS_LIST:
      return {
        ...state,
        settingsList: action.payload,
      };
    case store.configureDevices.ALLOWED_SETTINGS_LIST:
      return {
        ...state,
        allowedSettingsList: action.payload,
      };
    case store.configureDevices.SELECTED_SETTING:
      return {
        ...state,
        selectedSetting: action.payload,
      };

    default:
      return state;
  }
};

export default configureDevicesReducer;
