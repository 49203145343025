import { categoryStore } from '../../utils/constants';

export const savePluginName = (_payload) => {
  return {
    type: categoryStore.config.SAVE_PLUGIN_NAME,
    payload: _payload,
  };
};

export const setRulesData = (_payload) => {
  return {
    type: categoryStore.config.SET_RULES_DATA,
    payload: _payload,
  };
};

export const setRulesUpdate = (_payload) => {
  return {
    type: categoryStore.config.SET_RULES_DATA_UPDATE,
    payload: _payload,
  };
};

export const setRulesEffectData = (_payload) => {
  return {
    type: categoryStore.config.GET_RULES_EFFECT,
    payload: _payload,
  };
};

export const setPolicyData = (_payload) => {
  return {
    type: categoryStore.config.SET_POLICY_DATA,
    payload: _payload,
  };
};

export const setFeaturesData = (_payload) => {
  return {
    type: categoryStore.config.SET_FEATURES_DATA,
    payload: _payload,
  };
};

export const setSettingsData = (_payload) => {
  return {
    type: categoryStore.config.SET_SETTINGS_DATA,
    payload: _payload,
  };
};

export const setTempFeature = (_payload) => {
  return {
    type: categoryStore.config.SET_TEMP_FEATURE,
    payload: _payload,
  };
};

export const setTempFeatureUpdate = (_payload) => {
  return {
    type: categoryStore.config.SET_TEMP_FEATURE_UPDATE,
    payload: _payload,
  };
};

export const setTempRules = (_payload) => {
  return {
    type: categoryStore.config.SET_TEMP_RULES,
    payload: _payload,
  };
};

export const setTempRulesUpdate = (_payload) => {
  return {
    type: categoryStore.config.SET_TEMP_RULES_UPDATE,
    payload: _payload,
  };
};

export const setTempRulesEffect = (_payload) => {
  return {
    type: categoryStore.config.SET_TEMP_RULES_EFFECT,
    payload: _payload,
  };
};

export const setControlledPolicyRequest = () => {
  return {
    type: categoryStore.config.SET_CONTROLLED_POLICY_REQUEST,
  };
};

export const setControlledPolicyData = (_payload) => {
  return {
    type: categoryStore.config.SET_CONTROLLED_POLICY,
    payload: _payload,
  };
};

export const setControlledPolicyError = (_payload) => {
  return {
    type: categoryStore.config.SET_CONTROLLED_POLICY_ERROR,
    payload: _payload,
  };
};

export const resetInitialData = () => {
  return {
    type: categoryStore.config.RESET_INITIAL_DATA,
  };
};
