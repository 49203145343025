import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext, useEffect } from 'react';
import { CategoryEnum } from '../../../../config/CategoryEnums';
import LaunchDarklyVariables from '../../../../config/LaunchDarklyVariables';
import EditDataContext from '../../../../context/EditDataContext';
import CategoryTreeHelper from '../../../../helper/CategoryTreeHelper';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { Loader } from '../../../CommonExtended/Loader/Loader';
import { NoFilterData } from '../../../CommonExtended/NoFilterData/NoFilterData';
import FeaturesList from '../../Feature/List/FeatureList';
import * as Style from './Styles';

const CategoryList = (props) => {
  const { selectedData,isWex } = props;
  const { selectedCategory, setOffset, checkboxFeature, searchValue, checkedFeaturesToggle } =
    useContext(EditDataContext);
  const { getDeviceCategoryLocString, getDeviceFeatureLocString } = LocalizeHelper();
  const searchLowerCase = searchValue.toLowerCase();
  const [deviceConfigFilterData, setDeviceConfigFilterData] = React.useState([]);
  const { filterPolicyAttributes } = CategoryTreeHelper();
  const { getLaunchDarklyFeatures } = LaunchDarklyVariables(props);

  const [accordionData, setAccordionData] = React.useState([]);
  const [updatedAccordionData, setUpdatedAccordionData] = React.useState([]);
  const [isExpand, setIsExpand] = React.useState(false);
  const [flag, setFlag] = React.useState(false);

  // Dark Launchly responses are stored in this state
  const [LDValue, setLDValue] = React.useState([]);

  const handleScroll = (event) => {
    setOffset(event.currentTarget.scrollTop);
  };

  useEffect(() => {
    const LaunchDarklyFeatures = getLaunchDarklyFeatures() ? getLaunchDarklyFeatures() : []

    setLDValue(LaunchDarklyFeatures);

    const filteredLaunchDarklyValues = checkboxFeature.filter((i) =>
      LaunchDarklyFeatures.includes(i.id),
    );

    const chekedFeatures = filteredLaunchDarklyValues.filter(
      (_checkboxFeature) => _checkboxFeature.inputDisable === false,
    );

    const filteredcheckedFeatures = checkedFeaturesToggle
      ? chekedFeatures
      : filteredLaunchDarklyValues;

    const selectedCategoryFeatures = filteredcheckedFeatures.filter(
      (_filteredcheckedFeature) => _filteredcheckedFeature.categoryName === selectedCategory[0],
    );

    const selectedSubCategoryFeatures = filterPolicyAttributes(
      selectedCategory,
      filteredcheckedFeatures,
    );

    const selectedFeatures =
      selectedCategory.length == 1 ? selectedCategoryFeatures : selectedSubCategoryFeatures;

    const filteredSelectedFeatures = selectedCategory.length
      ? selectedFeatures
      : filteredcheckedFeatures;

    const compare = searchLowerCase.length
      ? (_feature) => {
        const name = getDeviceFeatureLocString(_feature.id, 'name');
        return name.toLowerCase().includes(searchLowerCase);
      }
      : () => true;

    const filteredSearchedFeatures = filteredSelectedFeatures
      .map((_filteredSelectedFeature) => {
        if (compare(_filteredSelectedFeature)) {
          return _filteredSelectedFeature;
        }
      })
      .filter(function (_resFilteredSelectedFeature) {
        return _resFilteredSelectedFeature !== undefined;
      });

    setDeviceConfigFilterData(filteredSearchedFeatures);
  }, [checkboxFeature, searchValue, selectedCategory, checkedFeaturesToggle, useFlags()]);

  useEffect(() => {
    if (searchValue.length || checkedFeaturesToggle || selectedCategory.length) {
      setIsExpand(true);
      setFlag(false);
      setUpdatedAccordionData([]);
    } else {
      setIsExpand(false);
      setFlag(false);
      setUpdatedAccordionData([]);
    }
  }, [searchValue, checkedFeaturesToggle, selectedCategory]);

  const accordionContent = (featureListData, isExpanded, categoryKey) => {
    return {
      content: <FeaturesList features={featureListData} selectedData={selectedData} isWex={isWex} />,
      expanded: isExpanded,
      header: {
        centralArea: (
          <Style.EditCategoryTitle data-testid={'id-batch-view-edit-category-title'}>
            {getDeviceCategoryLocString(categoryKey)}
          </Style.EditCategoryTitle>
        ),
      },
      id: `edit-batch-${categoryKey}-body`,
      categoryKey,
    };
  };

  useEffect(() => {
    const accordionContentData = Object.keys(CategoryEnum)
      .map((oneKey, i) => {
        const categoryKey = CategoryEnum[oneKey];
        const featureListData = deviceConfigFilterData.filter(
          (data) => data.categoryName === categoryKey && data.categoryName !== 'solutions',
        );

        const filteredData =
          updatedAccordionData.length &&
          updatedAccordionData?.filter((data) => data.categoryKey === categoryKey)[0];

        const expandVal = updatedAccordionData.length ? filteredData?.expanded : isExpand;

        const accordionItems = featureListData.length
          ? accordionContent(featureListData, expandVal, categoryKey)
          : null;
        return accordionItems;
      })
      .filter(function (_res) {
        return _res !== null;
      });

    setAccordionData(accordionContentData);
  }, [deviceConfigFilterData]);

  const handleExpand = (event, index, item) => {
    setFlag(true);
    const updatedData = [...accordionData];
    updatedData.filter((data) => data.categoryKey === item.categoryKey)[0].expanded = true;
    setAccordionData(updatedData);

    const accordionItems = updatedData.map((xyz) => {
      return { categoryKey: xyz.categoryKey, expanded: xyz.expanded };
    });

    if (updatedAccordionData.some((data) => data.categoryKey === item.categoryKey)) {
      const updatedAccordionDataCopy = updatedAccordionData;
      updatedAccordionDataCopy.find((data) => data.categoryKey === item.categoryKey).expanded =
        true;
      setUpdatedAccordionData(updatedAccordionDataCopy);
    } else {
      setUpdatedAccordionData(accordionItems);
    }
  };

  const handleCollapse = (event, index, item) => {
    setFlag(true);
    const updatedData = [...accordionData];
    updatedData.filter((data) => data.categoryKey === item.categoryKey)[0].expanded = false;
    setAccordionData(updatedData);

    const accordionItems = updatedData.map((xyz) => {
      return { categoryKey: xyz.categoryKey, expanded: xyz.expanded };
    });

    if (updatedAccordionData.some((data) => data.categoryKey === item.categoryKey)) {
      const updatedAccordionDataCopy = updatedAccordionData;
      updatedAccordionDataCopy.find((data) => data.categoryKey === item.categoryKey).expanded =
        false;
      setUpdatedAccordionData(updatedAccordionDataCopy);
    } else {
      setUpdatedAccordionData(accordionItems);
    }
  };
  return (
    <Style.EditCategoryCard onScroll={handleScroll}>
      {LDValue.length ? (
        deviceConfigFilterData.length ? (
          <Fragment>
            {Object.keys(CategoryEnum).map((oneKey, i) => {
              const categoryKey = CategoryEnum[oneKey];
              const featureListData = flag
                ? accordionData.filter((data) => data.categoryKey === categoryKey && data.categoryName !== 'solutions')
                : deviceConfigFilterData.filter((data) => data.categoryName === categoryKey && data.categoryName !== 'solutions');
                // Alternate Logic after BatchView refactor

              const accordionContentItems = [
                accordionContent(featureListData, isExpand, categoryKey),
              ];

              return featureListData.length ? (
                <Fragment>
                  <Style.EditCategoryAccordion
                    items={flag ? featureListData : accordionContentItems}
                    id={`edit-batch-${categoryKey}`}
                    className={'edit-batch-category-accordion'}
                    onCollapse={handleCollapse}
                    onExpand={handleExpand}
                  />
                </Fragment>
              ) : null;
            })}
          </Fragment>
        ) : (
          <NoFilterData checkedFeaturesToggle={checkedFeaturesToggle} searchValue={searchValue} />
        )
      ) : (
        <Loader />
      )}
    </Style.EditCategoryCard>
  );
};
export default CategoryList;
