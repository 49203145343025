import HPEnterprise_MF_EN from '@manageability/constraints/HPEnterprise_MF-EN.json';
import HPEnterprise_MF_EN_1 from '@manageability/constraints/HPEnterprise_MF-EN_1.json';
import HPEnterprise_SF_EN from '@manageability/constraints/HPEnterprise_SF-EN.json';
import HPEnterprise_SF_EN_1 from '@manageability/constraints/HPEnterprise_SF-EN_1.json';
import HPLaserJetPro_MF_DI from '@manageability/constraints/HPLaserJetPro_MF-DI.json';
import HPLaserJetPro_MF_DI_1 from '@manageability/constraints/HPLaserJetPro_MF-DI_1.json';
import HPLaserJetPro_SF_DI from '@manageability/constraints/HPLaserJetPro_SF-DI.json';
import HPLaserJetPro_SF_DI_1 from '@manageability/constraints/HPLaserJetPro_SF-DI_1.json';
import HPOfficeJetPro_MF_DI from '@manageability/constraints/HPOfficeJetPro_MF-DI.json';
import HPUnifiedDesignjet_MF_DI from '@manageability/constraints/HP_Unified_DesignJet_MF-DI.json';
import HPUnifiedDesignjet_SF_DI from '@manageability/constraints/HP_Unified_DesignJet_SF-DI.json';
import HPUnifiedPageWidePro_OfficeJetPro_MF_DI from '@manageability/constraints/HP_Unified_PageWidePro_OfficeJetPro_MF-DI.json';
import HPUnifiedPageWidePro_OfficeJetPro_SF_DI from '@manageability/constraints/HP_Unified_PageWidePro_OfficeJetPro_SF-DI.json';
import HPUnifiedLaserJet_MF_DI from '@manageability/constraints/HP_Unified_LaserJet_MF-DI.json';
import HPunifiedLaserJet_SF_DI from '@manageability/constraints/HP_Unified_LaserJet_SF-DI.json';
import DefaultPolicyConstraints from '@manageability/constraints/policy.json';

const ConstraintsBatchMode = () => {
  const getConstraintsData = () => {
    const batchData = [];
    batchData.push({ 'HPOfficeJetPro_MF-DI': HPOfficeJetPro_MF_DI });
    batchData.push({ 'HPEnterprise_MF-EN': HPEnterprise_MF_EN });
    batchData.push({ 'HPEnterprise_SF-EN': HPEnterprise_SF_EN });
    batchData.push({ 'HPLaserJetPro_MF-DI': HPLaserJetPro_MF_DI });
    batchData.push({ 'HPLaserJetPro_SF-DI': HPLaserJetPro_SF_DI });
    batchData.push({ 'HPLaserJetPro_MF-DI_1': HPLaserJetPro_MF_DI_1 });
    batchData.push({ 'HPLaserJetPro_SF-DI_1': HPLaserJetPro_SF_DI_1 });
    batchData.push({ 'HPEnterprise_MF-EN_1': HPEnterprise_MF_EN_1 });
    batchData.push({ 'HPEnterprise_SF-EN_1': HPEnterprise_SF_EN_1 });
    batchData.push({ 'HP_Unified_DesignJet_MF-DI': HPUnifiedDesignjet_MF_DI });
    batchData.push({ 'HP_Unified_DesignJet_SF-DI': HPUnifiedDesignjet_SF_DI });
    batchData.push({
      'HP_Unified_PageWidePro_OfficeJetPro_MF-DI': HPUnifiedPageWidePro_OfficeJetPro_MF_DI,
    });
    batchData.push({
      'HP_Unified_PageWidePro_OfficeJetPro_SF-DI': HPUnifiedPageWidePro_OfficeJetPro_SF_DI,
    });
    batchData.push({ 'HP_Unified_LaserJet_MF-DI': HPUnifiedLaserJet_MF_DI });
    batchData.push({ 'HP_Unified_LaserJet_SF-DI': HPunifiedLaserJet_SF_DI });
    batchData.push({ DefaultPolicyConstraints: DefaultPolicyConstraints });
    return batchData;
  };

  const getPluginConstraints = (pluginName) => {
    let constraints = [];
    getConstraintsData().filter((t) => {
      if (Object.keys(t)[0] === pluginName) {
        constraints = t;
      }
    });
    return constraints[pluginName];
  };

  return {
    getConstraintsData,
    getPluginConstraints,
  };
};

export default ConstraintsBatchMode;
