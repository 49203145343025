export const LdapSigninSetupHelper = (subFeatureId, propsData) => {

    const transformArray = (inputArray)=> {
      return inputArray.map(item => ({ ldapBindRoots: item }));
    }
  
    const tableData = transformArray(propsData)
  
    return { LdapSigninData: tableData };
  };
  