import { Button } from '@veneer/core';
import styled from 'styled-components';
import { FlexRowSpaceBetween } from '../../GlobalStyles/GlobalStyles';

export const CustomButtonWrap = styled(FlexRowSpaceBetween)`
  width: 100%;
`;

export const CustomCancelButton = styled(Button)`
&:hover {
  background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')}!important;
}
  ${(props) =>
   props.isWex &&
   `width: 92px;
    height: 36px ;
    padding: 8px 20px 8px 20px;
    gap: 8px;
    border-radius: 360px !important;
    border: 1px;
    opacity: 0px;`
  };
  font-family: 'Forma DJR Micro' !important;
  letter-spacing: 0.02em;
  min-width: 92px !important;

`;

export const CustomSaveButton = styled(Button)`
${(props) =>
  props.isWex &&
  `width: 92px;
   height: 36px ;
   padding: 8px 20px 8px 20px;
   gap: 8px;
   border-radius: 360px !important;
   border: 1px;
   opacity: 0px;`
  }
  font-family: 'Forma DJR Micro' !important;
  letter-spacing: 0.02em;
  min-width: 73px !important;

`;
