import { useI18n } from '@jarvis/react-portal-addons';
import { IconHelp } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { EntitlementFeature } from '../../../../../src/components/EntitlementFeature/EntitlementFeature';
import { AppConfig } from '../../../../../src/config/App.config';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import InfoModal from '../../../CommonExtended/InfoModal/InfoModal';
import * as Style from './Styles';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const FeatureHeader = (props) => {
  const { category, featuresId, featuresData, isWex } = props;
  const { t } = useI18n();
  const { getDeviceFeatureLocString, getDeviceFeatureModalString, getDeviceCategoryLocString } =
    LocalizeHelper();

  const name = getDeviceFeatureLocString(featuresId, 'name');
  const description = getDeviceFeatureModalString(featuresId, 'description');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
  const categoryName = category.map((x) => getDeviceCategoryLocString(x)).join(' > ');
  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  return (
    <Style.EditFeatureHeaderWrap>
      <Style.EditFeatureHeader>
        <Style.EditFeatureTitle data-testid={'id-edit-modal-feature-title'}>
          {name}
        </Style.EditFeatureTitle>
        <Style.EditFeatureIcon>
          <Style.InfoButton
            onClick={() => {
              handleInfoModal();
            }}
            appearance="secondary"
            leadingIcon={<IconHelp color="colorHpBlue7" size={20} />}
            data-testid={'id-edit-modal-feature-info-icon'}
            isWex={isWex}
          />
          {AppConfig.entitlementFeature.featureFlag &&
          featuresData.entitlementId &&
          ldAdvancedEntitlement ? (
            <EntitlementFeature entitlementId={ldAdvancedEntitlement} styling={'readView'} />
          ) : null}
          <InfoModal
            show={showInfoModal}
            handleClose={handleInfoModal}
            title={name}
            content={description}
            data-testid={'id-edit-modal-feature-info-modal'}
          />
        </Style.EditFeatureIcon>
      </Style.EditFeatureHeader>
    </Style.EditFeatureHeaderWrap>
  );
};

export default FeatureHeader;
