import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const SolutionsDataModel = [
  {
    id: `${FeatureIdEnum.Web_App_Deployment}`,
    configurable: true,
    visible: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.App_Deployment_WorkPath_Enablement}`,
        attribute: `${FleetSvcPolicyAttributeId.App_Deployment}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'workpath_enablement_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.App_Deployment_WorkPath_Enablement}`,
        resource: `${ConstraintsResourceEnum.App_Deployment_WorkPath_Enablement}`,
        settingsName: `${ConstraintsSettingsNameEnum.App_Deployment_WorkPath_Enablement}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SolutionManagerConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.App_Deployment_WorkPath_Enablement}`,
      },
      {
        id: `${SubfeatureIdEnum.App_Deployment_AppInstallation}`,
        attribute: `${FleetSvcPolicyAttributeId.App_Deployment}.apps`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'apps_installation_configuration_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.App_Deployment_AppInstallation}`,
        resource: `${ConstraintsResourceEnum.App_Deployment_AppInstallation}`,
        settingsName: `${ConstraintsSettingsNameEnum.App_Deployment_AppInstallation}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
    ],
  },
];
