import styled from 'styled-components';
import { styleAttributes4, styleAttributes9 } from '../../GlobalStyles/GlobalStyles';

export const NoFilterDataCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  background: #ffffff;
  border-radius: 12px;
`;
export const WarningIconWrap = styled.div``;

export const WarningIconBorder = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  border-radius: 100%;
`;

export const NoFilterDataDescWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const NoFilterDataDesc1 = styled.p`
  ${styleAttributes4}
  text-align: center;
  margin-bottom: 8px;
`;

export const NoFilterDataDesc2 = styled.a`
  ${styleAttributes9}
  text-align: center;
  color: #027aae;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
