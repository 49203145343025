import { SubFeatureAccessModeEnum, SubFeatureControlTypeEnum } from '../config/SubFeatureEnums';
import ConstraintsRuleParser from '../dataParser/ConstraintsRuleParser';

const TempDataHelper = () => {
  const { tempConditionCheck, setRuleEffect } = ConstraintsRuleParser();

  const getUpdateTempData = (
    currentSetting,
    tempFeatures,
    tempRulesData,
    editViewData,
    isMultipleConfig,
  ) => {
    const updatedTempFeature = isMultipleConfig
      ? tempFeatures.map((_resTempFeature) => {
          return _resTempFeature.settingsName === currentSetting.settingsName
            ? { ..._resTempFeature, fleetValue: currentSetting.fleetValue, tempCheck: true }
            : _resTempFeature;
        })
      : tempFeatures.map((_resTempFeatures) => {
          return {
            ..._resTempFeatures,
            subfeatures: _resTempFeatures.subfeatures.map((_resSubfeature) =>
              _resSubfeature.settingsName === currentSetting.settingsName
                ? { ..._resSubfeature, fleetValue: currentSetting.fleetValue, tempCheck: true }
                : _resSubfeature,
            ),
          };
        });

    const result = isMultipleConfig
      ? updatedTempFeature.find(
          (_resUpdatedTempFeature) =>
            _resUpdatedTempFeature.settingsName === currentSetting.settingsName,
        )
      : updatedTempFeature.length &&
        updatedTempFeature[0].subfeatures.find(
          (_resSubfeatures) => _resSubfeatures.settingsName === currentSetting.settingsName,
        );

    const tempRulesCondition = tempConditionCheck(tempRulesData, result);

    const updatedTempRulesData = tempRulesCondition
      ? tempRulesData.map((_resTempRulesData) => {
          return {
            ..._resTempRulesData,
            dependsOn: _resTempRulesData.dependsOn.map((_resDependsOn) =>
              _resDependsOn.settingsName === tempRulesCondition.settingsName
                ? { ..._resDependsOn, valueCheck: tempRulesCondition.valueCheck }
                : _resDependsOn,
            ),
          };
        })
      : tempRulesData;

    const updatedTempRulesEffectData = setRuleEffect(updatedTempRulesData);

    const updatedEditViewData = editViewData.reduce(function (filtered, element) {
      const rulesEffect = updatedTempRulesEffectData.filter(
        (_resUpdatedTempRulesEffectData) =>
          _resUpdatedTempRulesEffectData.currentSettingName === element.settingsName,
      );

      let settingsValue;
      let settingsConfirmValue;
      let settingError;
      let settingConfirmError = true;

      if (element.name === currentSetting.attribute) {
        settingsValue = currentSetting.fleetValue;
        settingsConfirmValue = currentSetting.confirmPasswordValue;
        settingError = currentSetting.error;
      } else {
        settingsValue = element.value;
        settingsConfirmValue = element.confirmValue;
        settingError = element.error;
      }

      if (
        settingsValue !== '' &&
        settingsValue !== null &&
        settingsConfirmValue !== '' &&
        settingsConfirmValue !== null
      ) {
        settingConfirmError = settingsValue === settingsConfirmValue ? false : true;
      }

      const newFleetData = {
        name: element.name,
        value: settingsValue,
        error: settingError,
        required: element.required,
        settingsName: element.settingsName,
        rulesEffect: rulesEffect.length ? true : false,
        isFmsPayload: element.isFmsPayload,
        ...(element.confirmpassword && {
          confirmValue: settingsConfirmValue,
          confirmpassword: element.confirmpassword,
          confirmpasswordError: settingConfirmError,
        }),

        ...(isMultipleConfig && { isChecked: element.isChecked }),
      };
      filtered.push(newFleetData);
      return filtered;
    }, []);

    return {
      updatedTempFeature,
      updatedTempRulesData,
      updatedTempRulesEffectData,
      updatedEditViewData,
    };
  };

  const getEditViewData = (rulesPayload, payload, isMultipleConfig) => {
    const payloadData = isMultipleConfig ? payload : payload[0].subfeatures;
    const result = payload.length
      ? payloadData.reduce(function (filtered, element) {
          let newFleetData;
          if (element.accessMode !== SubFeatureAccessModeEnum.READONLY) {
            const rulesEffect = rulesPayload.filter(
              (_resRulesPayload) => _resRulesPayload.currentSettingName === element.settingsName,
            );
            if (element.attribute !== 'snmp-v1-enable.check') {
              newFleetData = {
                name: element.attribute,
                value:
                  element.type !== `${SubFeatureControlTypeEnum.Password}` &&
                  element.type !== `${SubFeatureControlTypeEnum.File}`
                    ? element.fleetValue
                    : '',
                error: false,
                required: element.required,
                settingsName: element.settingsName,
                rulesEffect: rulesEffect.length ? true : false,
                isFmsPayload: element.isFmsPayload,
                ...(element.confirmpassword && {
                  confirmValue: '',
                  confirmpassword: true,
                  confirmpasswordError: true,
                }),
                ...(isMultipleConfig && { isChecked: false }),
              };
            }
          }

          if (newFleetData) {
            filtered.push(newFleetData);
          }

          return filtered;
        }, [])
      : payload;

    return result;
  };

  const getRulesEffect = (tempRulesEffect, settingName) => {
    const found = tempRulesEffect.find(
      (_resTempRulesEffect) => _resTempRulesEffect.currentSettingName === settingName,
    );
    return found ? found : [];
  };

  const getUpdatedCheckedFeatures = (checkedFeature, editViewData, checkedValue) => {
    const tempCheckedSettings = checkedFeature.map((_resCheckedFeature) => {
      return editViewData.reduce(function (filtered, element) {
        if (element.name === _resCheckedFeature.attribute) {
          const newData = {
            ...element,
            isChecked: checkedValue,
          };
          filtered.push(newData);
        }
        return filtered;
      }, []);
    });

    const mergeByProperty = (target, source, prop) => {
      source.forEach((sourceElement) => {
        let targetElement = target.find((targetElement) => {
          return sourceElement[prop] === targetElement[prop];
        });
        targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      });
    };

    const target = editViewData;
    const source = tempCheckedSettings.flat(1);
    mergeByProperty(target, source, 'name');

    return target;
  };

  return {
    getUpdateTempData,
    getRulesEffect,
    getEditViewData,
    getUpdatedCheckedFeatures,
  };
};

export default TempDataHelper;
