import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useContext } from 'react';
import CustomFeatureSettingsList from '../../../../components/EditView/Settings/List/CustomFeatureSettingsList';
import { AppConfig } from '../../../../config/App.config';
import { SubFeatureCustomModeEnum } from '../../../../config/SubFeatureEnums';
import EditDataContext from '../../../../context/EditDataContext';
import { BatchSettingsGenerator } from '../../../../generators/BatchSettingsGenerator';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { useStoreState } from '../../../../store/useStoreState';
import { AdvanceFeatureWarningMessage } from '../../../CommonExtended/AdvanceFeatureWarningMessage/AdvanceFeatureWarningMessage';
import { ControlledPolicy } from '../../../CommonExtended/ControlledPolicy/ControlledPolicy';
import { InfoNotification } from '../../../CommonExtended/InfoNotification/InfoNotification';
import { Loader } from '../../../CommonExtended/Loader/Loader';
import Notification from '../../../CommonExtended/Notification/Notification';
import Settings from '../Settings';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';

const SettingsList = (props) => {
  const { featuresData, selectedData ,isWex} = props;
  const {
    controlledPolicy,
    notSupportedDevices,
    policyCheck,
    entitlementId,
    effectivePolicy,
    warningNotification,
  } = featuresData;

  const { featureFlag } = AppConfig.controlledPolicy;
  const { entitlementFeature, notSupportedFeature } = AppConfig;
  const { generateDeviceSettings } = BatchSettingsGenerator();
  const { checkboxFeature, editViewData } = useContext(EditDataContext);
  const { deviceConfigDataState } = useStoreState();
  const { getCommonStrings, getDeviceFeatureModalString } = LocalizeHelper();
  const { loading } = deviceConfigDataState;

  const isChecked = checkboxFeature.filter((data) => data.id === featuresData.id)[0].inputDisable;
  const effectivePolicyData = effectivePolicy;
  const notifyMessage = getDeviceFeatureModalString(featuresData.id, 'notifyMessage');
  const entitlementCondition = selectedData?.map((data) =>
    data.solutions?.includes('ws-hp.com/smcloud-advanced'),
  );
  const entitlementArray = selectedData?.map((data) => data.solutions);
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
  const hasControlledPolicy = featureFlag && policyCheck;
  const hasControlledPolicyCheck = featureFlag && policyCheck && controlledPolicy?.length;

  const hasEntitlementFeature = entitlementFeature.featureFlag;
  const hasNotEntitlement =
    entitlementId && ldAdvancedEntitlement && entitlementCondition?.includes(false);

  const advanceFeatureWarningMessage = hasNotEntitlement ? <AdvanceFeatureWarningMessage /> : null;

  const controlledPolicyCheck = !hasNotEntitlement ? (
    hasControlledPolicyCheck ? (
      <ControlledPolicy policyName={controlledPolicy} />
    ) : (
      <InfoNotification
        title={getCommonStrings('relatedSettingsTitle')}
        message={getCommonStrings('relatedSettingsSubtitle')}
      />
    )
  ) : null;

  const ldapSetupCredential = editViewData?.find(
    (item) => item.name === 'ldap-setup.credential',
  )?.value;

  const getSettings = () => {
    return featuresData.subfeatures?.map((subfeature) => {
      return (
        <>
          <Settings
            deviceSettingForms={generateDeviceSettings(
              featuresData,
              entitlementArray,
              subfeature,
              featuresData.id,
              effectivePolicyData,
              ldapSetupCredential,
              isChecked,
              isWex
            )}
            accessMode={subfeature.accessMode}
            indent={subfeature.indent}
          />
        </>
      );
    });
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          {hasEntitlementFeature ? advanceFeatureWarningMessage : null}
          {hasControlledPolicy ? controlledPolicyCheck : null}
          {warningNotification ? <InfoNotification message={notifyMessage} /> : null}
          {notSupportedFeature.featureFlag && notSupportedDevices?.length ? (
            <Notification notSupportedDevices={notSupportedDevices} />
          ) : null}
          {/* {getSettings()} */}
          {featuresData.customizable === SubFeatureCustomModeEnum.WRITEVIEW ? (
            <CustomFeatureSettingsList
              id={featuresData.id}
              subfeaturesdata={featuresData.subfeatures}
              isControlledPolicy={effectivePolicy}
              isChecked={isChecked}
            />
          ) : (
            getSettings()
          )}
        </>
      )}
    </Fragment>
  );
};
export default SettingsList;
