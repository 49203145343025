import React, { Fragment, useContext, useEffect, useState } from 'react';
import EditDataContext from '../../../context/EditDataContext';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import LdapBindSearchRootTable from './Table/LdapBindSearchRootTable';

const LdapBindSearchRoot = (props) => {
  const { device_settings,isWex } = props;
  const [serverData, setServerData] = useState([]);
  const { onUpdateTempData } = useContext(EditDataContext);

  const [totalSelectedData, setTotalSelectedData] = useState(0);
  const [selectedData, setSelectedData] = useState(0);
  const fleetValueArray = Array.isArray(device_settings?.fleetValue)
    ? device_settings?.fleetValue
    : [];

  const data = fleetValueArray.map((_data, index) => {
    return {
      root_name: _data,
      uid: index,
      rowConfig: {
        selected: false,
      },
    };
  });

  const [serverTableData, setServerTableData] = useState(data);

  useEffect(() => {
    const ldapRootValues = serverTableData.map((_data) => {
      return _data.root_name;
    });

    onUpdateTempData({
      settingsName: device_settings.settingsName,
      fleetValue: ldapRootValues,
      attribute: device_settings.attribute,
      // error: !ldapRootValues.length,
      error: false,
    });
  }, [serverTableData]);

  return (
    <Fragment>
      <ButtonGroup
        {...props}
        serverTableData={serverTableData}
        setServerTableData={setServerTableData}
        totalSelectedData={totalSelectedData}
        selectedData={selectedData}
      />
      <LdapBindSearchRootTable
        {...props}
        serverData={serverData}
        tableDatas={serverTableData}
        setServerTableData={setServerTableData}
        setTotalSelectedData={setTotalSelectedData}
        setSelectedCount={setTotalSelectedData}
        setSelectedData={setSelectedData}
      />
      {/* <>{!serverTableData.length ? 'Please Add Data' : null}</> */}
    </Fragment>
  );
};

export default LdapBindSearchRoot;
