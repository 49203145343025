import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import EditDataContext from '../../context/EditDataContext';
import FilePickerHelper from '../../helper/FilePickerHelper';
import LocalizeHelper from '../../helper/LocalizeHelper';
import { colorRed6 } from '@veneer/tokens/dist/tokens/tokens';
import * as x509 from '@peculiar/x509';
import { Button, IconMinusCircle } from '@veneer/core';
import { ConstraintsEffectEnum } from '../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum, FeatureIdEnum } from '../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../config/SubFeatureEnums';
import TempDataHelper from '../../helper/TempDataHelper';
import { InputTitle } from '../CommonExtended/InputTitle/InputTitle';
import {
  FileNameLabel,
  FilePickerContainer,
  FilePickerError,
  FilePickerErrorMessage,
  FilePickerInput,
  StyledButton
} from './Styles';

const FilePickerControl = (props) => {
  const { device_settings, featuresId, isChecked, isControlledPolicy , isWex} = props;
  const { getDeviceSettingsLocString, getCommonStrings } = LocalizeHelper();
  const { getRulesEffect } = TempDataHelper();

  const { getAllowedFileTypes } = FilePickerHelper();
  const allowedFileRes = getAllowedFileTypes(featuresId);

  const fileInputRef = useRef(null);

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const [effectVal, setEffectVal] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState(false);
  const [errorKey, setErrorKey] = useState('');

  const label = getDeviceSettingsLocString(featuresId, device_settings.label);
  const tooltipMessage = getDeviceSettingsLocString(featuresId, device_settings.info);

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        device_settings.accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, device_settings.settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);

  const handleButtonClick = () => fileInputRef.current.click();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileNameArr = selectedFile.name.split('.');
    const FileExt = '.' + fileNameArr[fileNameArr.length - 1];
    let inputErrorData;
    setError(false);
    setFileName(selectedFile.name);

    if (!selectedFile || selectedFile.length === 0) return;

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = async (evt) => {
      const fileData = {
        filename: selectedFile.name,
        filetype: 'CERTIFICATE',
        contents: reader.result.toString().replace(/^data:(.*,)?/, ''),
      };

      // Certificate FileType and FileContent Validation
      if (!allowedFileRes.acceptFileExts.includes(FileExt)) {
        inputErrorData = true;
        setError(true);
        setErrorKey('invalidFileFormat');
      }
      // else {
      //   if (featuresId === `${FeatureIdEnum.CA_Certificate}`) {
      //     try {
      //       new x509.X509Certificate(reader.result.toString().replace(/^data:(.*,)?/, ''));
      //     } catch (e) {
      //       inputErrorData = true;
      //       setError(true);
      //       setErrorKey('invalidFileContent');
      //     }
      //   }
      // }

      onUpdateTempData({
        settingsName: device_settings.settingsName,
        fleetValue: fileData,
        attribute: device_settings.attribute,
        error: inputErrorData,
      });
    };
    fileInputRef.current.value = '';
  };

  return (
    <>
      <InputTitle
        title={label}
        tooltipMessage={tooltipMessage}
        info={device_settings.info}
        disable={isDisable}
        placement={'top'}
      />
      <FilePickerContainer>
        <FilePickerInput
          type={'file'}
          ref={fileInputRef}
          onChange={handleFileChange}
          accept={allowedFileRes.acceptFileExts}
          disabled={isDisable}
        ></FilePickerInput>
        <StyledButton onClick={handleButtonClick} appearance={'secondary'} disabled={isDisable} isWex = {isWex}>
          {getDeviceSettingsLocString(
            featuresId,
            fileName ? 'changeFile_button_text' : 'selectFile_button_text',
          )}
        </StyledButton>
        <FileNameLabel disable={isDisable}>{fileName}</FileNameLabel>
      </FilePickerContainer>
      {error && (
        <FilePickerError>
          <IconMinusCircle color={colorRed6} filled />
          <FilePickerErrorMessage>{getCommonStrings(errorKey)}</FilePickerErrorMessage>
        </FilePickerError>
      )}
    </>
  );
};

export default memo(FilePickerControl);
