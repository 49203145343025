import styled from 'styled-components';
import { FlexRow, fontFamilyColorGray10 } from '../../GlobalStyles/GlobalStyles';
import { IconWarningAlt, IconInfo } from '@veneer/core';
import primitives from '@veneer/primitives';

export const IconWarningAltWarnStyle = styled(IconWarningAlt)`
  color: rgba(208, 103, 2, 1) !important;
`;

export const WarningMsgWrap = styled(FlexRow)`
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const warningText = styled.div`
  margin-left: 4px;
  font-family: "Forma DJR UI";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${fontFamilyColorGray10}
`;

export const IconInfoAltWarnStyle = styled(IconInfo)`
  color: ${primitives.color.gray6} !important;
`;
