import { tableIdMapping } from '../../config/tableIdMap';
import LocalizeHelper from '../../helper/LocalizeHelper';

const useTableHead = (subFeatureId) => {
  const { getDeviceTableColumnsLocString } = LocalizeHelper();

  const generateTableHead = (subFeatureId, tableIdMapping) => {
    const tableData = tableIdMapping[subFeatureId];

    if (!tableData) {
      return;
    }

    return tableData.ids.map((data) => ({
      id: data,
      label: getDeviceTableColumnsLocString(tableData.label, data),
    }));
  };

  let tableHead = generateTableHead(subFeatureId, tableIdMapping);

  return { tableHead };
};

export default useTableHead;
