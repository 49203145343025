import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import LocalizeHelper from '../../helper/LocalizeHelper';
import * as Style from './Style';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../tests/mocks/launchDarkly';

export const EntitlementFeature = (props) => {
  const { entitlementId, styling } = props;
  const { getCommonStrings } = LocalizeHelper();
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
 return (
    <div>
      {entitlementId ? (
        <Style.FeatureAdvance view={styling}>
          <Style.advanceText>{getCommonStrings('entitlementFeature')}</Style.advanceText>
        </Style.FeatureAdvance>
      ) : null}
    </div>
  );
};
