import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';

export const DigitalSendDataModel = [
  {
    id: `${FeatureIdEnum.EmailAddress_MessageSetting}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.EmailAddress_RestrictionEnabled}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.restrictions`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressRestriction_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_Restriction}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_Restriction}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressRestrictionEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_MessageSetting}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.message.from`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressMessageSetting_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_MessageSetting}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_MessageSetting}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailMessageSettingTable}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_AllowInvalidFormats}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.allow-invalid-email`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressAllowInvalidFormat_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_AllowInvalidFormats}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_AllowInvalidFormats}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressInvalidFormats}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_DigitalSignUser}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.sign`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressDglSignMsgUser_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_DglSignEmail}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_DgSignEmailMsg}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressDglSign}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_DigitalSignUserEditable}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.sign-editable`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressDglSignMsgUserEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EmailAddress_DglSignEmail_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.EmailAddress_DgSignEmailMsg_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressDglSignUserEditable}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_EncryptEmailMsg}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.encrypt`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressEncrypt_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Encrypt_EmailMsg}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Encrypt_EmailMsg}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressEncrypt}`,
      },
      {
        id: `${SubfeatureIdEnum.EmailAddress_EncryptEmailMsgUserEditable}`,
        attribute: `${FleetSvcPolicyAttributeId.EmailAddress_MessageSetting}.encrypt-editable`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'EmailAddressEncryptUserEditable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Encrypt_EmailMsg_UserEditable}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_ScanEmail}`,
        settingsName: `${ConstraintsSettingsNameEnum.Encrypt_EmailMsg_UserEditable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmailAddressEncryptUserEditable}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SaveTo_SharePoint}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SaveTo_SharePoint}`,
        attribute: `${FleetSvcPolicyAttributeId.SaveTo_SharePoint}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'saveToSharePoint_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SaveTo_SharePoint}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SaveTo_SharePoint}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SaveToSharePoint}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SendTo_Email}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SendTo_Email}`,
        attribute: `${FleetSvcPolicyAttributeId.SendTo_Email}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sendToEmail_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SendTo_Email}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SendTo_Email}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SendToEmail}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SaveTo_NetworkFolder}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SaveTo_NetworkFolder}`,
        attribute: `${FleetSvcPolicyAttributeId.SaveTo_NetworkFolder}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'saveToNtf_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SaveTo_NetworkFolder}`,
        resource: `${ConstraintsResourceEnum.Scan_DestinationConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SaveTo_NetworkFolder}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ScanDestinationConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SaveToNetworkFolder}`,
      },
    ],
  },
];
