import { IconWarningAlt } from '@veneer/core';
import React from 'react';
import * as Style from './Styles';

const WarningMessage = (props) => {
  const doNotShrink = { flexShrink: 0 };
  return (
    <Style.WarningMsgWrap>
      {props.iconType === 'warning' ? (
        <Style.IconWarningAltWarnStyle customStyle={doNotShrink} size={20} filled={true} />
      ) : (
        <Style.IconInfoAltWarnStyle customStyle={doNotShrink} size={20} filled={true} />
      )}
      <Style.warningText id={props.id}>{props.warningMsg}</Style.warningText>
    </Style.WarningMsgWrap>
  );
};

export default WarningMessage;