export const AppConstants = {
  localization: {
    notification: {
      key: 'notSupportedDevices',
      title: 'title',
      desc: 'desc',
      coachMarkTitle: 'title',
    },
    commonstrings:{
      showEditableItems: 'showEditableItems',
      showSelectedItems: 'showSelectedItems',
      notCheckedItems: 'notCheckedItems'
  }
}
}
