import { Button } from '@veneer/core';
import { Accordion } from '@veneer/core';
import * as Veneer from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import {
  FlexRowAlignCenter,
  FlexRowAlignCenterBetween,
  FlexRowDirection,
  styleAttributes3,
} from '../../GlobalStyles/GlobalStyles';

export const AccordionTitle = styled(FlexRowAlignCenter)`
  ${styleAttributes3}
  font-family: "Forma DJR UI" !important;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: break-spaces;
  }
`;

export const AccordionHeader = styled(FlexRowDirection)`
  white-space: nowrap;
  .css-y8x8ob::before {
    border: 0px;
  }
  @media (max-width: 991px) {
    white-space: break-spaces;
  }
`;

export const FeatureListItem = styled.li`
  align-items: center;
  background-color: ${Veneer.colorWhite};
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  box-sizing: border-box;
  color: ${Veneer.colorGray10};
  justify-content: space-between;
  position: relative;
  width: 100%;
  word-break: break-word;
`;

export const FeatureList = styled.ul`
  width: 100%;
  word-break: break-word;
  list-style: none;
  white-space: nowrap;
`;

export const FeatureListWrap = styled(FlexRowAlignCenterBetween)`
  border-top: 1px solid rgb(235, 235, 235);
  .feature-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const FeatureAccordion = styled(Accordion)`
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 2.8px 21px 2.8px 32px;
  }
  div[id='${(props) => props.id}-body'] {
    margin: 0px 24px 0px 32px;
  }
`;

export const InfoButton = styled(Button)`
  &[id$='-help-icon']:hover {
    background: ${(props) => (props.isWex ? 'rgba(77, 82, 229, 0.1)' : 'rgba(2, 122, 174, 0.1)')};
  }
  padding: 4px !important;
  border: 0px !important;
`;
