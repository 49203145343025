import {Accordion} from '@veneer/core';
import styled from 'styled-components';
import { styleAttributes1 } from '../../../GlobalStyles/GlobalStyles';

// Starts

export const EditCategoryCard = styled.div`
  height: calc(100% - 1px);
  overflow-y: auto;
  margin-top: 16px;
  .edit-category-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const EditCategoryAccordion = styled(Accordion)`
  margin-bottom: 16px;
  box-shadow: 0px 4px 6px rgba(33, 33, 33, 0.1);
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 14.2px 21px 14.2px 20px;
  }

  div[id='${(props) => props.id}-body'] {
    margin: 0px;
    padding: 0px;
    // padding-bottom: 24px;
  }
`;

export const EditCategoryTitle = styled.div`
  ${styleAttributes1}
`;
