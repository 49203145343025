import React from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';

const Header = (props) => {
  // Localization Helper
  const { getDeviceFeatureLocString, getDeviceModalLocString } = LocalizeHelper();

  // Get Strings
  const modalTitle = getDeviceModalLocString('modal-title');
  const featureName = getDeviceFeatureLocString(props.title, 'name');

  return (
    <Style.ModalHeader>
      <Style.ModalTitle>
        {modalTitle} {featureName}
      </Style.ModalTitle>
    </Style.ModalHeader>
  );
};

export default Header;
