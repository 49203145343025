import React from 'react';
import { SubfeatureIdEnum } from '../../config/SubFeatureEnums';
import TimeConverter from '../../utils/timeConverter';
import LocalizeHelper from '../LocalizeHelper';

export const SleepScheduleHelper = (subFeatureId, propsData) => {
  const { getCommonStrings } = LocalizeHelper();

  const SleepWeeklyItems = (inputArray) => {
    const sleepList = inputArray?.sleepSchedules?.map((item) => ({
      column1_EventType: getCommonStrings('Sleep'),
      column2_Day: `${item?.daysOfWeek?.map((day) => getCommonStrings('week-days-short.' + day)).join(', ')}`,
      column3_SleepTime: item?.timeOfDay
        ? (
            <span>
              {TimeConverter({
                time24: `${item?.timeOfDay?.hourOffset}:${item?.timeOfDay?.minuteOffset}`,
              })}
            </span>
          )?.props?.children
        : '',
      column4_WakeTime: '--',
    }));

    return sleepList;
  };

  const WakeWeeklyItems = (inputArray) => {
    const wakeList = inputArray?.wakeSchedules?.map((item) => ({
      column1_EventType: getCommonStrings('Wake'),
      column2_Day: `${item?.daysOfWeek?.map((day) => getCommonStrings('week-days-short.' + day)).join(', ')}`,
      column3_SleepTime: '--',
      column4_WakeTime: item?.timeOfDay
        ? (
            <span>
              {TimeConverter({
                time24: `${item?.timeOfDay?.hourOffset}:${item?.timeOfDay?.minuteOffset}`,
              })}
            </span>
          )?.props?.children
        : '',
    }));

    return wakeList;
  };

  const groupItemsBySleepTime = (inputArray) => {
    const groupedByTime = inputArray?.reduce(
      (weeks: { [key: string]: string[] }, { column2_Day, column3_SleepTime }) => {
        weeks[column3_SleepTime] = weeks[column3_SleepTime] || [];
        weeks[column3_SleepTime].push(column2_Day);
        return weeks;
      },
      {},
    );

    let result = []

    if (groupedByTime != null) {
    result = Object?.entries(groupedByTime)?.map(([column3_SleepTime, column2_Day]) => {
      const column2_Days = column2_Day as string[];
      return {
        column1_EventType: getCommonStrings('Sleep'),
        column2_Day: column2_Days.join(', '),
        column3_SleepTime: column3_SleepTime,
        column4_WakeTime: '--',
      };
    });
  } else {
    result = []
  }

    return result;
  };

  const groupItemsByWakeTime = (inputArray) => {
    const groupedByTime = inputArray?.reduce(
      (weeks: { [key: string]: string[] }, { column2_Day, column4_WakeTime }) => {
        weeks[column4_WakeTime] = weeks[column4_WakeTime] || [];
        weeks[column4_WakeTime].push(column2_Day);
        return weeks;
      },
      {},
    );

    let result = []
    if (groupedByTime != null) {
      result = Object.entries(groupedByTime).map(([column4_WakeTime, column2_Day]) => {
        const column2_Days = column2_Day as string[];
        return {
          column1_EventType: getCommonStrings('Wake'),
          column2_Day: column2_Days.join(', '),
          column3_SleepTime: '--',
          column4_WakeTime: column4_WakeTime,
        };
      });
    } else {
      result = []
    }

    return result;
  };

  const transformArray = (inputArray) => {
    if (subFeatureId === SubfeatureIdEnum.Sleep_Schedule_Weekly) {
      const sleepItemsList = SleepWeeklyItems(inputArray);
      const sleepGroupedList = groupItemsBySleepTime(sleepItemsList);

      const wakeItemsList = WakeWeeklyItems(inputArray);
      const wakeGroupedList = groupItemsByWakeTime(wakeItemsList);

      const combinedSleepWakeList = [...sleepGroupedList, ...wakeGroupedList];

      return combinedSleepWakeList?.map((item) => ({
        column1_EventType: item.column1_EventType,
        column2_Day: item.column2_Day,
        column3_SleepTime: item.column3_SleepTime,
        column4_WakeTime: item.column4_WakeTime,
      }));
    } else if (subFeatureId === SubfeatureIdEnum.Sleep_Schedule_Holiday) {
      return inputArray?.holidaySchedules?.map((item) => {
        const startDateTime = new Date(item.startTime);
        const formattedStartDateTime = startDateTime
          .toLocaleString('en-US', {
            timeZone: getCommonStrings('UTC'), // Specify UTC time zone
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })
          .replace(',', ''); // Remove the comma

        const endDateTime = new Date(item.endTime);
        const formattedEndDateTime = endDateTime
          .toLocaleString('en-US', {
            timeZone: getCommonStrings('UTC'), // Specify UTC time zone
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })
          .replace(',', ''); // Remove the comma

        return {
          column1_EventName: item.holidayName,
          column2_Start: formattedStartDateTime,
          column3_End: formattedEndDateTime,
        };
      });
    }
  };

  const tableData = transformArray(propsData);

  return { SleepScheduleData: tableData };
};
