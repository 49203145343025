import * as Veneer from '@veneer/tokens/dist/tokens/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenterBetween, styleAttributes7 } from '../../GlobalStyles/GlobalStyles';

export const ModalHeader = styled(FlexRowAlignCenterBetween)`
  padding: 33.5px 24px;
  background: ${Veneer.colorWhite};
`;

export const ModalTitle = styled.h5`
  ${styleAttributes7}
`;
