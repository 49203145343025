import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { FlexRowAlignCenter, styleAttributes5 } from '../../GlobalStyles/GlobalStyles';

export const InfoIcon = styled.div`
  height: 13.33px;
  width: 13.33px;
  left: 1.33px;
  border-radius: 0px;
  margin-left: 5.33px;
  display: inline-block;
`;

export const SubFeature = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TitleWrap = styled(FlexRowAlignCenter)``;

export const Title = styled.div`
  ${styleAttributes5}
  font-family: Forma DJR UI !important;
`;

export const CustomLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-family: ${tokens.fontTitleRegular};
  color: #404040;
  padding-bottom: 16px;
  font-weight: 700;
`;
