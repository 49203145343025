import React, { useMemo } from 'react';
import CategoryTreeHelper from '../../../../helper/CategoryTreeHelper';
import FeatureContent from '../../Feature/Content/FeatureContent';
import CategoryHeader from '../Header/CategoryHeader';
import * as Style from './Styles';

const CategoryContent = (props) => {
  const { featuresData,entitlements, isWex } = props;
  const { getPolicyAttributeDescription } = CategoryTreeHelper();

  const accordionItems = useMemo(() => {
    const items = [];

    items.push({
      id: `edit-${featuresData.categoryName}-body`,
      class: 'edit-category-items',
      expanded: true,
      content: <FeatureContent featuresData={featuresData} entitlements={entitlements} isWex={isWex} />,
      header: {
        centralArea: <CategoryHeader categorykey={featuresData.categoryName} />,
      },
    });

    return items;
  }, [featuresData]);

  return (
    <Style.EditCategoryCard>
      {accordionItems.length > 0 && (
        <Style.EditCategoryAccordion
          items={accordionItems}
          id={`edit-${getPolicyAttributeDescription(featuresData.id)[0]}`}
          className={'edit-category-accordion'}
          behavior={'singleExpand'}
        />
      )}
    </Style.EditCategoryCard>
  );
};

export default CategoryContent;
