import React from 'react';
import ShellProps from './shellProps';
import { Stack } from '@jarvis/web-stratus-client';

export const ShellPropsProvider = (props) => {
  return (
    <ShellProps.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        ecpDeviceRolesPermissions: props.ecpDeviceRolesPermissions ?? false,
        stack: props.stack ?? Stack.pie,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        uxFramework: props.uxFramework,
        showMultipleConfigUi: props.showMultipleConfigUi ? props.showMultipleConfigUi : false,
        selectedDeviceIdList: props.selectedDeviceIdList ?? [],
        handleUseToast: props.handleUseToast,
        showModal: props.showModal,
        setShowModal: props.setShowModal,
        localization: props.localization,
        contentBrokerStack: props.contentBrokerStack,
        containerId: props.containerId,
      }}
    >
      {props.children}
    </ShellProps.Provider>
  );
};
