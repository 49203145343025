import styled from 'styled-components';

export const InputWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const TextInputWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  > div {
    width: 196px;
    height: 36pxpx;
    gap: 10px;
    opacity: 0px;   
  }
  .vn-input {
    min-width: 100px;
  }

  &:last-child {
    // width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
