import { Button } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { shellProps } from '../../../../../src/shellProps';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import * as Style from './Styles';

const GenerateButton = (props) => {
  const { passwordGenerate, titleGenerate, isDisable, isWex } = props;
  const [toastMessage, setToastMessage] = useState(false);
  const { useToast } = useContext(shellProps);
  const [nextId, setNextId] = React.useState(0);
  const { getCustomizableComponentStrings } = LocalizeHelper();

  useEffect(() => {
    if (toastMessage) {
      renderSuccess();
      setToastMessage(false);
    }
  }, [toastMessage]);

  const renderSuccess = () => {
    useToast.addToast({
      id: nextId,
      type: 'positive',
      text: getCustomizableComponentStrings('copytoclipboard'),
    });
    setNextId((prevId) => prevId + 1);
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Style.ButtonWrap>
      <Style.StyledButton
        onClick={() => {
          generatePassword(); setToastMessage(true);
        }}
        appearance="secondary"
        disabled={isDisable}
        data-testid={'id-EWS=generate-button'}
        isWex = {isWex}
      >
        {titleGenerate}
      </Style.StyledButton>
    </Style.ButtonWrap>
  );
  /**
   * Generates random password
   *
   * @param length specifies the length of password
   * @param lower whether to use lowercase characters or not
   * @param upper whether to use uppercase characters or not
   * @param digits whether to use digits or not
   * @param punctuation whether to use punctuation characters or not
   * @returns {string} randomly generated password
   */

  function generatePassword(
    length = 16,
    lower = true,
    upper = true,
    digits = true,
    punctuation = true,
  ) {
    const LOWER = 'abcdefghijklmnopqrstuvwxyz';
    const UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const DIGITS = '0123456789';
    const PUNCTUATION = '?=.*[!@#$%^&*]';
    let charSet = '';
    if (lower) {
      charSet += LOWER;
    }
    if (upper) {
      charSet += UPPER;
    }
    if (digits) {
      charSet += DIGITS;
    }
    if (punctuation) {
      charSet += PUNCTUATION;
    }
    if (!charSet.length) {
      return null;
    }

    var isLowerCaseSpecified = !lower;
    var isUpperCaseSpecified = !upper;
    var isDigitSpecified = !digits;
    var isSymbolSpecified = !punctuation;
    let password = '';
    do {
      password = '';
      const randomArray = new Uint16Array(length);
      window.crypto.getRandomValues(randomArray);
      randomArray.forEach((x) => (password += charSet[x % charSet.length]));
      if (lower) {
        isLowerCaseSpecified = IdentifyChar(LOWER, password);
      }
      if (upper) {
        isUpperCaseSpecified = IdentifyChar(UPPER, password);
      }
      if (digits) {
        isDigitSpecified = IdentifyChar(DIGITS, password);
      }
      if (punctuation) {
        isSymbolSpecified = IdentifyChar(PUNCTUATION, password);
      }

      var expression =
        isLowerCaseSpecified && isUpperCaseSpecified && isDigitSpecified && isSymbolSpecified;
    } while (!expression);

    passwordGenerate(password);
    copyToClipboard(password);
  }

  function IdentifyChar(CharSet, password) {
    var isCharSpecified = false;
    for (let i = 0; i < CharSet.length; i++) {
      if (password.includes(CharSet[i])) {
        isCharSpecified = true;
        break;
      }
    }
    return isCharSpecified;
  }
};

export default memo(GenerateButton);
