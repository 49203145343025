import React, { Fragment } from 'react';
import LocalizeHelper from '../../../../../helper/LocalizeHelper';
import { SectionField } from '../../../ControlTemplates/SectionField/SectionField';
import { SubFeatureSection } from '../../Section/SubFeatureSection';
import * as Style from '../../Styles';
import { SubFeatureTitle } from '../../Title/SubFeatureTitle';

export const Ipv4Ipv6 = (props) => {
  const { subFeatureData, groupData, featureId } = props;
  const { getCommonStrings } = LocalizeHelper();

  const wiredLabel = getCommonStrings('customLabelWire');
  const wirelessLabel = getCommonStrings('customLabelWireless');

  const filteredGroupData = Object.keys(groupData).filter(
    (key) => key.includes('eth') || key.includes('wifi'),
  );

  return (
    <Fragment>
      {filteredGroupData.length
        ? filteredGroupData.sort().map(function (keyName, keyIndex) {
          const splitedKeys = keyName.split('');
          const splitedKeysLastValue = splitedKeys[splitedKeys.length - 1];
          const checkPrefixKeys = keyName.split('');
          const checkPrefix = checkPrefixKeys.pop();
          const prefixKeysJoin = checkPrefixKeys.join('');

          const indexKeys = () => {
            return `${prefixKeysJoin === 'eth' ? wiredLabel : wirelessLabel}${splitedKeysLastValue !== '0' ? splitedKeysLastValue : ''
              }`;
          };

          return (
            <Style.SubFeature>
              <Style.CustomLabel>{indexKeys()}</Style.CustomLabel>
              {subFeatureData.map((subFeature) => {
                const val = groupData[keyName]?.find((data) => data.id === subFeature.id)?.value;
                return (
                  <Style.SubFeature>
                    <SubFeatureTitle
                      label={subFeature.label}
                      info={subFeature.info}
                      featureId={featureId}
                      prefixLabel={indexKeys()}
                      subFeatureId={subFeature.id}
                    />
                    <SectionField
                      type={subFeature.type}
                      result={subFeature.fleetResult}
                      value={val}
                      featureId={featureId}
                      subFeatureId={subFeature.id}
                    />
                  </Style.SubFeature>
                );
              })}
            </Style.SubFeature>
          );
        })
        : subFeatureData &&
        subFeatureData.map((subFeature) => {
          return (
            <SubFeatureSection
              subFeature={subFeature}
              {...props}
              subFeatureData={subFeatureData}
            />
          );
        })}
    </Fragment>
  );
};
