import { useContext } from 'react';
import DeviceClient from '../api/DeviceCacheClient';
import { shellProps } from '../shellProps';
import { getDeviceCacheEnvConfig } from '../utils/dynamicMfeConstants';
import logger from '../../src/utils/logger';

const useDeviceCache = (stack) => {
  const config = getDeviceCacheEnvConfig(stack);
  const { authProvider } = useContext(shellProps);

  const DevicesClient = new DeviceClient(config.deviceCacheUrl, authProvider);

  const fetchDeviceResources = async (deviceId) => {
    return await DevicesClient.getDeviceResourcesCollection(deviceId)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        logger.error("API error for fetchDeviceResources in useDeviceCache_Hook : ",err.message);
      });
  };

  return {
    fetchDeviceResources,
  };
};

export default useDeviceCache;
