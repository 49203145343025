import React from 'react';
import { InformationIcon } from '../../../CommonExtended/Icons/InformationIcon';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import * as Style from '../Styles';

export const SubFeatureTitle = (props) => {
  const { label, info, featureId, prefixLabel, subFeatureId } = props;

  const { getDeviceSettingsLocString } = LocalizeHelper();

  return (
    <Style.TitleWrap>
      <Style.Title id={`${subFeatureId}-title`}>
        {prefixLabel
          ? `${prefixLabel + ' ' + '(' + getDeviceSettingsLocString(featureId, label) + ')'}`
          : getDeviceSettingsLocString(featureId, label)}
      </Style.Title>

      {info ? (
        <InformationIcon
          infoMessage={getDeviceSettingsLocString(featureId, info)}
          size={16}
          subFeatureId={subFeatureId}
        />
      ) : null}
    </Style.TitleWrap>
  );
};
