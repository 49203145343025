import styled from 'styled-components';
import { RadioButton, RadioButtons } from '@veneer/core';

export const StyledRadioButtons = styled(RadioButtons)`
  > label .vn-radio-button__icon::before {
    width: ${(props) => props.isWex && '10px'};
    height: ${(props) => props.isWex && '10px'};
  }
`;

export const InputWrap = styled.div`
  width: 400px;
  margin-bottom: 16px;
  &:last-child {
    width: 400px;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
