import { RadioButton, RadioButtons } from '@veneer/core';
import React, { memo, useContext, useState } from 'react';
import { ConstraintsEffectEnum } from '../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum, SubfeatureIdEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import { InputTitle } from '../../CommonExtended/InputTitle/InputTitle';
import { InputWrap , StyledRadioButtons } from './Styles';

const RadioButtonControl = (props) => {
  const { id, subfeaturesdata, isChecked, isControlledPolicy, isWex 
} = props;

  const filteredData = subfeaturesdata.find(
    (_data) => _data.id === SubfeatureIdEnum.Outgoing_Server_List_OverwriteOption,
  );

  const { getDeviceSettingsLocString, getDeviceConfigurationsLocString } = LocalizeHelper();
  const { onUpdateTempData } = useContext(EditDataContext);

  const [inputVal, setInputVal] = useState(filteredData.constraints.options[0].value);
  const [effectVal, setEffectVal] = useState();

  const label = getDeviceSettingsLocString(id, 'overwrite_options');
  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        subfeaturesdata[0].accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;
  const radioOptions = filteredData.constraints.options.map((option) => {
    return (
      <RadioButton
        label={getDeviceConfigurationsLocString(id, option.value, filteredData.id)}
        value={option.value}
        disabled={isDisable}
      />
    );
  });

  const handleOnchange = (e, value) => {
    setInputVal(value);

    onUpdateTempData({
      settingsName: filteredData.settingsName,
      fleetValue: value,
      attribute: filteredData.attribute,
      error: false,
    });
  };

  return (
    <InputWrap>
      <InputTitle title={label} disable={isDisable} />
      <StyledRadioButtons
      isWex = {isWex}
        alignment="vertical"
        name="radiobutton"
        defaultValue={inputVal}
        onChange={(e, value) => handleOnchange(e, value)}
      >
        {radioOptions}
      </StyledRadioButtons>
    </InputWrap>
  );
};

export default memo(RadioButtonControl);
