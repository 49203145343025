import React from 'react';
import { StyledButton } from './Styles';
import LocalizeHelper from '../../../helper/LocalizeHelper';

export const EditButton = (props) => {
  const {isWex} = props;
  const { getDeviceModalLocString, getDeviceFeatureLocString } = LocalizeHelper();
  return (
    <StyledButton
      data-testid="edit-ews-action-button"
      onClick={props.onClick}
      disabled={props.enable}
      isWex={isWex}
    >
      {getDeviceModalLocString('modal-title')}
    </StyledButton>
  );
};
