import React from 'react';
import CheckBoxControl from '../components/Controls/CheckBoxControl';
import DropDownControl from '../components/Controls/DropDownControl';
import FilePickerControl from '../components/Controls/FilePickerControl';
import PasswordControl from '../components/Controls/PasswordControl';
import RadioButtonControl from '../components/Controls/RadioButtonControl';
import TextBoxControl from '../components/Controls/TextBoxControl';
import ToggleControl from '../components/Controls/ToggleControl';
import Dot1xAuthFailureConfiguration from '../components/CustomizableComponents/Dot1xAuthFailureConfiguration/Dot1xAuthFailureConfiguration';
import EwsPasswordConfiguration from '../components/CustomizableComponents/EwsPasswordConfiguration/EwsPasswordConfiguration';
import LdapBindSearchRoot from '../components/CustomizableComponents/LdapBindSearchRoot/LdapBindSearchRoot';
import LdapServerAuth from '../components/CustomizableComponents/LdapServerAuth/LdapServerAuth';
// import OutGoingServer from '../components/CustomizableComponents/OutGoingServer/OutGoingServer';
import OutGoingServerAuth from '../components/CustomizableComponents/OutGoingServerAuth/OutGoingServerAuth';
import OutGoingServerSplitEmail from '../components/CustomizableComponents/OutGoingServerSplitEmail/OutGoingServerSplitEmail';
import AppDeploymentTable from '../components/CustomizableComponents/AppsDeployment/AppDeploymentTable';
import {
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
} from '../config/SubFeatureEnums';
// import EditDataContext from '../context/EditDataContext';

export default class SettingsGenerator {
  static generateDeviceSettings(
    featuresData,
    entitlements,
    data,
    id,
    isControlledPolicy,
    ldapSetupCredential,
    isWex
  ) {
    const deviceSettingsForm = [];

    let form: JSX.Element;
    if (data?.customizable && data?.customizable === SubFeatureCustomModeEnum.WRITEVIEW) {
      if (data.id == SubfeatureIdEnum.Ews_Proposed_Password) {
        form = (
          <EwsPasswordConfiguration
            featuresId={id}
            device_settings={data}
            isControlledPolicy={isControlledPolicy}
            isWex={isWex}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure) {
        form = (
          <Dot1xAuthFailureConfiguration
            featuresId={id}
            device_settings={data}
            isControlledPolicy={isControlledPolicy}
            isWex = {isWex}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.App_Deployment_AppInstallation) {
        form = (
          <AppDeploymentTable
          featuresId={id}
          device_settings={data.fleetValue}
          settingsName={data.settingsName}
          attribute={data.attribute}
          isEditView = {true}
           />
        );
      }
      if (data.id == SubfeatureIdEnum.LDAP_Server_Authentication) {
        form = (
          <LdapServerAuth
            featuresId={id}
            device_settings={data}
            isControlledPolicy={isControlledPolicy}
            isWex = {isWex}
          />
        );
      }
      if (data.id == SubfeatureIdEnum.LDAP_Bind_And_Search_Root) {
        form = (
          <LdapBindSearchRoot
            featuresId={id}
            device_settings={data}
            isControlledPolicy={isControlledPolicy}
            isWex = {isWex}
          />
        );
      }
      //bind-prefix
      if (
        data.id == SubfeatureIdEnum.LDAP_Bind_Prefix &&
        (ldapSetupCredential === true ||
          ldapSetupCredential === 'true' ||
          ldapSetupCredential === 'ldap-setup.credential.device-user')
      ) {
        form = (
          <TextBoxControl
            device_settings={data}
            featuresId={id}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      //domain-name
      if (
        data.id == SubfeatureIdEnum.LDAP_Admin_DN &&
        (ldapSetupCredential === false ||
          ldapSetupCredential === 'false' ||
          ldapSetupCredential === 'ldap-setup.credential.admin')
      ) {
        form = (
          <TextBoxControl
            device_settings={data}
            featuresId={id}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
      //password
      if (
        data.id == SubfeatureIdEnum.LDAP_Admin_DN_Password &&
        (ldapSetupCredential === false ||
          ldapSetupCredential === 'false' ||
          ldapSetupCredential === 'ldap-setup.credential.admin')
      ) {
        form = (
          <PasswordControl
            featuresData={featuresData}
            entitlement={entitlements}
            device_settings={data}
            featuresId={id}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }

      // OutGoingServer Auth
      if (data.id == SubfeatureIdEnum.Outgoing_Server_CredType) {
        form = (
          <OutGoingServerAuth
            featuresData={featuresData}
            entitlement={entitlements}
            device_settings={data}
            featuresId={id}
            isControlledPolicy={isControlledPolicy}
            isWex = {isWex}

          />
        );
      }
      if (data.id == SubfeatureIdEnum.Outgoing_Server_Split) {
        form = (
          <OutGoingServerSplitEmail
            featuresId={id}
            device_settings={data}
            isControlledPolicy={isControlledPolicy}
          />
        );
      }
    } else {
      switch (data?.type) {
        case SubFeatureControlTypeEnum.Textbox:
          form = (
            <TextBoxControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Password:
          form = (
            <PasswordControl
              featuresData={featuresData}
              entitlement={entitlements}
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Dropbox:
          form = (
            <DropDownControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Radio:
          form = (
            <RadioButtonControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Toggle:
          form = (
            <ToggleControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.Checkbox:
          form = (
            <CheckBoxControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
            />
          );
          break;
        case SubFeatureControlTypeEnum.File:
          form = (
            <FilePickerControl
              device_settings={data}
              featuresId={id}
              isControlledPolicy={isControlledPolicy}
              isWex={isWex}
            />
          );
          break;
      }
    }

    deviceSettingsForm.push({
      form,
    });
    return deviceSettingsForm;
  }
}
