import { Button, ButtonGroup } from '@veneer/core'
import { TextBox } from '@veneer/core';
import React, { useState, useEffect } from 'react';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { InputLabel } from '../../../CommonExtended/InputTitle/InputLabel';
import { InputTitle } from '../../../CommonExtended/InputTitle/InputTitle';
import { InputWrap, TextBoxWrap } from '../Styles';
import * as Style from '../Styles';

const LdapRootModal = (props) => {
  const { featuresId, open, setOpen, setServerTableData, serverTableData , isWex } = props;
  const [rootName, setRootName] = useState('');
  const [textError, setTextError] = useState(null)
  const [error, setError] = useState('');
  const { getDeviceSettingsLocString } = LocalizeHelper();

  const add_root_label = getDeviceSettingsLocString(featuresId, 'add-root');
  const add_btn_label = getDeviceSettingsLocString(featuresId, 'add-btn');
  const cancel_btn_label = getDeviceSettingsLocString(featuresId, 'cancel-btn');
  const roots_desc_label = getDeviceSettingsLocString(featuresId, 'roots-description');
  const hint = getDeviceSettingsLocString(featuresId, 'roots_modal_text_info');
  const duplicateError = getDeviceSettingsLocString(featuresId, 'duplicate-error');

  const handleAdd = () => {
    if (!retrievedErrors(rootName)) {
      const asss = {
        root_name: rootName,
        uid: serverTableData.length,
        rowConfig: {
          selected: false,
        },
      };
      setServerTableData((prevState) => [...prevState, asss]);
      setOpen(false); 
    }
  };

  const handleOnchange = (value) => {
    retrievedErrors(value)
    setRootName(value);   
  };

  const retrievedErrors = (value): boolean => {
    if (serverTableData?.some((item) => item.root_name === value)) {
      setError('duplicateError')
      setTextError(duplicateError)
    }else {
      setTextError('')
      return false
    }
    return true
  }

  return (
    <Style.CustomAddRootModal
      id="ldap-add-root-modal"
      closeOnBlur={false}
      onClose={() => setOpen(false)}
      show={open}
      title={add_root_label}
      isWex = {isWex}
      footer={
        <ButtonGroup>
          <Button appearance="secondary" onClick={() => setOpen(false)}>
            {cancel_btn_label}
          </Button>
          <Button id="root_add_btn" onClick={handleAdd} disabled={rootName === ''}>
            {add_btn_label}
          </Button>
        </ButtonGroup>
      }
    >
      <InputWrap>
        <InputLabel
          title={roots_desc_label}
          id={'roots_desc'} />
      </InputWrap>
      <InputWrap>
        <InputTitle title={'Bind And Search Root'} />
        <TextBoxWrap>
          <TextBox
            id={'ldap_root_input'}
            placeholder={'Enter root'}
            value={rootName}
            helperText={error ? error && textError : hint}
            error={!!error}
            onChange={(value) => handleOnchange(value)}
          />
        </TextBoxWrap>
      </InputWrap>
    </Style.CustomAddRootModal>
  );
};

export default LdapRootModal;


