import styled from 'styled-components';
import { FlexColumnDirection } from '../../GlobalStyles/GlobalStyles';

export const ModalBody = styled.div`
  // height: calc(100% - 205px); //(96px -> header)
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 34px 24px 28px 26px;
  position: relative;
  top: 0px;
  overflow: hidden;
`;

export const ModalBodyRow = styled.div`
  display: flex;
  height: 100%;
  flex: 1 0 100%;
  gap: 24px;
  overflow: hidden;
`;

export const CategoryTreeWrap = styled(FlexColumnDirection)`
  width: 350px;
  flex-shrink: 0;
  overflow: auto;
  @media (max-width: 991px) {
    width: 25%;
  }
`;

export const EditContentWrap = styled(FlexColumnDirection)`
  flex-basis: 100%;
  overflow: auto;
  border-radius: 12px;
`;
