import { Accordion } from '@veneer/core';
import styled from 'styled-components';
import { FlexRowAlignCenter, styleAttributes1 } from '../../GlobalStyles/GlobalStyles';
import primitives from '@veneer/primitives';

export const AccordionTitle = styled(FlexRowAlignCenter)`
  ${styleAttributes1}
  font-family: Forma DJR UI !important;
  font-style: normal;
  font-weight: 400;
  color: ${primitives.color.gray12};
`;

export const CategoryCard = styled.div`
  ${(isWex) =>
    isWex &&
    `
margin-top : 16px;
margin-left : 16px;
`}
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: rgb(33 33 33 / 10%) 0px 4px 16px;
  border-radius: 12px;
  .category-accordion > div {
    border-left-width: 0px;
    border-right-width: 0px;
  }
`;

export const CategoryAccordion = styled(Accordion)`
  div[aria-controls='${(props) => props.id}-body'] {
    padding: 13.2px 20px;
  > div > div {
      color:   ${(props) =>props.isWex && `rgba(33, 33, 33, 1)`};
    }
  > svg > path {
      color :${(props) =>props.isWex && `rgba(64, 64, 64, 1)`};
    }
  }
  div[id='${(props) => props.id}-body'] {
    margin: 0px;
    padding: 0px;
    padding-bottom: 23.2px;
    border-top: 0px !important;
  }
  border-radius: 12px !important;
`;
