import React from 'react';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import * as Style from './Styles';
import { AppConstants } from '../../../utils/appConstants';

export const EditableToggle = (props) => {
  const { editableConfigToggle, setEditableConfigToggle } = props;
  const { getCommonStrings } = LocalizeHelper();

  return (
    <Style.EditableToggleWrap>
      <Style.ToggleButton
        onChange={(value) => setEditableConfigToggle(value)}
        on={editableConfigToggle}
        data-testid={'id-batch-view-show-selected-items-toggle'}
      />
      <Style.EditableToggleText>
        {getCommonStrings(AppConstants.localization.commonstrings.showEditableItems)}
      </Style.EditableToggleText>
    </Style.EditableToggleWrap>
  );
};
