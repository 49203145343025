import React, { useContext, useEffect, useState } from 'react';
import { fleetMgtSvc } from '../../../src/api/FleetMgtSvc';
import apiController from '../../../src/api/apiController';
import { shellProps } from '../../../src/shellProps';
import { service } from '../../../src/utils/constants';
import { FleetSvcCustomValue, FleetSvcPolicyAttributeId } from '../../config/FleetServiceEnums';
import EditDataContext from '../../context/EditDataContext';
import CustomValidationHelper from '../../helper/CustomValidationHelper';
import { FleetAttributeHelper } from '../../helper/FleetAttributeHelper';
import LocalizeHelper from '../../helper/LocalizeHelper';
import TempDataHelper from '../../helper/TempDataHelper';
import { useStoreState } from '../../store/useStoreState';
import Body from '../EditView/Body/Body';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import * as Style from './Styles';

const EditView = (props) => {
  const { showModal, setShowModal, featuresData, entitlements, isWex } = props;
  const { deviceConfigDataState, configState } = useStoreState();
  const { deviceId } = configState;
  const { rulesData, rulesEffect } = deviceConfigDataState;
  const { getApiClient } = apiController();
  const client = getApiClient(service.fleetMgtV2);
  const clientV1 = getApiClient(service.fleetMgtV1);

  const { useToast, showMultipleConfigUi } = useContext(shellProps);
  const { getDeviceModalLocString, getDeviceFeatureLocString } = LocalizeHelper();
  const { getUpdateTempData } = TempDataHelper();

  const [isFetching, setIsFetching] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [enable, setEnable] = useState(true);
  const [tempRulesData, setTempRulesData] = useState([]);
  const [tempFeatures, setTempFeatures] = useState([]);
  const [tempSettings, setTempSettings] = useState([]);
  const [tempRulesEffect, setTempRulesEffect] = useState([]);
  const [editViewData, setEditViewData] = useState([]);
  const [customErrorData, setCustomErrorData] = useState({ name: '', error: false, errorMsg: '' });

  const { getCustomValidate } = CustomValidationHelper(editViewData, setCustomErrorData);

  const onSave = async () => {
    if (!enable) {
      const finaleditViewData = editViewData
        .filter((data) =>
          data.name !== FleetSvcCustomValue.PUBLIC ? data.rulesEffect === false : data,
        )
        .filter((data) => data.isFmsPayload === true || data.isFmsPayload === undefined)
        .map((item) => {
          const payload = FleetAttributeHelper(item);
          return payload;
        });

      let finaleditViewDataCopy = [...finaleditViewData];

      const ldapSetupCredential = finaleditViewDataCopy.find(
        (data) => data.name === 'ldap-setup.credential',
      );

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'true' ||
          ldapSetupCredential.value === 'ldap-setup.credential.device-user')
      ) {
        finaleditViewDataCopy = finaleditViewDataCopy.filter(
          (data) => data.name !== 'ldap-setup.domain-name' && data.name !== 'ldap-setup.password',
        );
      }

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'false' ||
          ldapSetupCredential.value === 'ldap-setup.credential.admin')
      ) {
        finaleditViewDataCopy = finaleditViewDataCopy.filter(
          (data) => data.name !== 'ldap-setup.bind-prefix',
        );
      }

      const isSnmpv1v2Option = finaleditViewDataCopy?.filter((data) => {
        return data.name === FleetSvcCustomValue.PERMISSION;
      });

      if (isSnmpv1v2Option.length && isSnmpv1v2Option[0].value === FleetSvcCustomValue.DISABLE) {
        finaleditViewDataCopy = [...finaleditViewData]?.filter((data) => {
          return data.name !== FleetSvcCustomValue.PUBLIC;
        });
      }
      if (isSnmpv1v2Option.length && isSnmpv1v2Option[0].value === FleetSvcCustomValue.READONLY) {
        const snmpv1v2DefPublic = finaleditViewDataCopy?.find((data) => {
          return data.name === FleetSvcCustomValue.PUBLIC;
        });
        snmpv1v2DefPublic.value = true;
      }

      const certificateDataIds = [
        `${FleetSvcPolicyAttributeId.CA_Certificate}.file`,
        `${FleetSvcPolicyAttributeId.ID_Certificate}.file`,
      ];

      const certificateData = finaleditViewDataCopy.filter((_data) =>
        certificateDataIds.includes(_data['name']),
      );

      if (certificateData.length) {
        setIsFetching(true);

        const storedFile = await fleetMgtSvc.getCertificateDetails(
          clientV1,
          certificateData[0].value.filename,
          certificateData[0].value.filetype,
          certificateData[0].value.contents,
        );

        if (storedFile.error !== undefined) {
          setIsFetching(false);
          setShowModal(false);
          renderError();
        }

        const payload = `{"id":"${storedFile.response.data.id}", "name":"${certificateData[0].value.filename}"}`;

        finaleditViewDataCopy.find((v) => v.name === certificateData[0].name).value = payload;

        const configure = await fleetMgtSvc.useConfigureDevice(
          client,
          [deviceId],
          finaleditViewDataCopy,
        );

        if (configure.response !== undefined) {
          renderSuccess();
        }
        if (configure.error !== undefined) {
          renderError();
        }

        setIsFetching(false);
      } else {
        setIsFetching(true);

        const configure = await fleetMgtSvc.useConfigureDevice(
          client,
          [deviceId],
          finaleditViewDataCopy,
        );
        if (configure.response !== undefined) {
          renderSuccess();
        }
        if (configure.error !== undefined) {
          renderError();
        }

        setIsFetching(false);
      }
    }
    setShowModal(false);
    setTempFeatures([]);
    setTempSettings([]);
  };

  const renderSuccess = () => {
    useToast.addToast({
      id: nextId,
      type: 'informative',
      text: getDeviceModalLocString('single-mode-temporary-save-wait-notification-toast-title'),
      subtitle: getDeviceModalLocString(
        'single-mode-temporary-save-wait-notification-toast-subtitle',
      ),
    });
    setNextId((prevId) => prevId + 1);
  };

  const renderError = () => {
    useToast.addToast({
      id: nextId,
      type: 'negative',
      text: getDeviceModalLocString('single-mode-temporary-save-negative-toast', {
        feature: getDeviceFeatureLocString(props.featuresData.id, 'name'),
      }),
    });
    setNextId((prevId) => prevId + 1);
  };

  const onClose = () => {
    setShowModal(false);
    setTempFeatures([]);
    setTempSettings([]);
  };

  const onUpdateTempData = (payload) => {
    const updatedTempData = getUpdateTempData(
      payload,
      tempFeatures,
      tempRulesData,
      editViewData,
      showMultipleConfigUi,
    );

    setTempFeatures(updatedTempData.updatedTempFeature);
    setTempSettings(updatedTempData.updatedTempFeature[0]?.subfeatures);
    setTempRulesData(updatedTempData.updatedTempRulesData);
    setTempRulesEffect(updatedTempData.updatedTempRulesEffectData);
    setEditViewData(updatedTempData.updatedEditViewData);
  };

  useEffect(() => {
    setTempRulesData(rulesData);
    setTempRulesEffect(rulesEffect);
  }, []);

  useEffect(() => {
    if (editViewData && editViewData.length) {
      // custom Error

      let editViewDataCopy = [...editViewData];

      editViewDataCopy = editViewDataCopy.filter(
        (data) => data.isFmsPayload === true || data.isFmsPayload === undefined,
      );

      const customErrorArr = editViewDataCopy
        .map((_customErrorArrData) => {
          return getCustomValidate({
            editViewDataCopy,
            id: _customErrorArrData.name,
            value: _customErrorArrData.value,
          });
        })
        .filter(function (_res) {
          return _res !== undefined;
        });

      const ids = customErrorArr.map((_customErrorArrData) => _customErrorArrData.id);
      const filteredCustomErrorArr = customErrorArr
        .filter(({ id }, index) => !ids.includes(id, index + 1))
        .filter((_filteredData) => _filteredData.error);

      const customError = filteredCustomErrorArr.length ? false : true;

      const ldapSetupCredential = editViewDataCopy.find(
        (data) => data.name === 'ldap-setup.credential',
      );

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'true' ||
          ldapSetupCredential.value === 'ldap-setup.credential.device-user')
      ) {
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').required = true;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.domain-name').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').value = '';
      }

      if (
        ldapSetupCredential &&
        (ldapSetupCredential.value === 'false' ||
          ldapSetupCredential.value === 'ldap-setup.credential.admin')
      ) {
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').required = false;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.bind-prefix').value = '';
        editViewDataCopy.find((item) => item.name === 'ldap-setup.domain-name').required = true;
        editViewDataCopy.find((item) => item.name === 'ldap-setup.password').required = true;
      }

      let customValid = false;
      const minPasswordLength = editViewDataCopy.filter(
        (data) => data.name === 'ews-password.min-length',
      )[0]?.value;
      const ewsPasswordValue = editViewDataCopy.filter(
        (data) => data.name === 'ews-password.value',
      )[0]?.value;
      const ss = editViewDataCopy.filter((data) => data.name !== 'ews-password.value');

      if (minPasswordLength == '0' && ewsPasswordValue === '') {
        customValid = true;
      } else {
        customValid = false;
      }

      const requiredSettingsCount = customValid
        ? ss.filter(
            (data) =>
              (data.required === true || data.required === undefined) && data.rulesEffect === false,
          ).length
        : editViewDataCopy.filter(
            (data) =>
              (data.required === true || data.required === undefined) && data.rulesEffect === false,
          ).length;

      const requiredSettings = editViewDataCopy.some(
        (data) =>
          (data.required === true || data.required === undefined) && data.rulesEffect === false,
      );

      const valid = requiredSettings
        ? editViewDataCopy.filter(
            (data) =>
              (data.required == undefined || data.required) &&
              data.rulesEffect === false &&
              !data.error &&
              !data.confirmpasswordError &&
              data.value !== '' &&
              data.value !== null,
          ).length === requiredSettingsCount
        : editViewDataCopy.filter(
            (data) =>
              !data.error && !data.confirmpasswordError && data.value !== '' && data.value !== null,
          ).length === 1;

          if (editViewDataCopy[0].name === `${FleetSvcPolicyAttributeId.App_Deployment}.apps`) {
            const appConfigs = Array.isArray(editViewDataCopy[0]?.value)
            ? editViewDataCopy[0].value
                .filter(app => app.config.length > 0)
                .map(app => app.config)
            : [];

            setEnable(!valid || !customError || !appConfigs.length);

          }else{
            setEnable(!valid || !customError);
          }

      // setEnable(false);
    }
  }, [editViewData]);

  return (
    <Style.CustomModal
      maxWidth="calc(100% + 40px + 40px)"
      expanded
      closeOnBlur={false}
      show={showModal}
      data-testid={'id-single-mode-edit-view-modal'}
      footer={<Footer onClose={onClose} onSave={onSave} disabled={enable} loading={isFetching} isWex = {isWex} />}
    >
      <EditDataContext.Provider
        value={{
          setTempFeatures,
          setTempSettings,
          tempSettings,
          onUpdateTempData,
          tempRulesEffect,
          setEditViewData,
          editViewData,
          customErrorData,
          setCustomErrorData,
          entitlements,
          enable,
          setTempRulesEffect,
          setTempRulesData,
        }}
      >
        <Header title={featuresData?.id} data-testid={'id-single-mode-edit-modal-title'} />

        <Style.SubTitle data-testid={'id-single-mode-edit-modal-subtitle'}>
          {getDeviceModalLocString('modal-subtitle')}
        </Style.SubTitle>
        {showModal ? <Body featuresData={featuresData} entitlements={entitlements} isWex = {isWex} /> : null}
      </EditDataContext.Provider>
    </Style.CustomModal>
  );
};

export default EditView;
